import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Select } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setDate,
    setDhCodeAttendance,
    setEmployeeCode,
    setLeveL,
    setLocationMatch,
} from '../../redux/features/attendance/attendanceFilterSlice';

function AttendanceFilter({ queryFunc, loading }) {
    const { date, employeeCode, dhCode } = useSelector((state) => state.attendanceFilter);

    const dispatch = useDispatch();

    // date picker function
    const onChange = (_, dateString) => {
        dispatch(setDate(dateString));
    };

    return (
        <>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <DatePicker
                    value={date ? dayjs(date) : ''}
                    style={{ width: '100%' }}
                    size="large"
                    placeholder="Date"
                    onChange={onChange}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={employeeCode}
                    placeholder="Emp Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setEmployeeCode(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    showArrow
                    placeholder="EMP Level"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setLeveL(e))}
                    options={[
                        {
                            value: 'TMR',
                            label: 'TMR',
                        },
                        {
                            value: 'TMS',
                            label: 'TMS',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    showArrow
                    placeholder="Location Match"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setLocationMatch(e))}
                    options={[
                        {
                            value: 'yes',
                            label: 'Yes',
                        },
                        {
                            value: 'no',
                            label: 'No',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={dhCode}
                    placeholder="DH Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setDhCodeAttendance(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    loading={loading}
                    disabled={loading}
                    size="large"
                    className="filter-btn"
                    icon={<SearchOutlined />}
                    type="primary"
                    style={{ background: '#faad14', color: '#fff' }}
                    onClick={queryFunc}
                >
                    Search
                </Button>
            </Col>
        </>
    );
}

export default AttendanceFilter;
