import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HelmetHeader from '../../components/HelmetHeader';
import firebaseLog from '../../util/firebaseLog';
import TicketForm from './TicketForm';
import TicketTable from './TicketTable';

function Ticket() {
    const [tickets, setTickets] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // user information log
    const { user: userInfo } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Ticket', user: userInfo.name });
    }, [userInfo.name]);

    return (
        <>
            <HelmetHeader title="Ticket" />

            <Modal footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <TicketForm closeModal={handleOk} />
            </Modal>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    padding: '10px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <h3 style={{ margin: 0 }}>Tickets</h3>
                <Button onClick={showModal} type="primary" size="large">
                    Raise a Ticket
                </Button>
            </div>

            <div style={{ marginTop: '10px' }}>
                <TicketTable />
            </div>
        </>
    );
}

export default Ticket;
