import { configureStore } from '@reduxjs/toolkit';
import aiPosmListFilterSlice from '../features/AiPosmList/aiPosmListFilterSlice';
import { apiSlice } from '../features/api/apiSlice';
import attendanceFilterSlice from '../features/attendance/attendanceFilterSlice';
import authSlice from '../features/auth/authSlice';
import dailyActivityReportFilter from '../features/dailyActivityReport/dailyActivityReportFilter';
import dashboardFilterSlice from '../features/dashboard/dashboardFilterSlice';
import downloadReportFilterSlice from '../features/downloadReport/downloadReportFilterSlice';
import dataManagementFilterSlice from '../features/filter/dataManagementFilterSlice';
import damageAndLostFilterSlice from '../features/inventory/damageAndLostFilterSlice';
import dhSummaryFilterSlice from '../features/inventory/dhSummaryFilterSlice';
import tmrHistoryFilterSlice from '../features/inventory/tmrHistoryFilterSlice';
import tmrSummaryFilterSlice from '../features/inventory/tmrSummaryFilterSlice';
import viewDhHistoryFilterSlice from '../features/inventory/viewDhHistoryFilterSlice';
import LeaveManagementFilterSlice from '../features/leaveManagement/leaveManagementFilterSlice';
import loaderSlice from '../features/loaderSlice';
import loginDetailsFilterSlice from '../features/loginDetails/loginDetailsFilterSlice';
import receiveMaterialFilterSlice from '../features/materialManagement/receiveMaterialFilterSlice';
import noticeFilterSlice from '../features/notice/noticeFilterSlice';
import tmrExecutionFilterSlice from '../features/report/tmr/tmrExecutionFilterSlice';
import tmsExecutionFilterSlice from '../features/report/tms/tmsExecutionFilterSlice';
import routePlanTrackerFilterSlice from '../features/routePlanTracker/routePlanTrackerFilterSlice';
import permissionIdsSlice from '../features/superuser/permissionIdsSlice';
import viewUserFilterSlice from '../features/superuser/viewUser/viewUserFilterSlice';
import teamManagementFilterSlice from '../features/teamManagement/teamManagementFilterSlice';
import trainingFilterSlice from '../features/trainingModule/trainingFilterSlice';

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authSlice,
        dataManagement: dataManagementFilterSlice,
        dashboardFilter: dashboardFilterSlice,
        attendanceFilter: attendanceFilterSlice,
        leaveManagementFilter: LeaveManagementFilterSlice,
        noticeFilter: noticeFilterSlice,
        trainingFilter: trainingFilterSlice,
        loginDetailsFilter: loginDetailsFilterSlice,
        routePlanTrackerFilter: routePlanTrackerFilterSlice,
        teamManagementFilter: teamManagementFilterSlice,
        superuserViewUserFilter: viewUserFilterSlice,
        permissionsIds: permissionIdsSlice,
        tmrExecutionFilter: tmrExecutionFilterSlice,
        tmsExecutionFilter: tmsExecutionFilterSlice,
        dhHistoryFilter: viewDhHistoryFilterSlice,
        damageAndLostFilter: damageAndLostFilterSlice,
        dhSummaryFilter: dhSummaryFilterSlice,
        tmrHistoryFilter: tmrHistoryFilterSlice,
        tmrSummaryFilter: tmrSummaryFilterSlice,
        receiveMaterialFilter: receiveMaterialFilterSlice,
        aiPosmListFilter: aiPosmListFilterSlice,
        dailyActivityReportFilter,
        downloadFilter: downloadReportFilterSlice,
        globalLoading: loaderSlice,
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddlewares) => getDefaultMiddlewares().concat(apiSlice.middleware),
});

export default store;
