import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import HelmetHeader from '../../components/HelmetHeader';
import firebaseLog from '../../util/firebaseLog';
import AddArea from './AddArea';
import AddCircle from './AddCircle';
import AddDh from './AddDh';
import AddRegion from './AddRegion';
import AddTerritory from './AddTerritory';
import DataTable from './DataTable';

function DataManagement() {
    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Data Management', user: user.name });
    }, [user.name]);
    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Data Management" />

            <div style={{ marginTop: '10px' }}>
                <Row gutter={[10, 10]}>
                    <Col>
                        <AddCircle />
                    </Col>
                    <Col>
                        <AddRegion />
                    </Col>
                    <Col>
                        <AddArea />
                    </Col>
                    <Col>
                        <AddTerritory />
                    </Col>
                    <Col>
                        <AddDh />
                    </Col>
                </Row>
                <div>
                    <div className="box-heading">Distribution House List</div>
                    <DataTable />
                </div>
            </div>
        </>
    );
}

export default DataManagement;
