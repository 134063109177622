import { Button, InputNumber, Space, Table, message } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import {
    resetDataManagementFilter,
    setDistributionHouse,
} from '../../redux/features/filter/dataManagementFilterSlice';
import { setGlobalLoading, setReFetchFilter } from '../../redux/features/loaderSlice';
import {
    useGetPendingMaterialMutation,
    useReceiveMaterialMutation,
} from '../../redux/features/materialManagement/materialManagementApi';
import { resetReceiveMaterialFilter } from '../../redux/features/materialManagement/receiveMaterialFilterSlice';
import debounce from '../../util/debounce';
import firebaseLog from '../../util/firebaseLog';

const { Column, ColumnGroup } = Table;

function ReceivedMaterial() {
    const dispatch = useDispatch();

    const { dhCode } = useSelector((state) => state.receiveMaterialFilter);

    // filter data
    const { circle, region, area, territory, distributionHouse } = useSelector(
        (state) => state.dataManagement
    );

    // input data
    const [receiveItem, setReceiveItem] = useState([]);

    // get pending request
    const [getPendingMaterial, { data, isLoading }] = useGetPendingMaterialMutation();

    const [transformData, setTransformData] = useState([]);

    // response data normalize
    useEffect(() => {
        if (data?.data?.length) {
            setTransformData(
                data?.data
                    ?.map((item) =>
                        item.data.map((x) => ({
                            ...x,
                        }))
                    )
                    .flat()
            );
        }
    }, [data]);

    function getBodyData(dhcode) {
        const bodyData = {};
        if (distributionHouse?.length) {
            bodyData.dhId = distributionHouse[distributionHouse.length - 1].value;
        }
        if (dhcode) {
            bodyData.dhCode = dhcode;
        }
        return bodyData;
    }

    // pending data fetch request function
    const fetchPendingData = () => {
        if (distributionHouse.length || dhCode) {
            getPendingMaterial({
                ...getBodyData(dhCode),
                limit: 1000,
                page: 1,
            });
        } else {
            message.error('Please select a distribution house or type dh code');
        }
    };

    // input data collect function
    const changeFunc = (record, value) => {
        setReceiveItem((prev) => {
            if (prev.find((item) => item.materialId === record.materialId)) {
                return [
                    ...prev.filter((x) => x.materialId !== record.materialId),
                    { materialId: record.materialId, receive: value },
                ].filter((x) => x.receive > 0);
            }
            return [...prev, { materialId: record.materialId, receive: value }].filter(
                (x) => x.receive > 0
            );
        });
    };

    // dh filter single data showing
    useEffect(() => {
        if (distributionHouse.length > 1) {
            dispatch(setDistributionHouse([...distributionHouse.slice(1)]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [distributionHouse]);

    // confirm material api hook
    const [receiveMaterial, { isLoading: receiveLoading }] = useReceiveMaterialMutation();

    // confirm material function
    const confirmMaterialFunc = () => {
        confirmAlert({
            title: 'Confirm to Received Materials',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: debounce(async () => {
                        try {
                            const result = await receiveMaterial({
                                // eslint-disable-next-line no-underscore-dangle
                                id: data?.data[0]?._id.dhId || '',
                                data: receiveItem,
                            }).unwrap();
                            message.success('Material received successfully');
                            setTransformData([]);
                            setReceiveItem([]);
                            dispatch(resetReceiveMaterialFilter());
                        } catch (error) {
                            message.error('Something went wrong');
                        }
                    }, 300),
                },
                {
                    label: 'No',
                    onClick: () => message.warning('Cancelled!'),
                },
            ],
        });
    };

    // data receive and set global loading
    useEffect(() => {
        dispatch(setGlobalLoading(receiveLoading));
    }, [dispatch, receiveLoading]);

    const { reFetchFilter } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetReceiveMaterialFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Receive Material', user: user.name });
    }, [user.name]);

    return (
        <div style={{ margin: '15px' }}>
            <HelmetHeader title="Receive Material" />
            <div style={{ marginBottom: '10px' }}>
                <Filter
                    loading={isLoading}
                    queryFunc={fetchPendingData}
                    pathname="/receive-material"
                    selectAllDisable
                />
            </div>

            <div className="box-heading">Receive Material</div>

            <div
                style={{
                    padding: '10px',
                    marginTop: '8px',
                    boxShadow: '0 0 5px 0 #d6d0d0',
                    borderRadius: '10px',
                }}
            >
                <div style={{ display: 'flex', gap: 15 }}>
                    <p style={{ fontSize: '16px' }}>
                        <span style={{ fontWeight: 500 }}>DH Name: </span>
                        {transformData?.length ? data?.data[0]?.dh : ''}
                    </p>
                    <p style={{ fontSize: '16px' }}>
                        <span style={{ fontWeight: 500 }}>DH Code: </span>
                        {transformData?.length ? data?.data[0]?.dhcode : ''}
                    </p>
                </div>

                <Table
                    rowKey="_id"
                    loading={isLoading}
                    scroll={{ x: 800 }}
                    pagination={false}
                    size="small"
                    dataSource={
                        transformData
                            ?.sort((a, b) => (a.name < b.name ? -1 : 1))
                            .sort((a, b) => b.category.length - a.category.length) || []
                    }
                >
                    <Column
                        title="Last Allocate Time"
                        dataIndex="allocatedAt"
                        key="allocatedAt"
                        render={(e) => dayjs(e).format('DD/MM/YYYY hh:mm:ss A')}
                    />
                    <Column title="Category" dataIndex="category" key="category" />
                    <Column title="Name" dataIndex="name" key="name" />
                    <Column title="In Hand Qty" dataIndex="quantity" key="quantity" />
                    <Column title="Pending Qty" dataIndex="pendingQuantity" key="pendingQuantity" />
                    <Column
                        title="Receive Qty"
                        key="receive"
                        align="right"
                        render={(_, record) => (
                            <Space>
                                {record.pendingQuantity > 0 ? (
                                    <InputNumber
                                        onChange={(e) => changeFunc(record, e)}
                                        size="large"
                                        min={1}
                                        max={record.pendingQuantity}
                                        placeholder="Qty"
                                    />
                                ) : null}
                            </Space>
                        )}
                    />
                </Table>

                <div
                    style={{
                        margin: '0 auto',
                        padding: '10px 0 5px 0',
                        width: '100%',
                        textAlign: 'center',
                    }}
                >
                    {receiveItem?.length ? (
                        <Button
                            disabled={receiveLoading}
                            loading={receiveLoading}
                            onClick={debounce(confirmMaterialFunc, 300)}
                            className="common-bg"
                            size="large"
                        >
                            Confirm
                        </Button>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default ReceivedMaterial;
