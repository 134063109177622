import { DatePicker, Input, Modal, Select, Spin, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useGetDhListQuery } from '../../redux/features/dataManagement/dataManagementApi';
import {
    useSearchEmployeeMutation,
    useUpdateEmployeeInfoMutation,
} from '../../redux/features/teamManagement/teamManagementApi';

function EditModal({ isModalOpen, setIsModalOpen, userData, fetchSearchData, page, limit }) {
    const [updateEmployeeInfo, { isLoading }] = useUpdateEmployeeInfoMutation();
    // change state
    const [newName, setNewName] = useState('');
    const [newUserName, setNewUserName] = useState('');
    const [newEmployeeCode, setNewEmployeeCode] = useState('');
    const [supChangeVal, setSupChangeVal] = useState('');
    const [changeMobile, setChangeMobile] = useState('');
    const [updateResignDate, setUpdateResignDate] = useState('');
    const [updateDh, setUpdateDh] = useState([]);
    const [mobile, setMobile] = useState('');

    // search employee api hook
    const [searchEmployee, { data: supData, isLoading: supLoading }] = useSearchEmployeeMutation();

    // get dh list
    const { data: dhList, isLoading: getDhLoading } = useGetDhListQuery();

    useEffect(() => {
        searchEmployee({
            // ...getDataManagementFilterData({ circle, region, area, territory, distributionHouse }),
            type: 'tms',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchEmployee]);

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };
    const [dhSearch, setDhSearch] = useState('');
    const onDhSearch = (e) => {
        setDhSearch(e);
    };

    // get body data
    function getBodyData({ name, username, employeeCode, sup, dh, mNumber, resignD }) {
        const bodyData = {};
        if (name) {
            bodyData.name = name;
        }
        if (username) {
            bodyData.username = username;
        }
        if (employeeCode) {
            bodyData.employeeCode = employeeCode;
        }
        if (sup) {
            bodyData.supervisorId = sup;
        }
        if (dh.length) {
            bodyData.dhId = dh;
        }
        if (mNumber) {
            bodyData.mobile = mNumber;
        }
        if (resignD) {
            bodyData.resignDate = resignD;
        }
        return bodyData;
    }
    const handleOk = async () => {
        try {
            const res = await updateEmployeeInfo({
                id: userData.id,
                data: {
                    ...getBodyData({
                        name: newName,
                        username: newUserName,
                        employeeCode: newEmployeeCode,
                        sup: supChangeVal,
                        dh: updateDh,
                        mNumber: mobile,
                        resignD: updateResignDate,
                    }),
                },
            }).unwrap();
            setIsModalOpen(false);
            message.success('Task Completed successfully');
            fetchSearchData(page, limit);
        } catch (error) {
            message.error('Something went wrong!');
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const supChange = (value) => {
        setSupChangeVal(value);
    };

    const dhChange = (value) => {
        setUpdateDh(value);
    };

    const resignDateChange = (_, dateString) => {
        setUpdateResignDate(dateString);
    };

    return (
        <Modal
            title="Edit Employee Information"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Update"
            confirmLoading={isLoading}
        >
            {getDhLoading || supLoading ? (
                <Spin />
            ) : (
                <div>
                    <div>
                        <Typography.Title level={5}>Name</Typography.Title>
                        <Input
                            onChange={(e) => setNewName(e.target.value)}
                            defaultValue={userData.name}
                        />
                    </div>

                    <div>
                        <Typography.Title level={5}>Username</Typography.Title>
                        <Input
                            onChange={(e) => setNewUserName(e.target.value)}
                            defaultValue={userData.username}
                        />
                    </div>

                    <div>
                        <Typography.Title level={5}>Employee Code</Typography.Title>
                        <Input
                            onChange={(e) => setNewEmployeeCode(e.target.value)}
                            defaultValue={userData.employeeCode}
                        />
                    </div>

                    <div>
                        <Typography.Title level={5}>Phone Number</Typography.Title>
                        <Input
                            onChange={(e) => setMobile(e.target.value)}
                            defaultValue={userData.mobile}
                        />
                    </div>

                    <div>
                        <Typography.Title level={5}>Resign Date</Typography.Title>
                        <DatePicker onChange={resignDateChange} />
                    </div>

                    <div>
                        <Typography.Title level={5}>DH</Typography.Title>
                        <Select
                            loading={getDhLoading}
                            mode={userData?.type === 'tms' ? 'multiple' : null}
                            allowClear
                            showSearch
                            style={{
                                width: '100%',
                            }}
                            placeholder="Please select"
                            defaultValue={userData?.dh
                                .split(', ')
                                .map((x) => dhList?.data?.find((y) => y.label === x).value)}
                            onChange={dhChange}
                            options={dhList?.data}
                            filterOption={(input, option) =>
                                option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            searchValue={dhSearch}
                            onSearch={onDhSearch}
                        />
                    </div>

                    {userData?.type === 'tmr' ? (
                        <div>
                            <Typography.Title level={5}>Supervisor</Typography.Title>
                            <Select
                                loading={supLoading}
                                allowClear
                                showSearch
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Please select"
                                defaultValue={[userData.supervisorName]}
                                onChange={supChange}
                                options={supData?.data?.map((doc) => ({
                                    label: doc.name,
                                    // eslint-disable-next-line no-underscore-dangle
                                    value: doc._id,
                                }))}
                                filterOption={(input, option) =>
                                    option.props.label.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                                searchValue={search}
                                onSearch={onSearch}
                            />
                        </div>
                    ) : null}
                </div>
            )}
        </Modal>
    );
}

export default EditModal;
