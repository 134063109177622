/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dateRange: [],
    entryType: '',
    dhCode: '',
};

const viewDhHistoryFilterSlice = createSlice({
    name: 'viewDhHistoryFilterSlice',
    initialState,
    reducers: {
        setDateRange: (state, action) => {
            state.dateRange = action.payload;
        },
        setEntryType: (state, action) => {
            state.entryType = action.payload;
        },
        setDhCode: (state, action) => {
            state.dhCode = action.payload;
        },
        resetDhHistoryFilterSlice: (state, action) => {
            state.dateRange = [];
            state.entryType = '';
            state.dhCode = '';
        },
    },
});

export default viewDhHistoryFilterSlice.reducer;
export const { setDateRange, setEntryType, setDhCode, resetDhHistoryFilterSlice } =
    viewDhHistoryFilterSlice.actions;
