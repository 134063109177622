/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-array-index-key */
import { Progress } from 'antd';
import React from 'react';

function ProgressCircle({
    size = 'small',
    data = 0,
    colors = {
        '0%': '#8464EE',
        '100%': '#697BFD',
    },
    label = '',
    margin = 0,
}) {
    return (
        <div style={{ display: 'block', marginRight: margin, textAlign: 'center' }}>
            <Progress
                animation
                size={size}
                type="circle"
                status="active"
                percent={data}
                strokeWidth={9}
                strokeColor={colors}
            />
            {label ? (
                <p
                    style={{
                        margin: 0,
                        color: '#000',
                        paddingTop: '8px',
                        textAlign: 'center',
                        fontSize: '12px',
                    }}
                >
                    {label}
                </p>
            ) : null}
        </div>
    );
}

export default ProgressCircle;
