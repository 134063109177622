import { Modal, Select, Spin, message } from 'antd';
import React, { useState } from 'react';
import {
    useGroupUpdateMutation,
    usePermissionListQuery,
} from '../../redux/features/superuser/superUserApi';

function GroupEditModal({ isModalOpen, setIsModalOpen, groupData, fetchSearchData }) {
    const { data, isLoading } = usePermissionListQuery();

    const [groupUpdate, { isLoading: updateLoading }] = useGroupUpdateMutation();

    const [groupChangeData, setGroupChangeData] = useState([]);

    const handleOk = async () => {
        try {
            if (groupChangeData.length) {
                const res = await groupUpdate({
                    // eslint-disable-next-line no-underscore-dangle
                    id: groupData._id,
                    data: {
                        permissionsId: groupChangeData,
                    },
                }).unwrap();
                message.success('Group updated successfully');
                setIsModalOpen(false);
            }
        } catch (error) {
            message.error('Something went wrong');
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const groupChange = (value) => {
        setGroupChangeData(value);
    };

    return (
        <Modal
            title="Edit Employee Information"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Update"
            confirmLoading={updateLoading}
        >
            {data?.data?.length ? (
                <Select
                    mode="multiple"
                    style={{ minWidth: '480px' }}
                    defaultValue={groupData.modules
                        .split(' | ')
                        .map((x) => data?.data.find((y) => y.label === x).value)}
                    loading={isLoading}
                    options={data?.data}
                    onChange={groupChange}
                />
            ) : (
                <Spin />
            )}
        </Modal>
    );
}

export default GroupEditModal;
