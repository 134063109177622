import { Footer } from 'antd/es/layout/layout';
import React from 'react';
import logo from '../assets/logo.svg';

function FooterComponent() {
    return (
        <Footer
            size="small"
            theme="dark"
            style={{
                textAlign: 'center',
                padding: '16px 50px',
                background: '#ffffff',
                color: '#000',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                position: 'relative',
            }}
        >
            Powered By © HawkEyes Digital Monitoring Ltd. All rights reserved.
            <div style={{ position: 'absolute', right: '12px', bottom: '3px' }}>
                <img
                    src={logo}
                    alt="HawkEyes"
                    width="115px"
                    style={{ borderRadius: '2px', textAlign: 'right' }}
                />
            </div>
        </Footer>
    );
}

export default FooterComponent;
