// generated request body data
const getDataManagementFilterData = ({ circle, region, area, territory, distributionHouse }) => {
    const bodyData = {};
    if (circle.length) {
        bodyData.circleId = circle.map((item) => item.value);
    }
    if (region.length) {
        bodyData.regionId = region.map((item) => item.value);
    }
    if (area.length) {
        bodyData.areaId = area.map((item) => item.value);
    }
    if (territory.length) {
        bodyData.territoryId = territory.map((item) => item.value);
    }
    if (distributionHouse.length) {
        bodyData.dhId = distributionHouse.map((item) => item.value);
    }
    return bodyData;
};

export default getDataManagementFilterData;
