import { EditOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Pagination, Popconfirm, Space, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HelmetHeader from '../../components/HelmetHeader';
import { useGetAdminUserListMutation } from '../../redux/features/superuser/superUserApi';
import { resetViewUserFilter } from '../../redux/features/superuser/viewUser/viewUserFilterSlice';
import { useUnLockUserMutation } from '../../redux/features/teamManagement/teamManagementApi';
import firebaseLog from '../../util/firebaseLog';
import UserEditModal from './UserEditModal';
import ViewUserFilter from './ViewUserFilter';

const { Column, ColumnGroup } = Table;

function ViewUser() {
    const dispatch = useDispatch();

    // edit employee modal state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editUserData, setEditUserData] = useState({});

    const { username, groupName, userType } = useSelector((state) => state.superuserViewUserFilter);

    // pagination
    const [totalShowPage, setTotalPageShow] = useState(50);
    const [currentPage, setCurrentPageShow] = useState(1);

    const [getAdminUserList, { data, isLoading }] = useGetAdminUserListMutation();

    // get body data
    function getBodyData(uname, groupId, userT) {
        const bodyData = {};
        if (uname) {
            bodyData.username = uname;
        }
        if (groupId) {
            bodyData.rolesId = groupId;
        }
        if (userT) {
            bodyData.userType = userT;
        }
        return bodyData;
    }

    // search function
    const fetchUserData = (page, totalShow, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(50);
        }
        getAdminUserList({
            page,
            limit: totalShow,
            ...getBodyData(username, groupName, userType),
        });
    };

    useEffect(() => {
        getAdminUserList({
            page: 1,
            limit: totalShowPage,
            ...getBodyData(username, groupName, userType),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetchUserData(pageNumber, totalPageChange);
    };

    // unlock user api hook
    const [unLockUser, { isLoading: unlockLoading }] = useUnLockUserMutation();
    // unlock user
    const unlockUser = async (id) => {
        try {
            const res = await unLockUser({
                userId: id,
            }).unwrap();
            message.success('Task Complete');
            fetchUserData(currentPage, totalShowPage);
        } catch (error) {
            message.error(error.message);
        }
    };

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'View User', user: user.name });
    }, [user.name]);

    // reset all filter when component change
    useEffect(() => {
        dispatch(resetViewUserFilter());
    }, [dispatch]);

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="View User" />

            {/* edit modal */}
            {isModalOpen ? (
                <UserEditModal
                    fetchSearchData={getAdminUserList}
                    page={currentPage}
                    limit={totalShowPage}
                    userData={editUserData}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                />
            ) : null}

            <div style={{ margin: '16px 0' }}>
                <ViewUserFilter loading={isLoading} queryFunc={fetchUserData} />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">User Details</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    <Table
                        pagination={false}
                        size="small"
                        scroll={{
                            x: 800,
                        }}
                        loading={isLoading}
                        dataSource={data?.data?.data || []}
                    >
                        <Column title="Name" dataIndex="name" key="name" />
                        <Column title="Username" dataIndex="username" key="username" />
                        <Column title="User Type" dataIndex="userType" key="userType" />
                        <Column title="Access" dataIndex="accessOf" key="accessOf" />
                        <Column title="Landing Page" dataIndex="landingPage" key="landingPage" />
                        <Column title="Group Name" dataIndex="groupName" key="groupName" />
                        <Column
                            title="Action"
                            key="action"
                            align="right"
                            render={(_, record) => (
                                <Space size={2}>
                                    {record?.isLocked ? (
                                        <Popconfirm
                                            title="Unlock User"
                                            description="Are you sure to unlock this user?"
                                            // eslint-disable-next-line no-underscore-dangle
                                            onConfirm={() => unlockUser(record.userId)}
                                            okText="Yes"
                                            cancelText="No"
                                            okButtonProps={{
                                                loading: unlockLoading,
                                            }}
                                        >
                                            <Button
                                                title="Unlock User"
                                                size="small"
                                                icon={<UnlockOutlined />}
                                                type="primary"
                                                shape="circle"
                                            />
                                        </Popconfirm>
                                    ) : null}
                                    {record.groupName !== 'Superadmin' ? (
                                        <Button
                                            title="Edit"
                                            size="small"
                                            icon={<EditOutlined />}
                                            type="primary"
                                            danger
                                            shape="circle"
                                            onClick={() => {
                                                setEditUserData(record);
                                                setIsModalOpen(true);
                                            }}
                                        />
                                    ) : null}
                                </Space>
                            )}
                        />
                    </Table>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: '10px',
                    }}
                >
                    <Pagination
                        size="large"
                        pageSize={totalShowPage}
                        showSizeChanger
                        showQuickJumper
                        current={currentPage}
                        defaultCurrent={1}
                        total={data?.data?.meta?.total}
                        onChange={onChange}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    />
                </div>
            </div>
        </>
    );
}

export default ViewUser;
