import { Col, Row, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { useGetTmrSummaryMutation } from '../../redux/features/inventory/inventoryApiSlice';
import { resetTmrSummaryFilter } from '../../redux/features/inventory/tmrSummaryFilterSlice';
import { setGlobalLoading, setReFetchFilter } from '../../redux/features/loaderSlice';
import firebaseLog from '../../util/firebaseLog';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import TmrSummaryTable from './TmrSummaryTable';

const { Column, ColumnGroup } = Table;

function TmrSummary() {
    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    // dh history filter hook
    const { dateRange, tmrId, dhCode } = useSelector((state) => state.tmrSummaryFilter);

    // filter data
    const { circle, region, area, territory, distributionHouse } = useSelector(
        (state) => state.dataManagement
    );

    const getTmrSummaryFilterData = (dateArr, ids, dhcode) => {
        const bodyData = {};

        if (dateArr[0] && dateArr[1]) {
            const [s, e] = dateArr;
            bodyData.from = s;
            bodyData.to = e;
        }
        if (ids.length) {
            bodyData.tmrId = ids;
        }
        if (dhcode) {
            bodyData.dhCode = dhcode;
        }
        return bodyData;
    };

    const [getTmrSummary, { data, isLoading }] = useGetTmrSummaryMutation();

    const transformData = data?.data?.map((item) => ({
        circle: item.circle,
        region: item.region,
        area: item.area,
        territory: item.territory,
        dh: item.dh,
        dhcode: item.dhcode,
        tmr: item.name,
        tmrCode: item.usercode,
        countable: item.material
            .filter((x) => x.category === 'countable')
            ?.map(({ name, received, quantity, used, inhand }) => ({
                name,
                confirmedQuantity: received,
                inhand: quantity,
                used,
            })),
        toolkit: item.material
            .filter((x) => x.category === 'toolkit')
            ?.map(({ name, received, quantity, used, inhand }) => ({
                name,
                confirmedQuantity: received,
                inhand: quantity,
                used,
            })),
    }));

    const fetchTmrSummaryData = () => {
        if (tmrId.length && dateRange.length) {
            getTmrSummary({
                ...getDataManagementFilterData({
                    circle,
                    region,
                    area,
                    territory,
                    distributionHouse,
                }),
                ...getTmrSummaryFilterData(dateRange, tmrId, dhCode),
            });
        } else {
            message.error('Date range & TMR must be selected');
        }
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setGlobalLoading(isLoading));
    }, [dispatch, isLoading]);

    // const onChange = (pageNumber, totalPageChange) => {
    //     setTotalPageShow(() => totalPageChange);
    //     setCurrentPageShow(pageNumber);
    //     fetchTmrSummaryData(pageNumber, totalPageChange);
    // };

    const { reFetchFilter } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetTmrSummaryFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'TMR Summary', user: user.name });
    }, [user.name]);

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="TMR Summary" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    loading={isLoading}
                    queryFunc={fetchTmrSummaryData}
                    pathname="/inventory-tmrSummary"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">TMR Summary</div>

                {transformData?.map((item, i) => (
                    <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        style={{
                            boxShadow: '0 0 5px 0 #dad5d5',
                            borderRadius: '10px',
                            padding: '10px',
                            margin: '10px 0',
                        }}
                    >
                        <Row gutter={[10, 10]} justify="space-evenly">
                            <Col>
                                <p style={{ margin: 0 }}>
                                    <span style={{ fontWeight: 500 }}>Market Operation: </span>
                                    {item.circle}
                                </p>
                            </Col>
                            <Col>
                                <p style={{ margin: 0 }}>
                                    <span style={{ fontWeight: 500 }}>Region: </span>
                                    {item.region}
                                </p>
                            </Col>
                            <Col>
                                {' '}
                                <p style={{ margin: 0 }}>
                                    <span style={{ fontWeight: 500 }}>Area: </span>
                                    {item.area}
                                </p>
                            </Col>
                            <Col>
                                <p style={{ margin: 0 }}>
                                    <span style={{ fontWeight: 500 }}>Territory: </span>
                                    {item.territory}
                                </p>
                            </Col>
                            <Col>
                                <p style={{ margin: 0 }}>
                                    <span style={{ fontWeight: 500 }}>DH: </span>
                                    {item.dh}
                                </p>
                            </Col>
                            <Col>
                                <p style={{ margin: 0 }}>
                                    <span style={{ fontWeight: 500 }}>DH Code: </span>
                                    {item.dhcode}
                                </p>
                            </Col>
                            <Col>
                                <p style={{ margin: 0 }}>
                                    <span style={{ fontWeight: 500 }}>TMR Name: </span>
                                    {item.tmr}
                                </p>
                            </Col>
                            <Col>
                                <p style={{ margin: 0 }}>
                                    <span style={{ fontWeight: 500 }}>TMR Code: </span>
                                    {item.tmrCode}
                                </p>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }} gutter={[10, 10]} justify="space-evenly">
                            <Col lg={12} sm={24}>
                                <div
                                    style={{
                                        background: '#faad14',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '5px',
                                        borderRadius: '2px',
                                    }}
                                >
                                    <h4 style={{ margin: 0, color: '#fff' }}>Countable</h4>
                                </div>
                                <TmrSummaryTable isLoading={isLoading} data={item?.countable} />
                            </Col>

                            <Col lg={12} sm={24}>
                                <div
                                    style={{
                                        background: '#faad14',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '5px',
                                        borderRadius: '2px',
                                    }}
                                >
                                    <h4 style={{ margin: 0, color: '#fff' }}>Toolkit</h4>
                                </div>
                                <TmrSummaryTable isLoading={isLoading} data={item?.toolkit} />
                            </Col>
                        </Row>
                    </div>
                ))}
            </div>

            {/* <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Pagination
                    size="large"
                    pageSize={totalShowPage}
                    showSizeChanger
                    showQuickJumper
                    current={currentPage}
                    defaultCurrent={1}
                    total={data?.meta.total}
                    onChange={onChange}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                />
            </div> */}
        </>
    );
}

export default TmrSummary;
