import { Button, Form, Input, message } from 'antd';
import React from 'react';
import { useAddCircleMutation } from '../../redux/features/dataManagement/dataManagementApi';

function AddCircle() {
    // circle add api hook
    const [addCircle, { isSuccess, isLoading }] = useAddCircleMutation();

    const [form] = Form.useForm();
    const onFinish = async (v) => {
        try {
            const result = await addCircle({
                name: v.circle,
            }).unwrap();
            message.success('Region added successfully');
            form.resetFields();
        } catch (error) {
            message.error(error.data.message);
        }
    };

    return (
        <div className="data-box">
            <h3>Add Circle</h3>
            <div>
                <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
                    <Form.Item
                        name="circle"
                        label="Circle"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input size="large" placeholder="Circle Name" />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            loading={isLoading}
                            disabled={isLoading}
                            type="primary"
                            htmlType="submit"
                        >
                            Add Circle
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default AddCircle;
