/* eslint-disable no-underscore-dangle */
import {
    EditOutlined,
    KeyOutlined,
    LogoutOutlined,
    StopOutlined,
    UnlockOutlined,
} from '@ant-design/icons';
import { Button, Pagination, Popconfirm, Space, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { setReFetchFilter } from '../../redux/features/loaderSlice';
import {
    useForceLogoutUserMutation,
    useSearchEmployeeMutation,
    useSuspendUserApiMutation,
    useUnLockUserMutation,
} from '../../redux/features/teamManagement/teamManagementApi';
import { resetTeamManagementFilter } from '../../redux/features/teamManagement/teamManagementFilterSlice';
import firebaseLog from '../../util/firebaseLog';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import EditModal from './EditModal';
import PasswordChangeModal from './PasswordChange';

const { Column, ColumnGroup } = Table;

function TeamManagement() {
    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    // search employee api hook
    const [searchEmployee, { data, isLoading }] = useSearchEmployeeMutation();
    // filter data
    const { circle, region, area, territory, distributionHouse } = useSelector(
        (state) => state.dataManagement
    );
    const { empId, empCode, empName, empUsername, empLevel, supervisor, idLock } = useSelector(
        (state) => state.teamManagementFilter
    );

    const getFilterData = (eId, eCode, eName, eUsername, lev, sCode, idlock) => {
        const bodyData = {};
        if (eId) {
            bodyData.employeeNo = Number(eId);
        }
        if (eCode) {
            bodyData.employeeCode = eCode;
        }
        if (eName) {
            bodyData.employeeName = eName;
        }
        if (eUsername) {
            bodyData.employeeUsername = eUsername;
        }
        if (lev) {
            bodyData.type = lev;
        }
        if (sCode) {
            bodyData.supervisorId = [sCode];
        }
        if (idlock) {
            bodyData.idLock = idlock;
        }
        return bodyData;
    };

    useEffect(() => {
        searchEmployee({ limit: 10, page: 1 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchSearchData = (page, total, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(10);
        }
        searchEmployee({
            page,
            limit: total,
            ...getDataManagementFilterData({ circle, region, area, territory, distributionHouse }),
            ...getFilterData(empId, empCode, empName, empUsername, empLevel, supervisor, idLock),
        });
    };
    const dispatch = useDispatch();
    const { reFetchFilter } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetTeamManagementFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // user information log
    const { user: userInfo } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Add Employee', user: userInfo.name });
    }, [userInfo.name]);

    // unlock user api hook
    const [unLockUser, { isLoading: unlockLoading }] = useUnLockUserMutation();

    // unlock user
    const unlockUser = async (id) => {
        try {
            const res = await unLockUser({
                userId: id,
            }).unwrap();
            message.success('Task Complete');
            fetchSearchData(currentPage, totalShowPage);
        } catch (error) {
            message.error(error.message);
        }
    };

    // suspend user api hook
    const [suspendUserApi, { isLoading: suspendLoading }] = useSuspendUserApiMutation();

    // suspend user
    const suspendUser = async (id) => {
        try {
            const res = await suspendUserApi({
                userId: id,
            }).unwrap();
            message.success('Task Complete');
            fetchSearchData(currentPage, totalShowPage);
        } catch (error) {
            message.error(error.message);
        }
    };

    // forceLogout user api hook
    const [forceLogoutUser, { isLoading: forceLogoutLoading }] = useForceLogoutUserMutation();

    // logout user
    const forceLogoutUserFunc = async (id) => {
        try {
            const res = await forceLogoutUser({
                userId: id,
            }).unwrap();
            message.success('Task Complete');
            fetchSearchData(currentPage, totalShowPage);
        } catch (error) {
            message.error(error.message);
        }
    };

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetchSearchData(pageNumber, totalPageChange);
    };
    const isEditableUser = !!userInfo?.permission?.find((x) => x.label === 'User Edit')?.label;

    // edit employee modal state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editUserData, setEditUserData] = useState({});

    // update password modal
    const [passModalOpen, setPassModalOpen] = useState(false);
    const [passCngId, setPassCngId] = useState('');

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="View Employee" />

            {/* edit modal */}
            {isModalOpen ? (
                <EditModal
                    fetchSearchData={fetchSearchData}
                    page={currentPage}
                    limit={totalShowPage}
                    userData={editUserData}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                />
            ) : null}
            {passModalOpen ? (
                <PasswordChangeModal
                    passCngModal={passModalOpen}
                    setPassCngModal={setPassModalOpen}
                    id={passCngId}
                />
            ) : null}
            <div style={{ margin: '16px 0' }}>
                <Filter
                    loading={isLoading}
                    queryFunc={fetchSearchData}
                    pathname="/teamManagement"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">Employee Details</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    <Table
                        pagination={false}
                        rowKey="username"
                        size="small"
                        scroll={{
                            x: 800,
                        }}
                        loading={isLoading}
                        dataSource={data?.data?.map(
                            ({
                                name,
                                employeeCode,
                                type,
                                dh,
                                username,
                                serialNo,
                                supervisor: supervisorName,
                                _id,
                                isLocked,
                                isSuspended,
                            }) => ({
                                name,
                                employeeCode,
                                type,
                                serialNo,
                                supervisorName,
                                circle: [...new Set(dh.map(({ circle: cName }) => cName))].join(
                                    ', '
                                ),
                                region: [...new Set(dh.map(({ region: rName }) => rName))].join(
                                    ', '
                                ),
                                area: [...new Set(dh.map(({ area: aName }) => aName))].join(', '),
                                territory: [
                                    ...new Set(dh.map(({ territory: tName }) => tName)),
                                ].join(', '),
                                dh: dh.map(({ name: dName }) => dName).join(', '),
                                dhCode: dh.map(({ dhCode }) => dhCode).join(', '),
                                username,
                                id: _id,
                                isLocked,
                                isSuspended,
                            })
                        )}
                    >
                        <Column title="Emp Name" dataIndex="name" key="name" />
                        <Column title="Emp Code" dataIndex="employeeCode" key="employeeCode" />
                        <Column
                            style={{ zIndex: -1 }}
                            title="Emp Level"
                            dataIndex="type"
                            key="type"
                            render={(_, record) => record.type.toUpperCase()}
                        />
                        <Column
                            title="Supervisor Name"
                            dataIndex="supervisorName"
                            key="supervisorName"
                        />
                        <Column title="Market Operation" dataIndex="circle" key="circle" />
                        <Column title="Region" dataIndex="region" key="region" />
                        <Column title="Area" dataIndex="area" key="area" />
                        <Column title="Territory" dataIndex="territory" key="territory" />
                        <Column title="DH" dataIndex="dh" key="dh" />
                        <Column title="DH Code" dataIndex="dhCode" key="dhCode" />
                        <Column title="Username" dataIndex="username" key="username" />
                        <Column
                            title="Active"
                            dataIndex="isSuspended"
                            key="isSuspended"
                            render={(_, record) => (record.isSuspended ? 'No' : 'Yes')}
                        />
                        {isEditableUser ? (
                            <Column
                                title="Action"
                                key="action"
                                align="right"
                                render={(_, record) => (
                                    <Space size={2}>
                                        <Button
                                            title="Edit"
                                            size="small"
                                            icon={<EditOutlined />}
                                            type="primary"
                                            danger
                                            shape="circle"
                                            onClick={() => {
                                                setEditUserData(record);
                                                setIsModalOpen(true);
                                            }}
                                        />

                                        <Popconfirm
                                            title="Force Logout Warning!"
                                            description="Are you sure to logout this user?"
                                            // eslint-disable-next-line no-underscore-dangle
                                            onConfirm={() => forceLogoutUserFunc(record.id)}
                                            okText="Yes"
                                            cancelText="No"
                                            okType="danger"
                                            okButtonProps={{
                                                loading: forceLogoutLoading,
                                            }}
                                        >
                                            <Button
                                                title="Force Logout"
                                                size="small"
                                                icon={<LogoutOutlined />}
                                                type="primary"
                                                danger
                                                shape="circle"
                                            />
                                        </Popconfirm>

                                        {record?.isLocked ? (
                                            <Popconfirm
                                                title="Unlock User"
                                                description="Are you sure to unlock this user?"
                                                // eslint-disable-next-line no-underscore-dangle
                                                onConfirm={() => unlockUser(record.id)}
                                                okText="Yes"
                                                cancelText="No"
                                                okButtonProps={{
                                                    loading: unlockLoading,
                                                }}
                                            >
                                                <Button
                                                    title="Unlock User"
                                                    size="small"
                                                    icon={<UnlockOutlined />}
                                                    type="primary"
                                                    shape="circle"
                                                />
                                            </Popconfirm>
                                        ) : null}

                                        <Popconfirm
                                            title="User Suspend Warning!"
                                            description="Are you sure to suspend this user?"
                                            // eslint-disable-next-line no-underscore-dangle
                                            onConfirm={() => suspendUser(record.id)}
                                            okText="Yes"
                                            cancelText="No"
                                            okType="danger"
                                            okButtonProps={{
                                                loading: suspendLoading,
                                            }}
                                        >
                                            <Button
                                                title="Suspend"
                                                size="small"
                                                icon={<StopOutlined />}
                                                type="primary"
                                                danger
                                                shape="circle"
                                            />
                                        </Popconfirm>
                                        <Button
                                            title="Password Change"
                                            size="small"
                                            icon={<KeyOutlined />}
                                            type="primary"
                                            danger
                                            shape="circle"
                                            onClick={() => {
                                                setPassCngId(record.id);
                                                setPassModalOpen(true);
                                            }}
                                        />
                                    </Space>
                                )}
                            />
                        ) : null}
                    </Table>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: '10px',
                    }}
                >
                    <Pagination
                        size="large"
                        pageSize={totalShowPage}
                        showSizeChanger
                        showQuickJumper
                        current={currentPage}
                        defaultCurrent={1}
                        total={data?.meta?.total}
                        onChange={onChange}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    />
                </div>
            </div>
        </>
    );
}

export default TeamManagement;
