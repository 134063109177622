import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Select } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import DateRange from '../../components/DateRange';
import {
    setDateRange,
    setEmpLevel,
} from '../../redux/features/leaveManagement/leaveManagementFilterSlice';

function LeaveManagementFilter({ queryFunc, loading }) {
    const dispatch = useDispatch();

    // date picker function
    const dataPickerFunc = (_, date) => {
        dispatch(setDateRange(date));
    };

    return (
        <>
            <DateRange dataPickerFunc={dataPickerFunc} />
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    showArrow
                    placeholder="EMP Level"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setEmpLevel(e))}
                    options={[
                        {
                            value: 'TMR',
                            label: 'TMR',
                        },
                        {
                            value: 'TMS',
                            label: 'TMS',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    loading={loading}
                    disabled={loading}
                    size="large"
                    className="filter-btn"
                    icon={<SearchOutlined />}
                    type="primary"
                    style={{ background: '#faad14', color: '#fff' }}
                    onClick={() => queryFunc()}
                >
                    Search
                </Button>
            </Col>
        </>
    );
}

export default LeaveManagementFilter;
