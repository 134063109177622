/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { Row } from 'antd';
import React from 'react';

import AiPosmListFilter from '../features/AiPosmList/AiPosmListFilter';
import AttendanceFilter from '../features/Attendance/AttendanceFilter';
import DailyActivityReportFilter from '../features/DailyActivityReport/DailyActivityReportFilter';
import DashboardFilter from '../features/Dashboard/DashboardFilter';
import DamageAndLostFilter from '../features/Inventory/DamageAndLostFilter';
import DhHistoryFilter from '../features/Inventory/DhHistoryFilter';
import DhSummaryFilter from '../features/Inventory/DhSummaryFilter';
import TmrHistoryFilter from '../features/Inventory/TmrHistoryFilter';
import TmrSummaryFilter from '../features/Inventory/TmrSummaryFilter';
import LeaveManagementFilter from '../features/LeaveManagement/LeaveManagementFilter';
import LoginDetailsFilter from '../features/LoginDetails/LoginDetailsFilter';
import ReceiveMaterialFilter from '../features/MaterialManagement/ReceiveMaterialFilter';
import NoticeFilter from '../features/Notice/NoticeFilter';
import TmrExecutionFilter from '../features/Report/TMR/TmrExecutionFilter';
import TmsExecutionFilter from '../features/Report/TMS/TmsExecutionFilter';
import RoutePlanTrackerFilter from '../features/RoutePlanTracker/RoutePlanTrackerFilter';
import TeamManagementFilter from '../features/TeamManagement/TeamManagementFilter';
import TrainingFilter from '../features/TrainingModule/TrainingFilter';
import DownloadFilter from '../features/downloadReport/DownloadFilter';
import DataManagementFilter from './DataManagementFilter';

// mapping of pathnames to components
const filterComponents = {
    '/': DashboardFilter,
    '/notice': NoticeFilter,
    '/training-module': TrainingFilter,
    '/leaveManagement': LeaveManagementFilter,
    '/attendance': AttendanceFilter,
    '/login': LoginDetailsFilter,
    '/routePlanTracker': RoutePlanTrackerFilter,
    '/teamManagement': TeamManagementFilter,
    '/tmr-execution': TmrExecutionFilter,
    '/tms-execution': TmsExecutionFilter,
    '/inventory-dh': DhHistoryFilter,
    '/inventory-damageAndLost': DamageAndLostFilter,
    '/inventory-dhSummary': DhSummaryFilter,
    '/inventory-tmrHistory': TmrHistoryFilter,
    '/inventory-tmrSummary': TmrSummaryFilter,
    '/receive-material': ReceiveMaterialFilter,
    '/dailyActivityReport': DailyActivityReportFilter,
    '/aiPosmList': AiPosmListFilter,
    '/download-report': DownloadFilter,
};

function Filter({ pathname, queryFunc, loading, selectAllDisable }) {
    // get the appropriate filter component based on the pathname
    const ActivePageFilter = filterComponents[pathname] || null;

    return (
        <Row gutter={[5, 5]}>
            {pathname !== '/aiPosmList' && pathname !== '/login' && (
                <DataManagementFilter selectAllDisable={selectAllDisable} />
            )}
            {ActivePageFilter && <ActivePageFilter loading={loading} queryFunc={queryFunc} />}
        </Row>
    );
}

export default Filter;
