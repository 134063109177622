import { Table } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addIds, setIds } from '../../redux/features/superuser/permissionIdsSlice';

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'value',
    },
];
// const data = [
//     {
//         key: 2,
//         name: 'Attendance',
//         value: 'abcdefghijklmnopqrstuvwxyz',
//     },
// ];

function PermissionTable({ data }) {
    const { ids } = useSelector((state) => state.permissionsIds);

    const dispatch = useDispatch();

    // rowSelection objects indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            if (selected) {
                dispatch(
                    addIds([
                        ...selectedRows.map((item) => ({ name: item.name, value: item.value })),
                    ])
                );
            } else if (!selected && record?.children?.length) {
                dispatch(
                    setIds(
                        ids
                            .filter((item) => !record.children.find((x) => x.value === item.value))
                            .filter((x) => x.value !== record.value)
                    )
                );
            } else {
                dispatch(setIds(ids.filter((item) => item.value !== record.value)));
            }
        },
        onSelectInvert: (selectedRowKeys) => {
            // console.log(selectedRowKeys);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            // console.log(selected, selectedRows, changeRows);
        },
    };
    return (
        <Table
            title={(e) => e[0].name}
            rowKey="key"
            showHeader={false}
            pagination={false}
            columns={columns}
            rowSelection={{
                ...rowSelection,
                checkStrictly: false,
            }}
            dataSource={data}
        />
    );
}
export default PermissionTable;
