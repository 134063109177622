import { apiSlice } from '../api/apiSlice';

export const dataManagementApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // get data management filters
        getDataManagement: builder.mutation({
            query: (data) => ({
                url: '/v1/datamanagement',
                method: 'POST',
                body: data,
            }),
        }),
        // add circle
        addCircle: builder.mutation({
            query: (data) => ({
                url: '/v1/datamanagement/circle',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['circleList'],
        }),
        // get region list
        getCircleList: builder.query({
            query: (data) => ({
                url: '/v1/datamanagement/circles',
            }),
            providesTags: ['circleList'],
        }),
        // add region
        addRegion: builder.mutation({
            query: (data) => ({
                url: '/v1/datamanagement/region',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['regionList'],
        }),
        // get region list
        getRegionList: builder.query({
            query: (data) => ({
                url: '/v1/datamanagement/regions',
            }),
            providesTags: ['regionList'],
        }),
        // add area
        addArea: builder.mutation({
            query: (data) => ({
                url: '/v1/datamanagement/area',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['areaList'],
        }),
        // get area list
        getAreaList: builder.query({
            query: (data) => ({
                url: '/v1/datamanagement/areas',
            }),
            providesTags: ['areaList'],
        }),
        // add territory
        addTerritory: builder.mutation({
            query: (data) => ({
                url: '/v1/datamanagement/territory',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['territoryList'],
        }),
        // get territory list
        getTerritoryList: builder.query({
            query: (data) => ({
                url: '/v1/datamanagement/territories',
            }),
            providesTags: ['territoryList'],
        }),
        // add dh
        addDh: builder.mutation({
            query: (data) => ({
                url: '/v1/datamanagement/dh',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['dhList'],
        }),
        // get dh list
        getDhList: builder.query({
            query: (data) => ({
                url: '/v1/datamanagement/dh',
            }),
        }),
        // get dh list and his up layer
        getDhData: builder.query({
            query: () => ({
                url: '/v1/datamanagement/dhs-details',
            }),
            providesTags: ['dhList'],
        }),
        // get single dh information
        getSingleDh: builder.query({
            query: ({ id }) => ({
                url: `/v1/datamanagement/dh/${id}`,
            }),
        }),
    }),
});

export const {
    useGetDataManagementMutation,
    useAddCircleMutation,
    useGetCircleListQuery,
    useAddAreaMutation,
    useAddDhMutation,
    useAddRegionMutation,
    useAddTerritoryMutation,
    useGetAreaListQuery,
    useGetDhListQuery,
    useGetRegionListQuery,
    useGetTerritoryListQuery,
    useGetDhDataQuery,
    useGetSingleDhQuery,
} = dataManagementApi;
