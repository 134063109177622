/* eslint-disable prettier/prettier */
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Col, Select } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateRange from '../../components/DateRange';
import FilterButton from '../../components/FilterButton';
import {
    reportType, setDateRange
} from '../../redux/features/downloadReport/downloadReportFilterSlice';

function DownloadFilter({queryFunc, loading}) {
    const dispatch = useDispatch();

    const { dateRange } = useSelector((state) => state.downloadFilter);

    // date picker function
    const dataPickerFunc = (_, date) => {
        dispatch(setDateRange(date));
    };

    const {
        user: { employeeLevel },
    } = useSelector((state) => state.auth);

    return (
        <>
            <DateRange  dataPickerFunc={dataPickerFunc} />
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    placeholder="Report Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(v) => dispatch(reportType(v))}
                    options={[
                        {
                            label: 'Attendance Report',
                            value: 'Attendance Report',
                        },
                        {
                            label: 'Damage and Lost Material Report',
                            value: 'Damage and Lost Material Report',
                        },
                        {
                            label: 'Leave Report',
                            value: 'Leave Report',
                        },
                        {
                            label: 'TMR History Report',
                            value: 'TMR History Report',
                        },
                        {
                            label: 'TMR Summary Report',
                            value: 'TMR Summary Report',
                        },
                        {
                            label: 'TMR Execution Duration Report',
                            value: 'TMR Execution Duration Report',
                        },
                        {
                            label: 'TMR Execution Report',
                            value: 'TMR Execution Report',
                        },
                        {
                            label: 'TMS Joint/Control Call Report',
                            value: 'TMS Join/Control Report',
                        },
                        {
                            label: 'DH History Report',
                            value: 'DH History Report',
                        },
                        {
                            label: 'DH Summary Report',
                            value: 'DH Summary Report',
                        },
                        {
                            label: 'Uddokta TMR AI Result Report',
                            value: 'Uddokta TMR AI Result Report',
                        },
                        {
                            label: 'TMR Target VS Achievement Report',
                            value: 'TMR Target VS Achievement Report',
                        },
                        {
                            label: 'TMR TMS Tagging Report',
                            value: 'TMR TMS Tagging Report',
                        },
                        {
                            label: 'Total Visit Uddokta Count Report',
                            value: 'Total Visit Uddokta Count Report',
                        },
                        {
                            label: 'SOV Report',
                            value: 'SOV Report',
                        },
                        {
                            label: 'Absent Present TMRs Report',
                            value: 'Absent Present TMRs Report',
                        },
                        {
                            label: 'Master Data',
                            value: 'Master Data',
                        },
                        {
                            label: 'PJP Allocation Data',
                            value: 'PJP Allocation Data',
                        },
                        {
                            label: 'TMR Target',
                            value: 'TMR Target',
                        },
                    ]}
                />
            </Col>
            
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <FilterButton loading={loading} fn={queryFunc} icon={<CloudDownloadOutlined />} title="Export Data" />
            </Col>
        </>
    );
}

export default DownloadFilter;
