/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dateRange: [],
};

const dashboardFilterSlice = createSlice({
    name: 'dashboardFilterSlice',
    initialState,
    reducers: {
        setDateRange: (state, action) => {
            state.dateRange = action.payload;
        },
        resetDashboardFilter: (state, action) => {
            state.dateRange = [];
        },
    },
});

export default dashboardFilterSlice.reducer;
export const { setDateRange, resetDashboardFilter } = dashboardFilterSlice.actions;
