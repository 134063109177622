import { Pagination, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { useGetDhHistoryMutation } from '../../redux/features/inventory/inventoryApiSlice';
import { resetDhHistoryFilterSlice } from '../../redux/features/inventory/viewDhHistoryFilterSlice';
import { setReFetchFilter } from '../../redux/features/loaderSlice';
import firebaseLog from '../../util/firebaseLog';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';

const { Column, ColumnGroup } = Table;

function DhHistory() {
    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    // dh history filter hook
    const { dateRange, entryType, dhCode } = useSelector((state) => state.dhHistoryFilter);

    // filter data
    const { circle, region, area, territory, distributionHouse } = useSelector(
        (state) => state.dataManagement
    );

    const getDhHistoryFilterData = (dateArr, type, dhcode) => {
        const bodyData = {};

        if (dateArr[0] && dateArr[1]) {
            const [s, e] = dateArr;
            bodyData.from = s;
            bodyData.to = e;
        }
        if (type) {
            bodyData.type = type;
        }
        if (dhcode) {
            bodyData.dhCode = dhcode;
        }
        return bodyData;
    };

    const [getDhHistory, { data, isLoading }] = useGetDhHistoryMutation();

    const fetchDhHistoryData = (page, totalShow, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(10);
        }

        getDhHistory({
            page,
            limit: totalShow,
            ...getDataManagementFilterData({ circle, region, area, territory, distributionHouse }),
            ...getDhHistoryFilterData(dateRange, entryType, dhCode),
        });
    };

    useEffect(() => {
        getDhHistory({ page: currentPage, limit: totalShowPage });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetchDhHistoryData(pageNumber, totalPageChange);
    };

    const dispatch = useDispatch();
    const { reFetchFilter } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetDhHistoryFilterSlice());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // text change
    function textChange(txt) {
        if (txt === 'allocated') {
            return 'Allocated';
        }
        if (txt === 'received') {
            return 'Received';
        }
        if (txt === 'transfer') {
            return 'Transfer to another DH';
        }
        if (txt === 'transfer-received') {
            return 'Transfer received from another DH';
        }
        if (txt === 'assigned') {
            return 'Allocate to TMR';
        }
        if (txt === 'return') {
            return 'Return from TMR';
        }
        return '';
    }

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'DH History', user: user.name });
    }, [user.name]);

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="DH History" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    loading={isLoading}
                    queryFunc={fetchDhHistoryData}
                    pathname="/inventory-dh"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">DH History</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    <Table
                        pagination={false}
                        rowKey="_id"
                        scroll={{ x: 800 }}
                        size="small"
                        loading={isLoading}
                        dataSource={data?.data}
                    >
                        <Column title="Market Operation" dataIndex="circle" key="circle" />
                        <Column title="Region" dataIndex="region" key="region" />
                        <Column title="Area" dataIndex="area" key="area" />
                        <Column title="Territory" dataIndex="territory" key="territory" />
                        <Column title="DH" dataIndex="dh" key="dh" />
                        <Column title="DH Code" dataIndex="dhcode" key="dhcode" />
                        <Column title="Material Name" dataIndex="material" key="material" />
                        <Column title="Material Qty" dataIndex="quantity" key="quantity" />
                        <Column
                            title="Entry Type"
                            dataIndex="type"
                            key="type"
                            render={(type) => textChange(type)}
                        />
                        <Column
                            title="Timestamp"
                            dataIndex="createdAt"
                            key="createdAt"
                            render={(_, record) =>
                                dayjs(record.createdAt).format('DD/MM/YYYY hh:mm:ss A')
                            }
                        />
                    </Table>
                </div>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Pagination
                    size="large"
                    pageSize={totalShowPage}
                    showSizeChanger
                    showQuickJumper
                    current={currentPage}
                    defaultCurrent={1}
                    total={data?.meta.total}
                    onChange={onChange}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                />
            </div>
        </>
    );
}

export default DhHistory;
