import { LogoutOutlined, SettingOutlined, UnlockOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Space, message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HelmetHeader from '../../components/HelmetHeader';
import {
    useChangePasswordMutation,
    useChangeProfileMutation,
    useLogoutMutation,
    useWhoAmIQuery,
} from '../../redux/features/auth/authApi';
import { userLoggedOut } from '../../redux/features/auth/authSlice';
import firebaseLog from '../../util/firebaseLog';
import { capitalizeFirstLetter } from '../../util/miniFunction';
import ChangePassModal from './ChangePassModal';
import EditProfileModal from './EditProfileModal';

function Profile() {
    const { accessToken } = useSelector((state) => state.auth);

    const [logData, setLogData] = useState([]);
    const dispatch = useDispatch();

    const { data: profileData, isLoading: profileLoading } = useWhoAmIQuery({}, {});

    // navigate hook
    const navigate = useNavigate();

    const [logout] = useLogoutMutation();

    const handleLogout = () => {
        logout();
        sessionStorage.removeItem('auth');
        dispatch(userLoggedOut());
        navigate('/login');
    };

    const [editModalVisible, setEditModalVisible] = useState(false);
    const [changePassModal, setChangePassModal] = useState(false);

    const handleEditModalCancel = () => {
        setEditModalVisible(false);
    };

    const handleChangePassModalCancel = () => {
        setChangePassModal(false);
    };

    const [changeProfile, { data: updateProfileData }] = useChangeProfileMutation();
    const handleEditModalFinish = async (values) => {
        try {
            const bodyData = {
                name: values.name || profileData?.data.name,
                email: values.email || profileData?.data.email,
                refreshTime: values.refreshTime || profileData?.data.refreshTime,
                sessionTimeOut: values.sessionTimeOut || profileData?.data.sessionTimeOut,
                image: {
                    thumb: '',
                    original: profileData?.data?.image?.original || '',
                },
            };
            const result = await changeProfile({ ...bodyData }).unwrap();
            if (result?.message) {
                message.success(result?.message);
            }
            setEditModalVisible(false);
        } catch (error) {
            message.error('Something went wrong');
        }
    };

    const [changePassword, { data, isLoading }] = useChangePasswordMutation();
    // password change form
    const handleChangePassModalFinish = async (values) => {
        try {
            const result = await changePassword({
                ...values,
            }).unwrap();
            message.success('Password changed successfully');
            setChangePassModal(false);
        } catch (error) {
            message.error('Something went wrong');
        }
    };

    // user information log
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Profile', user: profileData?.data?.name });
    }, [profileData?.data?.name]);

    if (profileLoading) return 'Please wait...';

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Profile" />

            <EditProfileModal
                user={profileData?.data}
                visible={editModalVisible}
                onCancel={handleEditModalCancel}
                onFinish={handleEditModalFinish}
            />

            <ChangePassModal
                visible={changePassModal}
                onCancel={handleChangePassModalCancel}
                onFinish={handleChangePassModalFinish}
            />

            <div className="profile">
                <Card className="profile-card" style={{ paddingTop: '40px' }}>
                    <Avatar
                        size={220}
                        icon={<UserOutlined />}
                        src={profileData?.data?.image?.original}
                    />
                    <h2>{profileData?.data.name}</h2>
                    <p style={{ margin: 0 }}>{profileData?.data.username}</p>
                    <p style={{ margin: 0 }}>{capitalizeFirstLetter(profileData?.data.type)}</p>
                    <p>{profileData?.data.email}</p>
                    <Button danger type="primary" onClick={handleLogout} icon={<LogoutOutlined />}>
                        Logout
                    </Button>

                    <div className="settings-button-container">
                        <Space>
                            <Button
                                icon={<SettingOutlined />}
                                onClick={() => setEditModalVisible(true)}
                            />
                            <Button
                                icon={<UnlockOutlined />}
                                onClick={() => setChangePassModal(true)}
                            >
                                Change Password
                            </Button>
                        </Space>
                    </div>
                </Card>
            </div>
        </>
    );
}

export default Profile;
