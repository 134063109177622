import { Button, Form, Input, InputNumber, Select, message } from 'antd';
import React from 'react';
import {
    useAddDhMutation,
    useGetAreaListQuery,
    useGetCircleListQuery,
    useGetRegionListQuery,
    useGetTerritoryListQuery,
} from '../../redux/features/dataManagement/dataManagementApi';

const { Option } = Select;

function AddDh() {
    // get dropdown data
    const { data: circleList, isLoading } = useGetCircleListQuery();
    const { data: regionList } = useGetRegionListQuery();
    const { data: areaList } = useGetAreaListQuery();
    const { data: territoryList } = useGetTerritoryListQuery();

    // add dh api hook
    const [addDh, { isLoading: addLoading }] = useAddDhMutation();

    const [form] = Form.useForm();
    const onFinish = async (values) => {
        try {
            const result = await addDh({
                name: values.dh,
                circleId: values.dhCircle,
                regionId: values.dhRegion,
                areaId: values.dhArea,
                territoryId: values.dhTerritory,
                dhCode: values.dhCode,
                dhMaWallet: values.dhWallet.toString(),
                lat: values.lat,
                long: values.lon,
            }).unwrap();
            message.success('DH added successfully');
            form.resetFields();
        } catch (error) {
            message.error('Failed!');
        }
    };
    return (
        <div className="data-box">
            <h3>Add DH</h3>
            <div>
                <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
                    <Form.Item
                        name="dhCircle"
                        label="Circle"
                        rules={[
                            {
                                required: true,
                                message: 'Missing circle',
                            },
                        ]}
                    >
                        <Select placeholder="Circle Name" size="large" options={circleList?.data} />
                    </Form.Item>

                    <Form.Item
                        name="dhRegion"
                        label="Region"
                        rules={[
                            {
                                required: true,
                                message: 'Missing region',
                            },
                        ]}
                    >
                        <Select placeholder="Region Name" size="large" options={regionList?.data} />
                    </Form.Item>

                    <Form.Item
                        name="dhArea"
                        label="Area"
                        rules={[
                            {
                                required: true,
                                message: 'Missing area',
                            },
                        ]}
                    >
                        <Select placeholder="Area Name" size="large" options={areaList?.data} />
                    </Form.Item>

                    <Form.Item
                        name="dhTerritory"
                        label="Territory"
                        rules={[
                            {
                                required: true,
                                message: 'Missing territory',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Territory Name"
                            size="large"
                            options={territoryList?.data}
                        />
                    </Form.Item>

                    <Form.Item
                        name="dh"
                        label="Distribution House"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input size="large" placeholder="DH Name" />
                    </Form.Item>

                    <Form.Item
                        name="dhCode"
                        label="DH Code"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input size="large" placeholder="DH Code" />
                    </Form.Item>

                    <Form.Item
                        name="dhWallet"
                        label="DH Wallet"
                        rules={[
                            {
                                required: true,
                            },
                            {
                                pattern: /(^(\+8801|8801|01|008801))[1|3-9]{1}(\d){8}$/,
                                message: 'Please Provide a valid phone number',
                            },
                        ]}
                    >
                        <Input size="large" placeholder="DH Wallet" />
                    </Form.Item>

                    <Form.Item
                        name="lat"
                        label="DH latitude"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ minWidth: '200px' }}
                            size="large"
                            placeholder="DH latitude"
                        />
                    </Form.Item>

                    <Form.Item
                        name="lon"
                        label="DH longitude"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ minWidth: '200px' }}
                            size="large"
                            placeholder="DH longitude"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            disabled={addLoading}
                            loading={addLoading}
                            type="primary"
                            htmlType="submit"
                        >
                            Add DH
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default AddDh;
