import { EditOutlined } from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HelmetHeader from '../../components/HelmetHeader';
import { useViewGroupListQuery } from '../../redux/features/superuser/superUserApi';
import firebaseLog from '../../util/firebaseLog';
import GroupEditModal from './GroupEditModal';

const { Column, ColumnGroup } = Table;

function ViewGroup() {
    const { data, isLoading, refetch } = useViewGroupListQuery();

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'View Group', user: user.name });
    }, [user.name]);

    // edit employee modal state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editGroupData, setEditGroupData] = useState({});

    const refetchData = () => {
        refetch();
    };

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="View Group" />

            {/* edit modal */}
            {isModalOpen ? (
                <GroupEditModal
                    fetchSearchData={refetchData}
                    groupData={editGroupData}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                />
            ) : null}

            <div style={{ padding: '10px', width: '100%' }}>
                <Table rowKey="name" loading={isLoading} pagination={false} dataSource={data?.data}>
                    <Column title="Group Name" dataIndex="name" key="name" />
                    <Column title="Modules" dataIndex="modules" key="modules" />
                    <Column
                        title="Action"
                        key="action"
                        align="right"
                        render={(_, record) => (
                            <Space size={2}>
                                {record.name === 'Superadmin' ? null : (
                                    <Button
                                        title="Edit"
                                        size="small"
                                        icon={<EditOutlined />}
                                        type="primary"
                                        danger
                                        shape="circle"
                                        onClick={() => {
                                            setEditGroupData(record);
                                            setIsModalOpen(true);
                                        }}
                                    />
                                )}
                            </Space>
                        )}
                    />
                </Table>
            </div>
        </>
    );
}

export default ViewGroup;
