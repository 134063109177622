import { Table } from 'antd';
import React from 'react';
import TableSkeleton from '../../ui/TableSkeleton';

const { Column, ColumnGroup } = Table;

function ReportTable({ data, loading }) {
    return (
        <div style={{ borderRadius: '10px' }}>
            <div className="box-heading">Daily Activity Report</div>

            <div style={{ padding: '10px', width: '100%' }}>
                {loading ? (
                    <TableSkeleton />
                ) : (
                    <Table
                        rowKey="_id"
                        pagination={false}
                        scroll={{
                            x: 750,
                        }}
                        size="small"
                        loading={false}
                        dataSource={data}
                    >
                        <Column title="Market Operation" dataIndex="circle" key="circle" />
                        <Column title="Region" dataIndex="region" key="region" />
                        <Column title="Area" dataIndex="area" key="area" />
                        <Column title="Territory" dataIndex="territory" key="territory" />
                        <Column title="DH" dataIndex="dh" key="dh" />
                        <Column title="DH Code" dataIndex="dhcode" key="dhcode" />
                        <Column title="Emp Code" dataIndex="employeeId" key="employeeId" />
                        <Column title="Emp Name" dataIndex="employeeName" key="employeeName" />
                        <Column
                            align="center"
                            title="Emp Level"
                            dataIndex="employeeLevel"
                            key="employeeLevel"
                        />
                        <Column
                            align="center"
                            title="Attendance Marked"
                            dataIndex="punchIn"
                            key="punchIn"
                        />
                        <Column
                            align="center"
                            title="Dayend Marked"
                            dataIndex="punchOut"
                            key="punchOut"
                        />
                        <Column
                            align="center"
                            title="Route List"
                            dataIndex="assignedRoutes"
                            key="assignedRoutes"
                        />
                        <Column align="center" title="Target" dataIndex="target" key="target" />
                        <Column
                            align="center"
                            title="Strike Rate"
                            dataIndex="achivement"
                            key="achivement"
                        />
                    </Table>
                )}
            </div>
        </div>
    );
}

export default ReportTable;
