/* eslint-disable react/jsx-no-useless-fragment */
import { Col, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { resetDhSummaryFilter } from '../../redux/features/inventory/dhSummaryFilterSlice';
import { useGetDhSummaryMutation } from '../../redux/features/inventory/inventoryApiSlice';
import { setGlobalLoading, setReFetchFilter } from '../../redux/features/loaderSlice';
import NoResult from '../../ui/NoResult';
import firebaseLog from '../../util/firebaseLog';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import DhSummaryTable from './DhSummaryTable';

const { Column, ColumnGroup } = Table;

function DhSummary() {
    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    // dh history filter hook
    const { dateRange, dhCode } = useSelector((state) => state.dhSummaryFilter);

    // filter data
    const { circle, region, area, territory, distributionHouse } = useSelector(
        (state) => state.dataManagement
    );

    const getDhHistoryFilterData = (dateArr, dhcode) => {
        const bodyData = {};

        if (dateArr[0] && dateArr[1]) {
            const [s, e] = dateArr;
            bodyData.from = s;
            bodyData.to = e;
        }
        if (dhcode) {
            bodyData.dhCode = dhcode;
        }
        return bodyData;
    };

    const [getDhSummary, { data, isLoading }] = useGetDhSummaryMutation();

    const transformData = data?.data?.map((item) => ({
        circle: item.circle,
        region: item.region,
        area: item.area,
        territory: item.territory,
        dh: item.name,
        dhcode: item.dhcode,
        countable: item.material
            ?.filter((x) => x.category === 'countable')
            ?.map(({ name, pending, received, used, quantity, transferReceived, transfer }) => ({
                materialName: name,
                pendingQuantity: pending,
                inhand: quantity,
                received,
                totalUsed: used,
                transferReceived,
                transfer,
            })),
        toolkit: item.material
            ?.filter((x) => x.category === 'toolkit')
            ?.map(({ name, pending, received, used, quantity, transferReceived, transfer }) => ({
                materialName: name,
                pendingQuantity: pending,
                inhand: quantity,
                received,
                totalUsed: used,
                transferReceived,
                transfer,
            })),
    }));

    const fetchDhSummaryData = (page, totalShow, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(10);
        }

        getDhSummary({
            page,
            limit: totalShow,
            ...getDataManagementFilterData({ circle, region, area, territory, distributionHouse }),
            ...getDhHistoryFilterData(dateRange, dhCode),
        });
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setGlobalLoading(isLoading));
    }, [dispatch, isLoading]);

    // const onChange = (pageNumber, totalPageChange) => {
    //     setTotalPageShow(() => totalPageChange);
    //     setCurrentPageShow(pageNumber);
    //     fetchDhSummaryData(pageNumber, totalPageChange);
    // };

    const { reFetchFilter } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetDhSummaryFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'DH Summary', user: user.name });
    }, [user.name]);

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="DH Summary" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    loading={isLoading}
                    queryFunc={fetchDhSummaryData}
                    pathname="/inventory-dhSummary"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">DH Summary</div>

                {transformData?.length ? (
                    <>
                        {transformData?.map((item, i) => (
                            <div
                                // eslint-disable-next-line react/no-array-index-key
                                key={i}
                                style={{
                                    boxShadow: '0 0 5px 0 #dad5d5',
                                    borderRadius: '10px',
                                    padding: '10px',
                                    margin: '10px 0',
                                }}
                            >
                                <Row gutter={[10, 10]} justify="space-evenly">
                                    <Col>
                                        <p style={{ margin: 0 }}>
                                            <span style={{ fontWeight: 500 }}>
                                                Market Operation:{' '}
                                            </span>
                                            {item.circle}
                                        </p>
                                    </Col>
                                    <Col>
                                        <p style={{ margin: 0 }}>
                                            <span style={{ fontWeight: 500 }}>Region: </span>
                                            {item.region}
                                        </p>
                                    </Col>
                                    <Col>
                                        {' '}
                                        <p style={{ margin: 0 }}>
                                            <span style={{ fontWeight: 500 }}>Area: </span>
                                            {item.area}
                                        </p>
                                    </Col>
                                    <Col>
                                        <p style={{ margin: 0 }}>
                                            <span style={{ fontWeight: 500 }}>Territory: </span>
                                            {item.territory}
                                        </p>
                                    </Col>
                                    <Col>
                                        <p style={{ margin: 0 }}>
                                            <span style={{ fontWeight: 500 }}>DH: </span>
                                            {item.dh}
                                        </p>
                                    </Col>
                                    <Col>
                                        <p style={{ margin: 0 }}>
                                            <span style={{ fontWeight: 500 }}>DH Code: </span>
                                            {item.dhcode}
                                        </p>
                                    </Col>
                                </Row>
                                <Row
                                    style={{ marginTop: '10px' }}
                                    gutter={[10, 10]}
                                    justify="space-evenly"
                                >
                                    <Col lg={12} sm={24}>
                                        <div
                                            style={{
                                                background: '#faad14',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                padding: '5px',
                                                borderRadius: '2px',
                                            }}
                                        >
                                            <h4 style={{ margin: 0, color: '#fff' }}>Countable</h4>
                                        </div>
                                        <DhSummaryTable
                                            data={item?.countable}
                                            isLoading={isLoading}
                                        />
                                    </Col>

                                    <Col lg={12} sm={24}>
                                        <div
                                            style={{
                                                background: '#faad14',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                padding: '5px',
                                                borderRadius: '2px',
                                            }}
                                        >
                                            <h4 style={{ margin: 0, color: '#fff' }}>Toolkit</h4>
                                        </div>
                                        <DhSummaryTable
                                            data={item?.toolkit}
                                            isLoading={isLoading}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </>
                ) : (
                    <NoResult />
                )}
            </div>

            {/* <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Pagination
                    size="large"
                    pageSize={totalShowPage}
                    showSizeChanger
                    showQuickJumper
                    current={currentPage}
                    defaultCurrent={1}
                    total={data?.meta.total}
                    onChange={onChange}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                />
            </div> */}
        </>
    );
}

export default DhSummary;
