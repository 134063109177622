/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dateRange: [],
    dhCode: '',
};

const damageAndLostFilterSlice = createSlice({
    name: 'damageAndLostFilterSlice',
    initialState,
    reducers: {
        setDateRange: (state, action) => {
            state.dateRange = action.payload;
        },
        setDhCodeDamageAndLost: (state, action) => {
            state.dhCode = action.payload;
        },
        resetDamageAndLostFilter: (state, action) => {
            state.dateRange = [];
            state.dhCode = action.payload;
        },
    },
});

export default damageAndLostFilterSlice.reducer;
export const { setDateRange, setDhCodeDamageAndLost, resetDamageAndLostFilter } =
    damageAndLostFilterSlice.actions;
