import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateRange from '../../components/DateRange';
import {
    setDateRange,
    setDhCodeTmrSummary,
    setTmrId,
} from '../../redux/features/inventory/tmrSummaryFilterSlice';
import { useSearchEmployeeMutation } from '../../redux/features/teamManagement/teamManagementApi';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';

function TmrSummaryFilter({ queryFunc, loading }) {
    const { dateRange, dhCode } = useSelector((state) => state.tmrSummaryFilter);

    const dispatch = useDispatch();

    // date picker function
    const dataPickerFunc = (_, date) => {
        dispatch(setDateRange(date));
    };

    // filter data
    const { circle, region, area, territory, distributionHouse } = useSelector(
        (state) => state.dataManagement
    );

    // search employee api hook
    const [searchEmployee, { data: employeeData, isLoading }] = useSearchEmployeeMutation();

    // get tmr data
    useEffect(() => {
        searchEmployee({
            ...getDataManagementFilterData({ circle, region, area, territory, distributionHouse }),
            type: 'tmr',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchEmployee, circle, region, area, territory, distributionHouse]);

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };

    return (
        <>
            <DateRange dataPickerFunc={dataPickerFunc} />

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    mode="multiple"
                    showSearch
                    loading={isLoading}
                    allowClear
                    showArrow
                    placeholder="TMR Name"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setTmrId(e))}
                    options={employeeData?.data?.map((emp) => ({
                        label: emp.name,
                        // eslint-disable-next-line no-underscore-dangle
                        value: emp._id,
                    }))}
                    filterOption={(input, option) =>
                        option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    searchValue={search}
                    onSearch={onSearch}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={dhCode}
                    placeholder="DH Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setDhCodeTmrSummary(e.target.value))}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Space size={2}>
                    <Button
                        loading={loading}
                        disabled={loading}
                        size="large"
                        className="filter-btn"
                        icon={<SearchOutlined />}
                        type="primary"
                        style={{ background: '#faad14', color: '#fff' }}
                        onClick={() => queryFunc(1, 10, 'cleanShowResultOnPage')}
                    >
                        Search
                    </Button>
                </Space>
            </Col>
        </>
    );
}

export default TmrSummaryFilter;
