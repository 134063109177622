import { Image, Pagination, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { resetDamageAndLostFilter } from '../../redux/features/inventory/damageAndLostFilterSlice';
import { useGetTmrDamageAndLostMutation } from '../../redux/features/inventory/inventoryApiSlice';
import { setReFetchFilter } from '../../redux/features/loaderSlice';
import firebaseLog from '../../util/firebaseLog';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';

const { Column, ColumnGroup } = Table;

function DamageAndLost() {
    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    // filter hook
    const { dateRange, dhCode } = useSelector((state) => state.damageAndLostFilter);

    // filter data
    const { circle, region, area, territory, distributionHouse } = useSelector(
        (state) => state.dataManagement
    );

    const getDhHistoryFilterData = (dateArr, dhcode) => {
        const bodyData = {};

        if (dateArr[0] && dateArr[1]) {
            const [form, to] = dateArr;
            bodyData.from = form;
            bodyData.to = to;
        }
        if (dhcode) {
            bodyData.dhCode = dhcode;
        }
        return bodyData;
    };

    const [getTmrDamageAndLost, { data, isLoading }] = useGetTmrDamageAndLostMutation();

    // query function
    const fetchDamageAndLostData = (page, totalShow, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(10);
        }
        getTmrDamageAndLost({
            page,
            limit: totalShow,
            ...getDataManagementFilterData({ circle, region, area, territory, distributionHouse }),
            ...getDhHistoryFilterData(dateRange, dhCode),
        });
    };

    // pagination change event
    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetchDamageAndLostData(pageNumber, totalPageChange);
    };

    useEffect(() => {
        getTmrDamageAndLost({
            page: currentPage,
            limit: totalShowPage,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatch = useDispatch();
    const { reFetchFilter } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetDamageAndLostFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Damage & Lost History', user: user.name });
    }, [user.name]);

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Damage & Lost History" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    loading={isLoading}
                    queryFunc={fetchDamageAndLostData}
                    pathname="/inventory-damageAndLost"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">Damage & Lost History</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    <Table
                        rowKey="_id"
                        pagination={false}
                        scroll={{
                            x: 750,
                        }}
                        size="small"
                        loading={isLoading}
                        dataSource={data?.data}
                    >
                        <Column title="Market Operation" dataIndex="circle" key="circle" />
                        <Column title="Region" dataIndex="region" key="region" />
                        <Column title="Area" dataIndex="area" key="area" />
                        <Column title="Territory" dataIndex="territory" key="territory" />
                        <Column title="DH" dataIndex="dh" key="dh" />
                        <Column title="DH Code" dataIndex="dhcode" key="dhcode" />
                        <Column title="TMR" dataIndex="tmr" key="tmr" />
                        <Column title="Material Name" dataIndex="material" key="material" />
                        <Column
                            title="Damage Qty"
                            dataIndex="damageQuantity"
                            key="damageQuantity"
                        />
                        <Column title="Lost Qty" dataIndex="lostQuantity" key="lostQuantity" />
                        <Column
                            title="Timestamp"
                            dataIndex="createdAt"
                            key="createdAt"
                            render={(_, record) =>
                                dayjs(record.createdAt).format('DD/MM/YYYY h:mm:ss A')
                            }
                        />
                        <Column
                            title="Image"
                            key="image"
                            render={(_, record) => (
                                <Image width={60} src={record?.image?.original || ''} />
                            )}
                        />
                    </Table>
                </div>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Pagination
                    size="large"
                    pageSize={totalShowPage}
                    showSizeChanger
                    showQuickJumper
                    current={currentPage}
                    defaultCurrent={1}
                    total={data?.meta.total}
                    onChange={onChange}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                />
            </div>
        </>
    );
}

export default DamageAndLost;
