import AiPosmList from '../features/AiPosmList/AiPosmList';
import Attendance from '../features/Attendance/Attendance';
import DailyActivityReport from '../features/DailyActivityReport/DailyActivityReport';
import Dashboard from '../features/Dashboard/Dashboard';
import DataManagement from '../features/DataManagement/DataManagement';
import DamageAndLost from '../features/Inventory/DamageAndLost';
import DhHistory from '../features/Inventory/DhHistory';
import DhSummary from '../features/Inventory/DhSummary';
import TmrHistory from '../features/Inventory/TmrHistory';
import TmrSummary from '../features/Inventory/TmrSummary';
import LeaveManagement from '../features/LeaveManagement/LeaveManagement';
import LoginDetails from '../features/LoginDetails/LoginDatails';
import AllocateMaterial from '../features/MaterialManagement/AllocateMaterial';
import ApproveRejectTransfer from '../features/MaterialManagement/ApproveRejectTransfer';
import MaterialMovement from '../features/MaterialManagement/MaterialMovement';
import MaterialViewAndAdd from '../features/MaterialManagement/MaterialViewAndAdd';
import ReceivedMaterial from '../features/MaterialManagement/ReceivedMaterial';
import Notice from '../features/Notice/Notice';
import PjpAllocation from '../features/PjpAllocation/PjpAllocation';
import Profile from '../features/Profile/Profile';
import TmrExecution from '../features/Report/TMR/TmrExecution';
import TmsExecution from '../features/Report/TMS/TmsExecution';
import RoutePlanTracker from '../features/RoutePlanTracker/RoutePlanTracker';
import AddGroup from '../features/SuperUser/AddGroup';
import AddUser from '../features/SuperUser/AddUser';
import ViewGroup from '../features/SuperUser/ViewGroup';
import ViewUser from '../features/SuperUser/ViewUser';
import TmrTarget from '../features/Target/TmrTarget/TmrTarget';
import TmsTarget from '../features/Target/TmsTarget/TmsTarget';
import AddEmployee from '../features/TeamManagement/AddEmployee';
import TeamManagement from '../features/TeamManagement/TeamManagement';
import Ticket from '../features/Ticket/Ticket';
import TrainingModule from '../features/TrainingModule/TrainingModule';
import DownloadReport from '../features/downloadReport/DownloadReport';

const routeData = [
    {
        name: 'Profile',
        path: '/profile',
        element: <Profile />,
    },
    {
        name: 'Dashboard',
        path: '/',
        element: <Dashboard />,
    },
    {
        name: 'Attendance',
        path: '/attendance',
        element: <Attendance />,
    },
    {
        name: 'Notice',
        path: '/notice',
        element: <Notice />,
    },
    {
        name: 'Tickets',
        path: '/tickets',
        element: <Ticket />,
    },
    {
        name: 'Login Details',
        path: '/login-details',
        element: <LoginDetails />,
    },
    {
        name: 'Training Module',
        path: '/training-module',
        element: <TrainingModule />,
    },
    {
        name: 'Leave Management',
        path: '/leave-management',
        element: <LeaveManagement />,
    },
    {
        name: 'View Employee',
        path: '/view-employee',
        element: <TeamManagement />,
    },
    {
        name: 'Add Employee',
        path: '/add-employee',
        element: <AddEmployee />,
    },
    {
        name: 'Data Management',
        path: '/data-management',
        element: <DataManagement />,
    },
    {
        name: 'Route Plan Tracker',
        path: '/route-plan-tracker',
        element: <RoutePlanTracker />,
    },
    {
        name: 'PJP Allocation',
        path: '/pjp-allocation',
        element: <PjpAllocation />,
    },
    {
        name: 'TMR Target',
        path: '/tmr-target',
        element: <TmrTarget />,
    },
    {
        name: 'TMS Target',
        path: '/tms-target',
        element: <TmsTarget />,
    },
    {
        name: 'Add Group',
        path: '/add-group',
        element: <AddGroup />,
    },
    {
        name: 'View Group',
        path: '/view-group',
        element: <ViewGroup />,
    },
    {
        name: 'View User',
        path: '/view-user',
        element: <ViewUser />,
    },
    {
        name: 'Add User',
        path: '/add-user',
        element: <AddUser />,
    },
    {
        name: 'TMR Execution',
        path: '/tmr-execution',
        element: <TmrExecution />,
    },
    {
        name: 'TMS Call',
        path: '/tms-call',
        element: <TmsExecution />,
    },
    {
        name: 'View Material',
        path: '/material/view',
        element: <MaterialViewAndAdd />,
    },
    {
        name: 'Allocate Material',
        path: '/material/allocate-material',
        element: <AllocateMaterial />,
    },
    {
        name: 'Receive Material in DH',
        path: '/material/receive-material-in-dh',
        element: <ReceivedMaterial />,
    },
    {
        name: 'Material Movement',
        path: '/material/material-movement',
        element: <MaterialMovement />,
    },
    {
        name: 'Approve/Reject Transfer',
        path: '/material/approve-reject-transfer',
        element: <ApproveRejectTransfer />,
    },
    {
        name: 'DH History',
        path: '/inventory/dh-history',
        element: <DhHistory />,
    },
    {
        name: 'DH Summary',
        path: '/inventory/dh-summary',
        element: <DhSummary />,
    },
    {
        name: 'Damage & Lost',
        path: '/inventory/damage-lost',
        element: <DamageAndLost />,
    },
    {
        name: 'TMR History',
        path: '/inventory/tmr-history',
        element: <TmrHistory />,
    },
    {
        name: 'TMR Summary',
        path: '/inventory/tmr-summary',
        element: <TmrSummary />,
    },
    {
        name: 'Daily Activity Report',
        path: '/daily-activity-report',
        element: <DailyActivityReport />,
    },
    {
        name: 'Download Report',
        path: '/download-report',
        element: <DownloadReport />,
    },
    {
        name: 'AI POSM List',
        path: '//ai-posm-list',
        element: <AiPosmList />,
    },
];

export default routeData;
