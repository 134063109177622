/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import { message, notification, Spin, theme } from 'antd';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { useDownloadReportDataMutation } from '../../redux/features/downloadReport/downloadReportApi';
import {
    resetDownloadFilter,
} from '../../redux/features/downloadReport/downloadReportFilterSlice';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { setGlobalLoading, setReFetchFilter } from '../../redux/features/loaderSlice';


dayjs.extend(isToday)

function DownloadReport() {
    const dispatch = useDispatch();

    const { globalLoading } = useSelector((state) => state.globalLoading);


    const { user } = useSelector((state) => state.auth);
    const { circle, region, area, territory, distributionHouse } = useSelector(
        (state) => state.dataManagement
    );
    const { reportType, dateRange, report } = useSelector((state) => state.downloadFilter);
        
    const { accessToken } = useSelector((state) => state.auth);
    
    const { reFetchFilter } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetDownloadFilter());
    }, [dispatch]);

    const [loading, setLoading] = useState(false);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    // error notificaiton
    const [api, contextHolder] = notification.useNotification();
    const openNotification = () => {
        api.warning({
        message: `Warning`,
        description: 'Please select a report type.',
        placement: 'topRight'
        });
    };

    // masterdata download
    const handleDownload = () => {
        // Set the dynamic URL for the download
        const dynamicUrl = 'https://nagad-pulse.sgp1.digitaloceanspaces.com/Uddokta%20Master%20Data.xlsx';
        
        const anchor = document.createElement('a');
        anchor.href = dynamicUrl;
        anchor.download = 'Masterdata.xlsx'; // Optional: You can set the filename here
        anchor.style.display = 'none'; // Hide the link
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      };

    function getBodyData() {
        const bodyData = {};
            bodyData.circleId = circle?.map((r) => r.value) || [];
            bodyData.regionId = region?.map((r) => r.value) || [];
            bodyData.areaId = area?.map((r) => r.value) || [];
            bodyData.territoryId = territory?.map((r) => r.value) || [];
            bodyData.dhId = distributionHouse?.map((r) => r.value) || [];
        if (dateRange.length) {
            let [s, e] = dateRange;

            const isSameDay = dayjs(e).isToday()

        if (isSameDay) {
            e = dayjs().toJSON()
        } else {
            e = dayjs(e).endOf("day")
        }
        s = dayjs(s).startOf("day");
        

            bodyData.startDate = s
            bodyData.endDate = e;
            bodyData.fromDate = s
            bodyData.toDate = e;
        }
        return bodyData;
    }

    // check json
    function checkJSON (doc) {
        return doc?.headers.get('Content-Type').includes('json')
    }

    async function downLoadingFile (doc) {
        const excName = doc?.headers.get('Content-Disposition').split('"')[1] || 'report.xlsx';
        const fResult = await doc.arrayBuffer();
        const blob = new Blob([fResult]);

        const urla = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urla;
        link.setAttribute('download', `${excName}`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    // download link function
    const download = async ({url, fileName, masterData}) => {
        if (masterData) {
            handleDownload();
        } else {
            try {
                dispatch(setGlobalLoading(true))
                for (let index = -1;;) {
                    // eslint-disable-next-line no-await-in-loop
                    const [result1, result2, result3] = await Promise.all([
                        fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': ` Bearer ${accessToken}`,
                            },
                            // eslint-disable-next-line no-plusplus
                            body: JSON.stringify({...getBodyData(), index: ++index}),
                            mode: 'cors'
                        }),
                        fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': ` Bearer ${accessToken}`,
                            },
                            // eslint-disable-next-line no-plusplus
                            body: JSON.stringify({...getBodyData(), index: ++index}),
                            mode: 'cors'
                        }),
                        fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': ` Bearer ${accessToken}`,
                            },
                            // eslint-disable-next-line no-plusplus
                            body: JSON.stringify({...getBodyData(), index: ++index}),
                            mode: 'cors'
                        }),
                    ])
                    const typeJSON1 = checkJSON(result1);
                    const typeJSON2 = checkJSON(result2);
                    const typeJSON3 = checkJSON(result3);
    
                    const arr = [];
    
                    if (!typeJSON1) arr.push(downLoadingFile(result1));
                    if (!typeJSON2) arr.push(downLoadingFile(result2));
                    if (!typeJSON3) arr.push(downLoadingFile(result3));
    
                    // eslint-disable-next-line no-await-in-loop
                    await Promise.all(arr);
                    
                    if (typeJSON1 || typeJSON2 || typeJSON3) break;
                    
                }
            } catch (error) {
                message.error('Something went wrong')
            } finally {
                dispatch(setGlobalLoading(false))
            }
        }
    };    

    const [downloadReportData] = useDownloadReportDataMutation();

    

    const fetchSurveyQueryData =  async () => {
            if (dateRange.length) {
                try {
                    if (reportType === 'Attendance Report') {
                        download(
                            {
                                url: '/v1/report/attendance', 
                                fileName: 'Attendance Report.xlsx'
                            }
                        ) 
                    } else if (reportType === 'Damage and Lost Material Report') {
                        download(
                            {
                                url: '/v1/report/damage-and-lost-material', fileName: 'Damage and Lost Material Report.xlsx'
                            }
                        )
                    } else if (reportType === 'Leave Report') {
                        download(
                            {
                                url: '/v1/report/leave', 
                                fileName: 'Leave Report.xlsx'
                            }
                        )
                    } else if (reportType === 'TMR History Report') {
                        download(
                            {
                                url: '/v1/report/tmr-history', 
                                fileName: 'TMR History Report.xlsx'
                            }
                        )
                        
                    } else if (reportType === 'TMR Summary Report') {
                        download(
                            {
                                url: '/v1/report/tmr-summary',
                                fileName: 'TMR Summary Report.xlsx',
                            }
                        )
                    } else if (reportType === 'TMR Execution Duration Report') {
                        download(
                            {
                                url: '/v1/report/tmr-execution-duration', fileName: 'TMR Execution Duration Report.xlsx'
                            }
                        )
                    } else if (reportType === 'TMR Execution Report') {
                        download(
                            {
                                url: '/v1/report/tmr-execution', fileName: 'TMR Execution Report.xlsx'
                            }
                        )
                    
                    } else if (reportType === 'TMS Join/Control Report') {
                        download(
                            {
                                url: '/v1/report/tms-joint-call-and-control-call', fileName: 'TMS Join/Control Report.xlsx'
                            }
                        )

                    } else if (reportType === 'DH History Report') {
                        download(
                            {
                                url: '/v1/report/dh-history', fileName: 'DH History Report.xlsx'
                            }
                        )
                    } else if (reportType === 'DH Summary Report') {
                        download(
                            {
                                url: '/v1/report/dh-summary', fileName: 'DH Summary Report.xlsx'
                            }
                        )
                    }  else if (reportType === 'Uddokta TMR AI Result Report') {
                        download(
                            {
                                url: '/v1/report/ai-tmr-execution', fileName: 'Uddokta TMR AI Result Report.xlsx'
                            }
                        )
                    } else if (reportType === 'TMR Target VS Achievement Report') {
                        download(
                            {
                                url: '/v1/report/tmr-target-vs-achievement', fileName: 'TMR Target VS Achievement Report.xlsx'
                            }
                        )
                    } else if (reportType === 'TMR TMS Tagging Report') {
                        download(
                            {
                                url: '/v1/report/tmr-tms-tagging-report', fileName: 'TMR TMS Tagging Report.xlsx'
                            }
                        )
                    } else if (reportType === 'Total Visit Uddokta Count Report') {
                        download(
                            {
                                url: '/v1/report/total-visited-uddokta-count', fileName: 'Total Visit Uddokta Report.xlsx'
                            }
                        )
                    } else if (reportType === 'SOV Report') {
                        download(
                            {
                                url: '/v1/report/sov-report', fileName: 'SOV Report.xlsx'
                            }
                        )
                    } else if (reportType === 'Master Data') {
                        download({
                            masterData: true,
                        })
                    } else if (reportType === 'Absent Present TMRs Report') {
                        download(
                            {
                                url: '/v1/report/absent-present-tmrs', fileName: 'Absent Present TMRs Report.xlsx'
                            }
                        )
                    } else if (reportType === 'PJP Allocation Data') {
                        download(
                            {
                                url: '/v1/report/route-plan', fileName: 'PJP Allocation Data.xlsx'
                            }
                        )
                    } else if (reportType === 'TMR Target') {
                        download(
                            {
                                url: '/v1/report/tmr-target',
                                fileName: 'TMR Target.xlsx'
                            }
                        )
                    }
                    else {
                        openNotification();
                    }
                } catch (error) {
                    message.error('Something went wrong')
                } finally {
                    setGlobalLoading(false);
                }
            } else {
                message.error('Date are required')
            }
    };

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Download Report" />
            
            {contextHolder}
            <div style={{ margin: '16px 0' }}>
                <Filter loading={globalLoading} queryFunc={fetchSurveyQueryData} pathname="/download-report" />
            </div>
            <div
                style={{
                    padding: 15,
                    minHeight: 400,
                    background: colorBgContainer,
                }}
            >
                <div
                    style={{ width: '100%', height: '50vh', display: 'grid', placeItems: 'center' }}
                >
                    {globalLoading ? <Spin size="large" /> : null}
                </div>
            </div>
        </>
    );
}

export default DownloadReport;
