/* eslint-disable react/no-array-index-key */
import { AuditOutlined, CaretRightOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Divider, Image, Modal, Row, Space, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import MapModal from '../../../components/MapModal';
import { capitalizeFirstLetter } from '../../../util/miniFunction';

const { Column, ColumnGroup } = Table;

function TmsExecutionItem({ data }) {
    const {
        tmsName,
        tmsCode,
        tmrName,
        tmrCode,
        orgName,
        tmrExecutionDh,
        tmrExecutedOutletWalletNo,
        uddoktaName,
        retailerCode,
        merchandisingImages,
        outletImages,
        lat,
        long,
        outletCode,
        outletName,
        createdAt,
        isQrCodeMatched,
        isLocationMatched,
        isQrCodeScanned,
        remarks,
        allQuestions,
        callType,
        tmsCallImageUrl,
        route,
        tmrExecutionType,
        materials,
        competitions,
        sovDetails,
        isAiReady,
    } = data;
    // modal state and function
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const imageData = [
        outletImages?.insideImgUrl,
        outletImages?.outsideImgUrl,
        tmsCallImageUrl,
        ...merchandisingImages,
    ].filter((item) => item);

    // error reason generation function
    function getErrorReason(qrMach, isLocation, qrScan) {
        if ((!qrScan || !qrMach) && !isLocation) {
            return 'QR and Location Error';
        }
        if ((!qrScan || !qrMach) && isLocation) {
            return 'QR Code';
        }
        if (!isLocation && (qrScan || qrMach)) {
            return 'Location Error';
        }
        return '';
    }

    // get is error
    const isErr = !isQrCodeMatched || !isLocationMatched || !isQrCodeScanned;

    // marking modal
    const [openModal, setOpenModal] = useState(false);
    const markingModalOk = () => {
        setOpenModal(false);
    };
    const markingModalCancel = () => {
        setOpenModal(false);
    };

    const competitionsData = [
        {
            title: 'Bkash',
            data: competitions?.bkash,
        },
        {
            title: 'Rocket',
            data: competitions?.rocket,
        },
        {
            title: 'Upay',
            data: competitions?.upay,
        },
    ].filter((items) => items.data?.length);
    const sov = [
        {
            title: 'Nagad',
            quantity: sovDetails?.nagad?.quantity || 0,
            percent: sovDetails?.nagad?.percentage || 0,
        },
        {
            title: 'Bkash',
            quantity: sovDetails?.bkash?.quantity || 0,
            percent: sovDetails?.bkash?.percentage || 0,
        },
        {
            title: 'Rocket',
            quantity: sovDetails?.rocket?.quantity || 0,
            percent: sovDetails?.rocket?.percentage || 0,
        },
        {
            title: 'Upay',
            quantity: sovDetails?.upay?.quantity || 0,
            percent: sovDetails?.upay?.percentage || 0,
        },
    ];

    return (
        <>
            <Modal
                footer={false}
                title="Location in google Map"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width="850px"
                bodyStyle={{ padding: 0 }}
            >
                <MapModal lat={lat || 0} lon={long || 0} />
            </Modal>
            <Modal
                footer={false}
                title="Rating"
                open={openModal}
                onOk={markingModalOk}
                onCancel={markingModalCancel}
                width="850px"
                bodyStyle={{ padding: 0 }}
            >
                <Table
                    pagination={false}
                    rowKey="question"
                    size="small"
                    scroll={{
                        x: 500,
                    }}
                    dataSource={allQuestions || []}
                >
                    <Column title="Rating Parameter" dataIndex="questionEN" key="questionEN" />
                    <Column title="Rating Given" dataIndex="answer" key="answer" align="right" />
                </Table>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                    <h3>Total</h3>
                    <h3>{allQuestions?.reduce((a, c) => a + c.answer, 0)}</h3>
                </div>
            </Modal>

            <div className="execution-item" style={isErr ? { background: '#C50B00' } : {}}>
                <Row
                    gap={[5, 5]}
                    justify="space-between"
                    className={isErr ? 'execution-item-error' : ''}
                >
                    <Col lg={6} md={12} sm={24}>
                        <p className="ex-item-point">
                            <span style={{ fontWeight: '500' }}>Call Type: </span>
                            {callType}
                        </p>
                        <p className="ex-item-point">
                            <span style={{ fontWeight: '500' }}>TMS Name: </span>
                            {tmsName}
                        </p>
                        <p className="ex-item-point">
                            <span style={{ fontWeight: '500' }}>TMS Code: </span>
                            {tmsCode}
                        </p>
                        <p className="ex-item-point">
                            <span style={{ fontWeight: '500' }}>TMR Name: </span>
                            {tmrName}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Uddokta Code: </span>
                            {outletCode}
                        </p>

                        <p className="ex-item-point">
                            <span className="ex-item-point-b">DH: </span>
                            {tmrExecutionDh}
                        </p>
                    </Col>

                    <Col lg={6} md={12} sm={24}>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Uddokta Wallet: </span>
                            {tmrExecutedOutletWalletNo}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Route: </span>
                            {route}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Org Name: </span>
                            {outletName}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">TMR Execution Type: </span>
                            {capitalizeFirstLetter(tmrExecutionType)}
                        </p>
                    </Col>

                    <Col lg={6} md={12} sm={24}>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Remarks: </span>
                            {remarks}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Timestamp: </span>
                            {dayjs(createdAt).format('DD/MM/YYYY hh:mm:ss a')}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Reason for error: </span>
                            {!isErr
                                ? ' '
                                : getErrorReason(
                                      isQrCodeMatched,
                                      isLocationMatched,
                                      isQrCodeScanned
                                  )}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">AI Run: </span>
                            {isAiReady ? 'Yes' : 'No'}
                        </p>
                    </Col>

                    <Col
                        lg={6}
                        md={12}
                        sm={24}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <div className="survey-last-section">
                            <Image.PreviewGroup
                                preview={{
                                    countRender: (current) =>
                                        imageData[current - 1]?.imageName || 'null',
                                }}
                            >
                                <Row gutter={[2, 2]}>
                                    {imageData.map(({ original }, i) => (
                                        <Col key={original + i}>
                                            <Image width={45} src={original || ''} />
                                        </Col>
                                    ))}
                                </Row>
                            </Image.PreviewGroup>
                        </div>

                        <Space direction="vertical" size="small">
                            <Button
                                style={{ marginLeft: '5px' }}
                                onClick={() => setIsModalOpen((prev) => !prev)}
                            >
                                <EnvironmentOutlined />
                            </Button>
                            <Button
                                style={{ marginLeft: '5px' }}
                                onClick={() => setOpenModal((prev) => !prev)}
                            >
                                <AuditOutlined />
                            </Button>
                        </Space>
                    </Col>
                </Row>

                <Divider style={{ margin: '5px 0' }} />
                <Collapse
                    bordered={false}
                    ghost
                    // eslint-disable-next-line react/no-unstable-nested-components
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    items={[
                        {
                            key: '1',
                            label: 'Expand Report',
                            children: (
                                <Row gutter={[10, 10]}>
                                    <Col>
                                        <div>
                                            <h3 style={isErr ? { color: 'white' } : {}}>Nagad</h3>
                                            <Table
                                                pagination={false}
                                                rowKey="_id"
                                                size="small"
                                                scroll={{
                                                    x: 300,
                                                }}
                                                style={{
                                                    maxWidth: '325px',
                                                    border: '1px solid #FAFAFA',
                                                    borderRadius: '10px 8px 0 0',
                                                }}
                                                dataSource={
                                                    materials.filter(
                                                        (x) => x.category !== 'toolkit'
                                                    ) || []
                                                }
                                            >
                                                <Column
                                                    title="Material"
                                                    dataIndex="name"
                                                    key="name"
                                                />
                                                <Column
                                                    title="Used Qty"
                                                    dataIndex="usedQty"
                                                    key="usedQty"
                                                    render={(_, record) =>
                                                        record.category === 'toolkit'
                                                            ? 'Yes'
                                                            : record.usedQty
                                                    }
                                                />
                                                <Column
                                                    title="AI Detect"
                                                    dataIndex="aiDetectedQty"
                                                    key="aiDetectedQty"
                                                    render={(_, record) =>
                                                        record.category === 'toolkit'
                                                            ? ''
                                                            : record.aiDetectedQty
                                                    }
                                                />
                                            </Table>

                                            <Table
                                                pagination={false}
                                                rowKey="_id"
                                                size="small"
                                                scroll={{
                                                    x: 300,
                                                }}
                                                title={() => 'Toolkit'}
                                                style={{
                                                    maxWidth: '325px',
                                                    border: '1px solid #FAFAFA',
                                                    borderRadius: '10px 8px 0 0',
                                                    marginTop: '10px',
                                                }}
                                                dataSource={
                                                    materials.filter(
                                                        (x) => x.category !== 'countable'
                                                    ) || []
                                                }
                                            >
                                                <Column
                                                    title="Material"
                                                    dataIndex="name"
                                                    key="name"
                                                />
                                                <Column
                                                    title="Used"
                                                    dataIndex="usedQty"
                                                    key="usedQty"
                                                    render={(_, record) =>
                                                        record.category === 'toolkit'
                                                            ? 'Yes'
                                                            : record.usedQty
                                                    }
                                                />
                                            </Table>
                                        </div>
                                    </Col>
                                    {competitionsData?.length > 0 ? (
                                        <Col>
                                            <div>
                                                <h3 style={isErr ? { color: 'white' } : {}}>
                                                    Competition
                                                </h3>
                                                {competitionsData?.map((item) => (
                                                    <Table
                                                        key={item.title}
                                                        pagination={false}
                                                        rowKey="_id"
                                                        size="small"
                                                        scroll={{
                                                            x: 300,
                                                        }}
                                                        style={{
                                                            maxWidth: '325px',
                                                            marginBottom: '5px',
                                                            border: '1px solid #FAFAFA',
                                                            borderRadius: '10px 10px 0 0',
                                                        }}
                                                        dataSource={item.data || []}
                                                        title={() => item.title}
                                                    >
                                                        <Column
                                                            title="Material"
                                                            dataIndex="name"
                                                            key="name"
                                                        />
                                                        <Column
                                                            title="AI Detect"
                                                            dataIndex="quantity"
                                                            key="quantity"
                                                            render={(_, record) =>
                                                                record.isCount
                                                                    ? record.quantity
                                                                    : record.value
                                                            }
                                                        />
                                                    </Table>
                                                ))}
                                            </div>
                                        </Col>
                                    ) : null}
                                    <Col>
                                        <div>
                                            <h3 style={isErr ? { color: 'white' } : {}}>
                                                SOV By Count
                                            </h3>
                                            <Table
                                                pagination={false}
                                                rowKey="_id"
                                                size="small"
                                                scroll={{
                                                    x: 300,
                                                }}
                                                style={{
                                                    maxWidth: '325px',
                                                    marginBottom: '5px',
                                                    border: '1px solid #FAFAFA',
                                                    borderRadius: '10px 8px 0 0',
                                                }}
                                                dataSource={sov || []}
                                            >
                                                <Column
                                                    title="Name"
                                                    dataIndex="title"
                                                    key="title"
                                                />
                                                <Column
                                                    title="Quantity"
                                                    dataIndex="quantity"
                                                    key="quantity"
                                                    align="center"
                                                />
                                                <Column
                                                    title="Percentage"
                                                    dataIndex="percent"
                                                    key="percent"
                                                    align="center"
                                                    render={(v) => `${Math.round(v)}%`}
                                                />
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            ),
                            style: {
                                borderRadius: '5px',
                                border: 'none',
                            },
                        },
                    ]}
                />
            </div>
        </>
    );
}

export default TmsExecutionItem;
