import { apiSlice } from '../api/apiSlice';

export const dailyActivityApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDailyActivityReport: builder.mutation({
            query: (data) => ({
                url: `/v1/user/user-activity`,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const { useGetDailyActivityReportMutation } = dailyActivityApi;
