import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateRange from '../../../components/DateRange';
import {
    setCallType,
    setDateRange,
    setErrorReason,
    setIsAiReady,
    setQrCodeScanned,
    setTmrCode,
    setTmrId,
    setUddoktaCode,
} from '../../../redux/features/report/tmr/tmrExecutionFilterSlice';
import { useSearchEmployeeMutation } from '../../../redux/features/teamManagement/teamManagementApi';
import getDataManagementFilterData from '../../../util/generateDataManagementFilterData';

function TmrExecutionFilter({ queryFunc, loading }) {
    const { uddoktaCode, tmsCode, errorReason, callType, tmrCode } = useSelector(
        (state) => state.tmrExecutionFilter
    );

    // filter data
    const { circle, region, area, territory, distributionHouse } = useSelector(
        (state) => state.dataManagement
    );

    const dispatch = useDispatch();

    // search employee api hook
    const [searchEmployee, { data: employeeData, isLoading }] = useSearchEmployeeMutation();

    // date picker function
    const dataPickerFunc = (_, date) => {
        dispatch(setDateRange(date));
    };

    useEffect(() => {
        searchEmployee({
            ...getDataManagementFilterData({ circle, region, area, territory, distributionHouse }),
            type: 'tmr',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchEmployee, circle, region, area, territory, distributionHouse]);

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };

    // user information log
    const { user: userInfo } = useSelector((state) => state.auth);
    const isEditableUser = !!userInfo?.permission?.find((x) => x.label === 'User Edit')?.label;

    return (
        <>
            <DateRange dataPickerFunc={dataPickerFunc} />
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={uddoktaCode}
                    placeholder="Uddokta Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setUddoktaCode(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    loading={isLoading}
                    allowClear
                    showSearch
                    placeholder="TMR Name"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setTmrId(e))}
                    options={employeeData?.data?.map((emp) => ({
                        label: emp.name,
                        // eslint-disable-next-line no-underscore-dangle
                        value: emp._id,
                    }))}
                    filterOption={(input, option) =>
                        option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    searchValue={search}
                    onSearch={onSearch}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    showArrow
                    placeholder="Error Reason"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setErrorReason(e))}
                    options={[
                        {
                            value: 'qrError',
                            label: 'QR code not match',
                        },
                        {
                            value: 'location',
                            label: 'Location not match',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    showArrow
                    placeholder="QR Code Scan"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setQrCodeScanned(e))}
                    options={[
                        {
                            value: 'yes',
                            label: 'Yes',
                        },
                        {
                            value: 'no',
                            label: 'No',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    showArrow
                    mode="multiple"
                    placeholder="Call Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setCallType(e))}
                    options={[
                        {
                            value: 'install',
                            label: 'Install',
                        },
                        {
                            value: 'maintenance',
                            label: 'Maintenance',
                        },
                        {
                            value: 'no',
                            label: 'No Execution',
                        },
                    ]}
                />
            </Col>
            {isEditableUser ? (
                <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                    <Select
                        allowClear
                        showArrow
                        placeholder="AI Run"
                        size="large"
                        style={{
                            width: '100%',
                        }}
                        onChange={(e) => dispatch(setIsAiReady(e))}
                        options={[
                            {
                                value: 'yes',
                                label: 'Yes',
                            },
                            {
                                value: 'no',
                                label: 'No',
                            },
                        ]}
                    />
                </Col>
            ) : null}
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={tmrCode}
                    placeholder="Employee Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setTmrCode(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    loading={loading}
                    disabled={loading}
                    size="large"
                    className="filter-btn"
                    icon={<SearchOutlined />}
                    type="primary"
                    style={{ background: '#faad14', color: '#fff' }}
                    onClick={() => queryFunc(1, 10, 'cleanShowResultOnPage')}
                >
                    Search
                </Button>
            </Col>
        </>
    );
}

export default TmrExecutionFilter;
