import { Button, Form, Input, Select, message } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAddTicketMutation } from '../../redux/features/ticket/ticketApi';

function TicketForm({ closeModal }) {
    const { accessToken } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState();
    const [fileName, setFileName] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    console.log(fileUrl);

    const fileChange = async (e) => {
        setLoading(true);
        try {
            const fileData = e.target.files[0];
            const formData = new FormData();
            formData.append('file', fileData);

            setFileName(fileData.name);

            const res = await fetch(
                `${process.env.REACT_APP_API_URL}/v1/upload/file/${dayjs(new Date()).format(
                    'YYYY-MM-DD'
                )}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: formData,
                }
            );
            const result = await res.json();
            console.log(result);
            setFileUrl(result.data.fileUrl || result.data.original);
            message.success(result.message);
        } catch (error) {
            message.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const { Option } = Select;

    const [form] = Form.useForm();

    // add tickets api hook
    const [addTicket, { data, isLoading }] = useAddTicketMutation();

    const onFinish = async (values) => {
        try {
            const result = await addTicket({ ...values, fileUrl }).unwrap();
            message.success('Ticket Raised Successfully');
            form.resetFields();
            setFileUrl('');
            closeModal();
        } catch (error) {
            message.error('Something went wrong');
        }
    };

    return (
        <div>
            <h2>Raise a Ticket</h2>
            <Form form={form} onFinish={onFinish}>
                <Form.Item
                    label="Type"
                    name="type"
                    rules={[{ required: true, message: 'Please select the ticket type' }]}
                >
                    <Select>
                        <Option value="Issue">Issue</Option>
                        <Option value="Feature Request">Feature Request</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: 'Please enter the ticket subject' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ required: true, message: 'Please enter the ticket description' }]}
                >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item label="Attachment">
                    <div>
                        {fileUrl ? (
                            <p style={{ color: '#000' }}>{fileName}</p>
                        ) : (
                            <input
                                className="training-file"
                                type="file"
                                accept=".pdf,.mp4,.png,.jpg,.jpeg"
                                onChange={fileChange}
                            />
                        )}
                    </div>
                </Form.Item>

                <Form.Item>
                    <Button
                        disabled={isLoading}
                        loading={isLoading}
                        type="primary"
                        htmlType="submit"
                    >
                        Submit Ticket
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default TicketForm;
