/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';

function TicTacToeGame() {
    const [board, setBoard] = useState(Array(9).fill(null));
    const [isXNext, setIsXNext] = useState(true);
    const [winner, setWinner] = useState(null);
    const [score, setScore] = useState({ X: 0, O: 0, ties: 0 });

    useEffect(() => {
        const winner = checkForWinner(board);
        if (winner) {
            setWinner(winner);
            updateScore(winner);
        } else if (isBoardFull(board)) {
            // It's a tie
            setWinner('Tie');
            updateScore('Tie');
        }

        // If the game is over (win or tie), reset the game after a delay
        if (winner || isBoardFull(board)) {
            setTimeout(() => {
                resetGame();
            }, 3000); // New game after a few seconds
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [board]);

    useEffect(() => {
        if (isXNext || winner !== null) {
            // If it's not the bot's turn or there's already a winner, do nothing
            return;
        }

        botMove();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isXNext, winner]);

    const updateScore = (result) => {
        if (winner !== null) return; // Prevent multiple score updates

        setScore((prevScore) => {
            if (result === 'Tie') {
                return { ...prevScore, ties: prevScore.ties + 1 };
            }
            return { ...prevScore, [result]: prevScore[result] + 1 };
        });
    };

    const isBoardFull = (squares) => squares.every((square) => square !== null);

    const handleClick = (index) => {
        if (board[index] || winner) {
            // If the cell is already filled or we have a winner, ignore the click
            return;
        }

        // Otherwise, proceed to handle the move
        const newBoard = [...board];
        newBoard[index] = isXNext ? 'X' : 'O';
        setBoard(newBoard);

        // After a move is made, check for a winner
        const currentWinner = checkForWinner(newBoard);
        if (currentWinner) {
            setWinner(currentWinner);
            updateScore(currentWinner);
        } else {
            setIsXNext(!isXNext); // Change turns only if there's no winner
        }
    };

    // without minimax algorithm
    const botMove = () => {
        setTimeout(() => {
            let move;
            const randomFactor = Math.random();
            const difficulty = 0.9; // 90% of the time, the bot will play optimally

            if (randomFactor < difficulty) {
                move = findBestMove(board); // Bot tries to play the best move
            } else {
                move = findRandomMove(board); // Bot plays a random move
            }

            if (move !== -1) {
                handleClick(move);
            }
        }, 500);
    };

    const findBestMove = (board) => {
        let move = findWinningMove(board, 'O'); // 1. Win if possible
        if (move === -1) {
            move = findWinningMove(board, 'X'); // 2. Block X from winning
        }
        if (move === -1 && board[4] === null) {
            move = 4; // 3. Take center if available
        }
        if (move === -1) {
            move = takeCorner(board); // 4. Take a corner if available
        }
        if (move === -1) {
            move = takeSide(board); // 5. Take a side if available
        }
        return move;
    };

    const findRandomMove = (board) => {
        const availableMoves = board
            .map((cell, idx) => (cell === null ? idx : null))
            .filter((v) => v !== null);
        if (availableMoves.length > 0) {
            return availableMoves[Math.floor(Math.random() * availableMoves.length)];
        }
        return -1;
    };

    const findWinningMove = (board, player) => {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < board.length; i++) {
            const newBoard = [...board];
            if (newBoard[i] === null) {
                newBoard[i] = player;
                if (checkForWinner(newBoard) === player) {
                    return i;
                }
            }
        }
        return -1;
    };

    const takeCorner = (board) => {
        const corners = [0, 2, 6, 8];
        const emptyCorners = corners.filter((index) => board[index] === null);
        if (emptyCorners.length > 0) {
            return emptyCorners[Math.floor(Math.random() * emptyCorners.length)];
        }
        return -1;
    };

    const takeSide = (board) => {
        const sides = [1, 3, 5, 7];
        const emptySides = sides.filter((index) => board[index] === null);
        if (emptySides.length > 0) {
            return emptySides[Math.floor(Math.random() * emptySides.length)];
        }
        return -1;
    };

    // minimax algorithm
    // const botMove = () => {
    //     setTimeout(() => {
    //         const bestMove = minimax(board, 0, true);
    //         if (bestMove.move != null) {
    //             handleClick(bestMove.move);
    //         }
    //     }, 500);
    // };
    // const minimax = (board, depth, isMaximizing) => {
    //     const winner = checkForWinner(board);
    //     if (winner === 'X') return { score: -10 + depth };
    //     if (winner === 'O') return { score: 10 - depth };
    //     if (isBoardFull(board)) return { score: 0 };

    //     if (isMaximizing) {
    //         let bestScore = -Infinity;
    //         let move;
    //         // eslint-disable-next-line no-plusplus
    //         for (let i = 0; i < board.length; i++) {
    //             if (board[i] === null) {
    //                 board[i] = 'O';
    //                 const { score } = minimax(board, depth + 1, false);
    //                 board[i] = null;
    //                 if (score > bestScore) {
    //                     bestScore = score;
    //                     move = i;
    //                 }
    //             }
    //         }
    //         return { score: bestScore, move };
    //     }
    //     let bestScore = Infinity;
    //     let move;
    //     // eslint-disable-next-line no-plusplus
    //     for (let i = 0; i < board.length; i++) {
    //         if (board[i] === null) {
    //             board[i] = 'X';
    //             const { score } = minimax(board, depth + 1, true);
    //             board[i] = null;
    //             if (score < bestScore) {
    //                 bestScore = score;
    //                 move = i;
    //             }
    //         }
    //     }
    //     return { score: bestScore, move };
    // };

    const checkForWinner = (squares) => {
        const lines = [
            [0, 1, 2],
            [3, 4, 5],
            [6, 7, 8],
            [0, 3, 6],
            [1, 4, 7],
            [2, 5, 8],
            [0, 4, 8],
            [2, 4, 6],
        ];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < lines.length; i++) {
            const [a, b, c] = lines[i];
            if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
                return squares[a];
            }
        }
        return null;
    };

    const resetGame = () => {
        setBoard(Array(9).fill(null));
        setIsXNext(true);
        setWinner(null);
    };

    return (
        <div className="tie-game">
            <div className="game-board">
                {board.map((cell, index) => (
                    <button
                        type="button"
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className={`game-cell ${cell}`}
                        onClick={() => handleClick(index)}
                        disabled={!!winner}
                    >
                        {cell}
                    </button>
                ))}
            </div>
            {winner && (
                <div className={`winner ${winner ? 'show' : ''}`}>
                    {winner === 'Tie' ? "It's a Tie!" : `Winner: ${winner}`}
                </div>
            )}
            <div className="scoreboard">
                <div>X wins: {score.X}</div>
                <div>O wins: {score.O}</div>
                <div>Ties: {score.ties}</div>
            </div>
            <button type="button" onClick={resetGame} className="game-reset-button">
                Reset Game
            </button>
        </div>
    );
}

export default TicTacToeGame;
