import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateRange from '../../../components/DateRange';
import {
    setCallType,
    setDateRange,
    setTmsCode,
    setUddoktaCode,
} from '../../../redux/features/report/tms/tmsExecutionFilterSlice';
import { useSearchEmployeeMutation } from '../../../redux/features/teamManagement/teamManagementApi';
import getDataManagementFilterData from '../../../util/generateDataManagementFilterData';

function TmsExecutionFilter({ queryFunc, loading }) {
    const { uddoktaCode, tmsCode, callType } = useSelector((state) => state.tmsExecutionFilter);

    const dispatch = useDispatch();

    // date picker function
    const dataPickerFunc = (_, date) => {
        dispatch(setDateRange(date));
    };

    // filter data
    const { circle, region, area, territory, distributionHouse } = useSelector(
        (state) => state.dataManagement
    );

    // search employee api hook
    const [searchEmployee, { data: employeeData, isLoading }] = useSearchEmployeeMutation();

    const getFilterData = (lev) => {
        const bodyData = {};
        if (lev) {
            bodyData.type = lev;
        }
        return bodyData;
    };

    useEffect(() => {
        searchEmployee({
            ...getDataManagementFilterData({ circle, region, area, territory, distributionHouse }),
            type: 'tms',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchEmployee, circle, region, area, territory, distributionHouse]);

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };

    return (
        <>
            <DateRange dataPickerFunc={dataPickerFunc} />
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={uddoktaCode}
                    placeholder="Uddokta Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setUddoktaCode(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    showArrow
                    placeholder="Call Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setCallType(e))}
                    options={[
                        {
                            value: 'Joint Call',
                            label: 'Joint Call',
                        },
                        {
                            value: 'Control Call',
                            label: 'Control Call',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    loading={isLoading}
                    allowClear
                    showSearch
                    placeholder="TMS Name"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setTmsCode(e))}
                    options={employeeData?.data?.map((emp) => ({
                        label: emp.name,
                        // eslint-disable-next-line no-underscore-dangle
                        value: emp._id,
                    }))}
                    filterOption={(input, option) =>
                        option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    searchValue={search}
                    onSearch={onSearch}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    loading={loading}
                    disabled={loading}
                    size="large"
                    className="filter-btn"
                    icon={<SearchOutlined />}
                    type="primary"
                    style={{ background: '#faad14', color: '#fff' }}
                    onClick={() => queryFunc(1, 10, 'cleanShowResultOnPage')}
                >
                    Search
                </Button>
            </Col>
        </>
    );
}

export default TmsExecutionFilter;
