import { Table } from 'antd';
import React from 'react';

const { Column, ColumnGroup } = Table;

function TmrSummaryTable({ isLoading, data }) {
    return (
        <Table
            pagination={false}
            size="small"
            loading={isLoading}
            dataSource={data}
            scroll={{
                x: 400,
            }}
        >
            <Column title="Material Name" dataIndex="name" key="name" />
            <Column title="Received" dataIndex="confirmedQuantity" key="confirmedQuantity" />
            <Column title="In hand Qty" dataIndex="inhand" key="inhand" />
            <Column title="Used" dataIndex="used" key="used" />
        </Table>
    );
}

export default TmrSummaryTable;
