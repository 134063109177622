import React from 'react';
import { Helmet } from 'react-helmet-async';

function HelmetHeader({ title, description }) {
    return (
        <Helmet>
            <title>{title || ''} | Nagad Pulse</title>
            <meta name="description" content={description || ''} />
        </Helmet>
    );
}

export default HelmetHeader;
