/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../../components/Filter';
import HelmetHeader from '../../../components/HelmetHeader';
import { resetDataManagementFilter } from '../../../redux/features/filter/dataManagementFilterSlice';
import { setReFetchFilter } from '../../../redux/features/loaderSlice';
import { useGetTmrExecutionDataMutation } from '../../../redux/features/report/tmr/tmrExecutionApi';
import { resetTmrExecutionFilter } from '../../../redux/features/report/tmr/tmrExecutionFilterSlice';
import ExecutionSkeleton from '../../../ui/ExecutionSkeleton';
import NoResult from '../../../ui/NoResult';
import firebaseLog from '../../../util/firebaseLog';
import getInventoryQueryData from '../../../util/generateRequestBodyData';
import ExecutionItem from './ExecutionItem';

function TmrExecution() {
    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    // filter data
    const { circle, region, area, territory, distributionHouse } = useSelector(
        (state) => state.dataManagement
    );
    const {
        dateRange,
        uddoktaCode,
        tmsCode,
        tmrId,
        errorReason,
        callType,
        isQrCodeScanned,
        isAiReady,
        tmrCode,
    } = useSelector((state) => state.tmrExecutionFilter);

    const [getTmrExecutionData, { data, isLoading, isError, isSuccess, error }] =
        useGetTmrExecutionDataMutation();

    const fetchExecutionData = (page, totalShow, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(10);
        }
        getTmrExecutionData({
            page,
            limit: totalShow,
            ...getInventoryQueryData({
                dateRange,
                uddoktaCode,
                tmsCode,
                tmrId,
                errorReason,
                callType,
                circle,
                region,
                area,
                territory,
                distributionHouse,
                isQrCodeScanned,
                isAiReady,
                tmrCode,
            }),
        });
    };

    useEffect(() => {
        getTmrExecutionData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetchExecutionData(pageNumber, totalPageChange);
    };

    const dispatch = useDispatch();
    const { reFetchFilter } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetTmrExecutionFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'TMR Execution', user: user.name });
    }, [user.name]);

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="TMR Execution" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    loading={isLoading}
                    queryFunc={fetchExecutionData}
                    pathname="/tmr-execution"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">Uddokta TMR Execution</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    {!isLoading ? (
                        <>
                            {data?.data.length <= 0 || !data ? (
                                <NoResult />
                            ) : (
                                <>
                                    {data?.data.map((item, i) => (
                                        <ExecutionItem loading={isLoading} data={item} key={i} />
                                    ))}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {new Array(5).fill('').map((_, i) => (
                                <ExecutionSkeleton key={i} />
                            ))}
                        </>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Pagination
                            size="large"
                            pageSize={totalShowPage}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultCurrent={1}
                            total={data?.meta.count}
                            onChange={onChange}
                            showTotal={(total, range) =>
                                `${range[0]}-${range[1]} of ${total} items`
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default TmrExecution;
