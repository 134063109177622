import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Select } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchEmployeeMutation } from '../../redux/features/teamManagement/teamManagementApi';
import {
    setEmpCode,
    setEmpId,
    setEmpLevel,
    setEmpName,
    setEmpUsername,
    setIdLock,
    setSupervisor,
} from '../../redux/features/teamManagement/teamManagementFilterSlice';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';

function TeamManagementFilter({ queryFunc, loading }) {
    const { empId, empName, empUsername, empCode } = useSelector(
        (state) => state.teamManagementFilter
    );

    // filter data
    const { circle, region, area, territory, distributionHouse } = useSelector(
        (state) => state.dataManagement
    );

    const [searchEmployee, { data, isLoading }] = useSearchEmployeeMutation();

    useEffect(() => {
        searchEmployee({
            ...getDataManagementFilterData({ circle, region, area, territory, distributionHouse }),
            type: 'tms',
        });
    }, [circle, region, area, territory, distributionHouse, searchEmployee]);

    const dispatch = useDispatch();

    return (
        <>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={empId}
                    placeholder="Emp ID"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setEmpId(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={empName}
                    placeholder="Emp Name"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setEmpName(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={empUsername}
                    placeholder="Emp Username"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setEmpUsername(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    showArrow
                    placeholder="EMP Level"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setEmpLevel(e))}
                    options={[
                        {
                            value: 'tmr',
                            label: 'TMR',
                        },
                        {
                            value: 'tms',
                            label: 'TMS',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    disabled={isLoading}
                    loading={isLoading}
                    allowClear
                    showArrow
                    placeholder="Supervisor"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setSupervisor(e))}
                    options={data?.data?.map(({ name, _id }) => ({ label: name, value: _id }))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={empCode}
                    placeholder="Emp Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setEmpCode(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    showArrow
                    placeholder="ID Lock"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setIdLock(e))}
                    options={[
                        {
                            value: 'yes',
                            label: 'Yes',
                        },
                        {
                            value: 'no',
                            label: 'No',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    loading={loading}
                    disabled={loading}
                    size="large"
                    className="filter-btn"
                    icon={<SearchOutlined />}
                    type="primary"
                    style={{ background: '#faad14', color: '#fff' }}
                    onClick={() => queryFunc(1, 10, 'cleanShowResultOnPage')}
                >
                    Search
                </Button>
            </Col>
        </>
    );
}

export default TeamManagementFilter;
