import { apiSlice } from '../api/apiSlice';

export const trainingModuleApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        addTrainingModule: builder.mutation({
            query: (data) => ({
                url: '/v1/training-material',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['training-materials'],
        }),
        getTrainingModuleData: builder.query({
            query: () => ({
                url: '/v1/training-material',
            }),
            providesTags: ['training-materials'],
        }),
        deleteTrainingModule: builder.mutation({
            query: (id) => ({
                url: `/v1/training-material/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['training-materials'],
        }),
    }),
});

export const {
    useGetTrainingModuleDataQuery,
    useDeleteTrainingModuleMutation,
    useAddTrainingModuleMutation,
} = trainingModuleApi;
