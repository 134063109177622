/* eslint-disable react/jsx-no-useless-fragment */
import { Select, Space, Table, message } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import { useGetTicketsQuery, useUpdateTicketMutation } from '../../redux/features/ticket/ticketApi';

function TicketTable() {
    // get all tickets
    const { data, isLoading } = useGetTicketsQuery();

    const { Option } = Select;

    // update tickets
    const [updateTicket, { isLoading: updateLoading }] = useUpdateTicketMutation();

    const onChange = async (v, e) => {
        try {
            const result = await updateTicket({
                // eslint-disable-next-line no-underscore-dangle
                id: v._id,
                status: {
                    status: e,
                },
            }).unwrap();
            message.success('Tickets updated');
        } catch (error) {
            message.error('Error updating tickets');
        }
    };

    // user information log
    const { user: userInfo } = useSelector((state) => state.auth);
    const isEditableUser = !!userInfo?.permission?.find((x) => x.label === 'User Edit')?.label;

    const columns = [
        {
            title: 'Create Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, record) => dayjs(record.createdAt).format('DD/MM/YYYY hh:mm:ss a'),
        },
        {
            title: 'Raise By',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => record?.createdBy?.name,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Last Updated',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (_, record) => dayjs(record.updatedAt).format('DD/MM/YYYY hh:mm:ss a'),
        },
        {
            title: 'Attachment',
            dataIndex: 'fileUrl',
            key: 'fileUrl',
            render: (_, record) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {record.fileUrl && (
                        <a href={record.fileUrl} target="_blank" rel="noreferrer">
                            View
                        </a>
                    )}
                </>
            ),
        },
    ];

    if (isEditableUser) {
        columns.push({
            title: 'Actions',
            key: 'actions',
            render: (_, ticket) => (
                <Space>
                    {ticket.status === 'resolved' || ticket.status === 'rejected' ? null : (
                        <Select
                            style={{ minWidth: '120px' }}
                            placeholder="Action"
                            onChange={(e) => onChange(ticket, e)}
                        >
                            <Option value="progress">Progress</Option>
                            <Option value="resolved">Resolved</Option>
                            <Option value="rejected">Rejected</Option>
                        </Select>
                    )}
                </Space>
            ),
        });
    }

    return (
        <Table
            rowKey="_id"
            scroll={{ x: 800 }}
            pagination={false}
            loading={isLoading || updateLoading}
            dataSource={data?.data}
            columns={columns}
        />
    );
}

export default TicketTable;
