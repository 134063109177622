/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dateRange: [],
    tmrId: [],
    dhCode: '',
};

const tmrSummaryFilterSlice = createSlice({
    name: 'tmrSummaryFilterSlice',
    initialState,
    reducers: {
        setDateRange: (state, action) => {
            state.dateRange = action.payload;
        },
        setTmrId: (state, action) => {
            state.tmrId = action.payload;
        },
        setDhCodeTmrSummary: (state, action) => {
            state.dhCode = action.payload;
        },
        resetTmrSummaryFilter: (state, action) => {
            state.dateRange = [];
            state.tmrId = [];
            state.dhCode = '';
        },
    },
});

export default tmrSummaryFilterSlice.reducer;
export const { setDateRange, setTmrId, setDhCodeTmrSummary, resetTmrSummaryFilter } =
    tmrSummaryFilterSlice.actions;
