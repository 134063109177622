/* eslint-disable import/prefer-default-export */
/* eslint-disable prettier/prettier */
import {
    AimOutlined,
    AuditOutlined,
    BarChartOutlined,
    BellOutlined,
    BranchesOutlined,
    CloudDownloadOutlined,
    ClusterOutlined,
    CodeOutlined,
    CrownOutlined,
    FolderOpenOutlined,
    FundViewOutlined,
    NotificationOutlined,
    OneToOneOutlined,
    PieChartOutlined,
    ReadOutlined,
    SplitCellsOutlined,
    TagsOutlined,
    TeamOutlined,
    ThunderboltOutlined,
    UserOutlined
} from '@ant-design/icons';

export const items = [
    {
        label: 'Profile',
        key: '/profile',
        icon: <UserOutlined />,
        serial: 1,
    },
    {
        label: 'Dashboard',
        key: '/',
        icon: <PieChartOutlined />,
        serial: 2,
    },
    {
        label: 'Attendance',
        key: '/attendance',
        icon: <AimOutlined />,
        serial: 4,
    },
    {
        label: 'Tickets',
        key: '/tickets',
        icon: <TagsOutlined />,
        serial: 3,
    },
    {
        label: 'Notice',
        key: '/notice',
        icon: <BellOutlined />,
        serial: 13,
    },
    {
        label: 'Training Module',
        key: '/training-module',
        icon: <AuditOutlined />,
        serial: 15,
    },
    {
        label: 'Leave Approval',
        key: '/leave-management',
        icon: <SplitCellsOutlined />,
        serial: 14,
    },
    {
        label: 'Login Details',
        key: '/login-details',
        icon: <CodeOutlined />,
        serial: 8,
    },
    {
        label: 'Route Plan Tracker',
        key: '/route-plan-tracker',
        icon: <BranchesOutlined />,
        serial: 7,
    },
    {
        label: 'PJP Allocation',
        key: '/pjp-allocation',
        icon: <ClusterOutlined />,
        serial: 5,
    },
    {
        label: 'Target',
        key: '/target',
        icon: <ThunderboltOutlined />,
        serial: 6,
        children: [
            {
                label: 'TMR Target',
                key: '/tmr-target',
            },
            {
                label: 'TMS Target',
                key: '/tms-target',
            },
        ],
    },
    {
        label: 'Team Management',
        key: '/team-management',
        icon: <TeamOutlined />,
        children: [
            {
                label: 'Add Employee',
                key: '/add-employee',
            },
            {
                label: 'View Employee',
                key: '/view-employee',
            },
        ],
        serial: 8,
    },
    {
        label: 'Data Management',
        key: '/data-management',
        icon: <OneToOneOutlined />,
        serial: 9,
    },
    {
        label: 'Super User',
        key: '/superuser',
        icon: <CrownOutlined />,
        children: [
            {
                label: 'Add Group',
                key: '/add-group',
            },
            {
                label: 'View Group',
                key: '/view-group',
            },
            {
                label: 'Add User',
                key: '/add-user',
            },
            {
                label: 'View User',
                key: '/view-user',
            },
        ],
        serial: 13,
    },
    
    {
        label: 'Material Management',
        key: '/material-management',
        icon: <BarChartOutlined />,
        children: [
            // {
            //     label: 'Add Material',
            //     key: '/add-material',
            // },
            {
                label: 'View Material',
                key: '/material/view',
            },
            {
                label: 'Allocate Material',
                key: '/material/allocate-material',
            },
            {
                label: 'Receive Material in DH',
                key: '/material/receive-material-in-dh',
            },
            {
                label: 'Material Movement',
                key: '/material/material-movement',
            },
            {
                label: 'Approve/Reject Transfer',
                key: '/material/approve-reject-transfer',
            },
        ],
        serial: 10,
    },
    {
        label: 'Inventory',
        key: '/inventory',
        icon: <FolderOpenOutlined />,
        children: [
            {
                label: 'DH History',
                key: '/inventory/dh-history',
            },
            {
                label: 'DH Summary',
                key: '/inventory/dh-summary',
            },
            {
                label: 'Damage & Lost',
                key: '/inventory/damage-lost',
            },
            {
                label: 'TMR History',
                key: '/inventory/tmr-history',
            },
            {
                label: 'TMR Summary',
                key: '/inventory/tmr-summary',
            },
        ],
        serial: 11,
    },
    {
        label: 'AI Based Report',
        key: '/report',
        icon: <NotificationOutlined />,
        children: [
            {
                label: 'TMR Execution',
                key: '/tmr-execution',
            },
            {
                label: 'TMS Call',
                key: '/tms-call',
            },
        ],
        serial: 12,
    },
    {
        label: 'Daily Activity Report',
        key: '/daily-activity-report',
        icon: <FundViewOutlined />,
        serial: 6,
    },
    
    {
        label: 'AI POSM List',
        key: '/ai-posm-list',
        icon: <ReadOutlined />,
        serial: 15,
    },
    {
        label: 'Download Report',
        key: '/download-report',
        icon: <CloudDownloadOutlined />,
        serial: 16,
    },
];