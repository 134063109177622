/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    date: '',
    employeeCode: '',
    level: '',
    dhCode: '',
    locationMatch: '',
};

const attendanceFilterSlice = createSlice({
    name: 'attendanceFilterSlice',
    initialState,
    reducers: {
        setDate: (state, action) => {
            state.date = action.payload;
        },
        setEmployeeCode: (state, action) => {
            state.employeeCode = action.payload;
        },
        setLeveL: (state, action) => {
            state.level = action.payload;
        },
        setDhCodeAttendance: (state, action) => {
            state.dhCode = action.payload;
        },
        setLocationMatch: (state, action) => {
            state.locationMatch = action.payload;
        },
        resetAttendanceFilter: (state, action) => {
            state.date = '';
            state.employeeCode = '';
            state.level = '';
            state.dhCode = '';
            state.locationMatch = '';
        },
    },
});

export default attendanceFilterSlice.reducer;
export const {
    setDate,
    setEmployeeCode,
    setLeveL,
    setDhCodeAttendance,
    setLocationMatch,
    resetAttendanceFilter,
} = attendanceFilterSlice.actions;
