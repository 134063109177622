import { apiSlice } from '../api/apiSlice';
import { userLoggedIn } from './authSlice';

export const authApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (data) => ({
                url: '/v1/auth/admin-login',
                method: 'POST',
                body: data,
            }),

            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    const result = await queryFulfilled;

                    sessionStorage.setItem(
                        'auth',
                        JSON.stringify({
                            accessToken: result?.data?.data.access_token,
                            user: result?.data?.data.payload,
                        })
                    );

                    dispatch(
                        userLoggedIn({
                            accessToken: result?.data?.data.access_token,
                            user: result?.data?.data.payload,
                        })
                    );
                } catch (err) {
                    // do nothing
                }
            },
            invalidatesTags: ['whoAmI'],
        }),
        logout: builder.mutation({
            query: (data) => ({
                url: '/v1/auth/logout',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['whoAmI'],
        }),
        whoAmI: builder.query({
            query: () => ({
                url: '/v1/user/whoami-admin',
            }),
            providesTags: ['whoAmI'],
        }),
        changePassword: builder.mutation({
            query: (data) => ({
                url: '/v1/user/update-password',
                method: 'POST',
                body: data,
            }),
        }),
        changeProfile: builder.mutation({
            query: (data) => ({
                url: '/v1/user/update-profile',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['whoAmI'],
        }),
    }),
});

export const {
    useLoginMutation,
    useLogoutMutation,
    useWhoAmIQuery,
    useChangePasswordMutation,
    useChangeProfileMutation,
} = authApi;
