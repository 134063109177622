import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { useGetAttendanceDataMutation } from '../../redux/features/attendance/attendanceApi';
import { resetAttendanceFilter } from '../../redux/features/attendance/attendanceFilterSlice';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { setGlobalLoading, setReFetchFilter } from '../../redux/features/loaderSlice';
import firebaseLog from '../../util/firebaseLog';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import AttendanceLocator from './AttendanceLocator';
import AttendanceTracker from './AttendanceTracker';

function getBodyData(dateStr, empCode, lev, dhcode, locationmatch) {
    const bodyData = {};
    bodyData.date = dayjs(dateStr || new Date()).format('DD-MM-YYYY');
    if (empCode) {
        bodyData.employeeCode = empCode;
    }
    if (lev) {
        bodyData.employeeLevel = lev;
    }
    if (dhcode) {
        bodyData.dhCode = dhcode;
    }
    if (locationmatch) {
        bodyData.isLocationMatched = locationmatch;
    }
    return bodyData;
}

function Attendance() {
    const dispatch = useDispatch();
    const { circle, region, area, territory, distributionHouse } = useSelector(
        (state) => state.dataManagement
    );
    const { date, employeeCode, level, dhCode, locationMatch } = useSelector(
        (state) => state.attendanceFilter
    );
    const { user } = useSelector((state) => state.auth);
    const [getAttendanceData, { data, isLoading }] = useGetAttendanceDataMutation();
    const { reFetchFilter } = useSelector((state) => state.globalLoading);

    const filterData = () => {
        getAttendanceData({
            ...getDataManagementFilterData({ circle, region, area, territory, distributionHouse }),
            ...getBodyData(date, employeeCode, level, dhCode, locationMatch),
        });
    };

    useEffect(() => {
        getAttendanceData();
    }, [getAttendanceData]);

    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetAttendanceFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        dispatch(setGlobalLoading(isLoading));
    }, [dispatch, isLoading]);

    // user information log
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Attendance', user: user.name });
    }, [user.name]);

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Attendance" />

            <div style={{ margin: '16px 0' }}>
                <Filter loading={isLoading} queryFunc={filterData} pathname="/attendance" />
            </div>
            <Row gutter={[5, 10]} style={{ paddingBottom: '10px' }}>
                <Col lg={12}>
                    <AttendanceTracker loading={isLoading} data={data?.data} />
                </Col>
                <Col lg={12}>
                    <AttendanceLocator data={data?.data?.attendances?.data} />
                </Col>
            </Row>
        </>
    );
}

export default Attendance;
