/* eslint-disable prettier/prettier */
import { FilterOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import DateRange from '../../components/DateRange';
import FilterButton from '../../components/FilterButton';
import {
    setDateRange
} from '../../redux/features/dashboard/dashboardFilterSlice';

function DashboardFilter({queryFunc}) {
    const dispatch = useDispatch();

    // date picker function
    const dataPickerFunc = (_, date) => {
        dispatch(setDateRange(date));
    };
    
    return (
        <>
            <DateRange dataPickerFunc={dataPickerFunc} />
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <FilterButton fn={queryFunc} icon={<FilterOutlined />} title="Filter" />
            </Col>
        </>
    );
}

export default DashboardFilter;
