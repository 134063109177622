import { Table } from 'antd';
import React from 'react';

const { Column, ColumnGroup } = Table;

function DhSummaryTable({ isLoading, data }) {
    return (
        <Table
            pagination={false}
            size="small"
            loading={isLoading}
            dataSource={data}
            scroll={{
                x: 400,
            }}
        >
            <Column title="Material Name" dataIndex="materialName" key="materialName" />
            <Column title="Pending Qty" dataIndex="pendingQuantity" key="pendingQuantity" />
            <Column title="Received Qty" dataIndex="received" key="received" />
            <Column
                title="Transfer Received Qty"
                dataIndex="transferReceived"
                key="transferReceived"
            />
            <Column title="Transfer" dataIndex="transfer" key="transfer" />
            <Column title="In hand Qty" dataIndex="inhand" key="inhand" />
            <Column title="Used" dataIndex="totalUsed" key="totalUsed" />
        </Table>
    );
}

export default DhSummaryTable;
