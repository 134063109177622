import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Space, Table, message } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import {
    useGetPendingLeaveMutation,
    useUpdateLeaveRequestMutation,
} from '../../redux/features/leaveManagement/leaveManagementApi';
import { resetLeaveManagementFilter } from '../../redux/features/leaveManagement/leaveManagementFilterSlice';
import { setReFetchFilter } from '../../redux/features/loaderSlice';
import firebaseLog from '../../util/firebaseLog';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import { capitalizeFirstLetter } from '../../util/miniFunction';

const { Column, ColumnGroup } = Table;

function LeaveManagement() {
    const dispatch = useDispatch();

    // filter data
    const { circle, region, area, territory, distributionHouse } = useSelector(
        (state) => state.dataManagement
    );
    const { dateRange, empLevel } = useSelector((state) => state.leaveManagementFilter);

    const [getPendingLeave, { data: leaveData, isLoading }] = useGetPendingLeaveMutation();

    const [updateLeaveRequest, { data: updateData, isLoading: updateLoading }] =
        useUpdateLeaveRequestMutation();

    function generateBody(d, lev) {
        const bodyData = {};
        if (d.length) {
            const [s, e] = d;
            bodyData.startAt = dayjs(s).format('DD-MM-YYYY');
            bodyData.endAt = dayjs(e).format('DD-MM-YYYY');
        }
        if (lev) {
            bodyData.employeeLevel = empLevel;
        }
        return bodyData;
    }

    const fetchLeaveData = () => {
        getPendingLeave({
            page: 1,
            limit: 1000,
            ...generateBody(dateRange, empLevel),
            ...getDataManagementFilterData({ circle, region, area, territory, distributionHouse }),
        });
    };

    const updateLeaveRequestFunc = (record, status) => {
        confirmAlert({
            title: `Confirm to ${status}`,
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            const result = await updateLeaveRequest({
                                leaveId: record.leaveId,
                                userId: record.userId,
                                status,
                            }).unwrap();
                        } catch (error) {
                            message.error('Something went wrong');
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => message.warning('Cancelled!'),
                },
            ],
        });
    };

    useEffect(() => {
        fetchLeaveData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData]);

    const { reFetchFilter } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetLeaveManagementFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Leave Management', user: user.name });
    }, [user.name]);

    function reverseDate(d) {
        return d.split('-').reverse().join('/');
    }

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Leave Management" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    queryFunc={fetchLeaveData}
                    loading={isLoading}
                    pathname="/leaveManagement"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">Leave Management</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    <Table
                        rowKey="leaveId"
                        loading={isLoading}
                        dataSource={leaveData?.data}
                        pagination={false}
                    >
                        <Column
                            title="Details"
                            key="name"
                            render={(_, record) => (
                                <>
                                    <p style={{ margin: 0 }}>
                                        <span style={{ fontWeight: 600 }}>Name: </span>
                                        {record.userFullName}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <span style={{ fontWeight: 600 }}>Start At: </span>
                                        {record.startAt}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <span style={{ fontWeight: 600 }}>End At: </span>
                                        {record.endAt}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <span style={{ fontWeight: 600 }}>Total Days: </span>
                                        {dayjs(reverseDate(record.endAt)).diff(
                                            reverseDate(record.startAt),
                                            'day'
                                        ) + 1}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <span style={{ fontWeight: 600 }}>Leave type: </span>
                                        {capitalizeFirstLetter(record.reason)}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <span style={{ fontWeight: 600 }}>Comments: </span>
                                        {record.comment}
                                    </p>
                                </>
                            )}
                        />
                        <Column
                            title="Total Entitled"
                            dataIndex="totalEntitled"
                            key="totalEntitled"
                        />
                        <Column
                            title="Total Consume"
                            dataIndex="totalConsumed"
                            key="totalConsumed"
                        />
                        <ColumnGroup align="center" title="Remaining Leave">
                            <Column
                                title="Sick"
                                dataIndex="remainingSickLeave"
                                key="remainingSickLeave"
                            />
                            <Column
                                title="Annual"
                                dataIndex="remainingAnnualLeave"
                                key="remainingAnnualLeave"
                            />
                        </ColumnGroup>
                        <Column
                            title="Action"
                            key="action"
                            render={(_, record) => (
                                <Space>
                                    <Button
                                        onClick={() => updateLeaveRequestFunc(record, 'approved')}
                                        type="primary"
                                        shape="circle"
                                        icon={<CheckOutlined />}
                                    />
                                    <Button
                                        onClick={() => updateLeaveRequestFunc(record, 'rejected')}
                                        type="primary"
                                        shape="circle"
                                        danger
                                        icon={<CloseOutlined />}
                                    />
                                </Space>
                            )}
                        />
                    </Table>
                </div>
            </div>
        </>
    );
}

export default LeaveManagement;
