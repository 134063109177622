import { Button, Form, Input, Select, message } from 'antd';
import React from 'react';
import {
    useAddTerritoryMutation,
    useGetAreaListQuery,
    useGetCircleListQuery,
    useGetRegionListQuery,
} from '../../redux/features/dataManagement/dataManagementApi';

const { Option } = Select;

function AddTerritory() {
    // get dropdown data
    const { data: circleList, isLoading } = useGetCircleListQuery();
    const { data: regionList } = useGetRegionListQuery();
    const { data: areaList } = useGetAreaListQuery();

    // territory add api hook
    const [addTerritory, { isLoading: addLoading }] = useAddTerritoryMutation();

    const [form] = Form.useForm();
    const onFinish = async (values) => {
        try {
            const result = await addTerritory({
                name: values.territory,
                circleId: values.territoryCircle,
                regionId: values.territoryRegion,
                areaId: values.territoryArea,
            }).unwrap();
            message.success('Territory added successfully');
            form.resetFields();
        } catch (error) {
            message.error(error.data.message);
        }
    };
    return (
        <div className="data-box">
            <h3>Add Territory</h3>
            <div>
                <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
                    <Form.Item
                        name="territoryCircle"
                        label="Circle"
                        rules={[
                            {
                                required: true,
                                message: 'Missing circle',
                            },
                        ]}
                    >
                        <Select placeholder="Circle" size="large" options={circleList?.data} />
                    </Form.Item>
                    <Form.Item
                        name="territoryRegion"
                        label="Region"
                        rules={[
                            {
                                required: true,
                                message: 'Missing region',
                            },
                        ]}
                    >
                        <Select placeholder="Region" size="large" options={regionList?.data} />
                    </Form.Item>
                    <Form.Item
                        name="territoryArea"
                        label="Area"
                        rules={[
                            {
                                required: true,
                                message: 'Missing area',
                            },
                        ]}
                    >
                        <Select placeholder="Area" size="large" options={areaList?.data} />
                    </Form.Item>
                    <Form.Item
                        name="territory"
                        label="Territory"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input size="large" placeholder="Territory Name" />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            disabled={addLoading}
                            loading={addLoading}
                            type="primary"
                            htmlType="submit"
                        >
                            Add Territory
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default AddTerritory;
