/* eslint-disable react/no-array-index-key */
import { CaretRightOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Divider, Image, Modal, Row, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import MapModal from '../../../components/MapModal';
import { capitalizeFirstLetter } from '../../../util/miniFunction';

const { Column, ColumnGroup } = Table;

function ExecutionItem({ data }) {
    const {
        tmrName,
        tmrCode,
        orgName,
        dh,
        route,
        uddoktaWalletNo,
        uddoktaName,
        retailerCode,
        executionType,
        formattedExecutionDuration,
        remarks,
        createdAt,
        isQrCodeMatched,
        isLocationMatched,
        isQrCodeScanned,
        merchandisingImages,
        outletImages,
        latitude,
        longitude,
        materialsUsed,
        competitions,
        sovDetails,
        isAiReady,
        tmrPhone,
        tmsName,
        tmsCode,
        tmsPhone,
    } = data;
    // modal state and function
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // error reason generation function
    function getErrorReason(qrMach, isLocation, qrScan) {
        if ((!qrScan || !qrMach) && !isLocation) {
            return 'QR and Location Error';
        }
        if ((!qrScan || !qrMach) && isLocation) {
            return 'QR Code';
        }
        if (!isLocation && (qrScan || qrMach)) {
            return 'Location Error';
        }
        return '';
    }

    const imageData = [
        outletImages?.insideImgUrl,
        outletImages?.outsideImgUrl,
        ...merchandisingImages,
    ].filter((item) => item);

    const isErr = !isLocationMatched || !isQrCodeScanned || !isQrCodeMatched;

    const competitionsData = [
        {
            title: 'Bkash',
            data: competitions?.bkash,
        },
        {
            title: 'Rocket',
            data: competitions?.rocket,
        },
        {
            title: 'Upay',
            data: competitions?.upay,
        },
    ].filter((items) => items.data?.length);
    const sov = [
        {
            title: 'Nagad',
            quantity: sovDetails?.nagad?.quantity || 0,
            percent: sovDetails?.nagad?.percentage || 0,
        },
        {
            title: 'Bkash',
            quantity: sovDetails?.bkash?.quantity || 0,
            percent: sovDetails?.bkash?.percentage || 0,
        },
        {
            title: 'Rocket',
            quantity: sovDetails?.rocket?.quantity || 0,
            percent: sovDetails?.rocket?.percentage || 0,
        },
        {
            title: 'Upay',
            quantity: sovDetails?.upay?.quantity || 0,
            percent: sovDetails?.upay?.percentage || 0,
        },
    ];

    return (
        <>
            <Modal
                footer={false}
                title="Location in google Map"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width="850px"
                bodyStyle={{ padding: 0 }}
            >
                <MapModal lat={latitude || 0} lon={longitude || 0} />
            </Modal>
            <div className="execution-item" style={isErr ? { background: '#C50B00' } : {}}>
                <Row
                    gap={[5, 5]}
                    justify="space-between"
                    className={isErr ? 'execution-item-error' : ''}
                >
                    <Col lg={6} md={12} sm={24}>
                        <p className="ex-item-point">
                            <span style={{ fontWeight: '500' }}>TMR Name: </span>
                            {tmrName}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">TMR Code: </span>
                            {tmrCode}
                        </p>
                        <p className="ex-item-point">
                            <span style={{ fontWeight: '500' }}>TMR Phone: </span>
                            {tmrPhone}
                        </p>
                        <p className="ex-item-point">
                            <span style={{ fontWeight: '500' }}>TMS Name: </span>
                            {tmsName}
                        </p>
                        <p className="ex-item-point">
                            <span style={{ fontWeight: '500' }}>TMS Code: </span>
                            {tmsCode}
                        </p>
                        <p className="ex-item-point">
                            <span style={{ fontWeight: '500' }}>TMS Phone: </span>
                            {tmsPhone}
                        </p>
                    </Col>

                    <Col lg={6} md={12} sm={24}>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Uddokta Name: </span>
                            {uddoktaName}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Uddokta Code: </span>
                            {retailerCode}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Org Name: </span>
                            {orgName}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">DH: </span>
                            {dh}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Uddokta Wallet: </span>
                            {uddoktaWalletNo}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Route: </span>
                            {route}
                        </p>
                    </Col>

                    <Col lg={6} md={12} sm={24}>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Execution Type: </span>
                            {capitalizeFirstLetter(executionType)}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Execution Duration: </span>
                            {formattedExecutionDuration}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Remarks: </span>
                            {remarks}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Timestamp: </span>
                            {dayjs(createdAt).format('DD/MM/YYYY hh:mm:ss a')}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Reason for error: </span>
                            {getErrorReason(isQrCodeMatched, isLocationMatched, isQrCodeScanned)}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">AI Run: </span>
                            {isAiReady ? 'Yes' : 'No'}
                        </p>
                    </Col>

                    <Col
                        lg={6}
                        md={12}
                        sm={24}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <div className="survey-last-section">
                            <Image.PreviewGroup
                                preview={{
                                    countRender: (current) =>
                                        imageData[current - 1]?.imageName || 'null',
                                }}
                            >
                                <Row gutter={[2, 2]}>
                                    {imageData.map(({ original }, i) => (
                                        <Col key={original + i}>
                                            <Image width={45} src={original || ''} />
                                        </Col>
                                    ))}
                                </Row>
                            </Image.PreviewGroup>
                        </div>
                        <Button
                            style={{ marginLeft: '5px' }}
                            onClick={() => setIsModalOpen((prev) => !prev)}
                        >
                            <EnvironmentOutlined />
                        </Button>
                    </Col>
                </Row>

                <Divider style={{ margin: '5px 0' }} />
                {executionType === 'no' ? null : (
                    <Collapse
                        bordered={false}
                        ghost
                        // eslint-disable-next-line react/no-unstable-nested-components
                        expandIcon={({ isActive }) => (
                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                        items={[
                            {
                                key: '1',
                                label: 'Expand Report',
                                children: (
                                    <Row gutter={[10, 10]}>
                                        <Col>
                                            <div>
                                                <h3 style={isErr ? { color: 'white' } : {}}>
                                                    Nagad
                                                </h3>
                                                <Table
                                                    pagination={false}
                                                    rowKey="_id"
                                                    size="small"
                                                    scroll={{
                                                        x: 300,
                                                    }}
                                                    style={{
                                                        maxWidth: '325px',
                                                        border: '1px solid #FAFAFA',
                                                        borderRadius: '10px 8px 0 0',
                                                    }}
                                                    dataSource={
                                                        materialsUsed.filter(
                                                            (x) => x.category !== 'toolkit'
                                                        ) || []
                                                    }
                                                >
                                                    <Column
                                                        title="Material"
                                                        dataIndex="name"
                                                        key="name"
                                                    />
                                                    <Column
                                                        title="Used Qty"
                                                        dataIndex="usedQty"
                                                        key="usedQty"
                                                        render={(_, record) =>
                                                            record.category === 'toolkit'
                                                                ? 'Yes'
                                                                : record.usedQty
                                                        }
                                                    />
                                                    <Column
                                                        title="AI Detect"
                                                        dataIndex="aiDetectedQty"
                                                        key="aiDetectedQty"
                                                        render={(_, record) =>
                                                            record.category === 'toolkit'
                                                                ? ''
                                                                : record.aiDetectedQty
                                                        }
                                                    />
                                                </Table>

                                                <Table
                                                    pagination={false}
                                                    rowKey="_id"
                                                    size="small"
                                                    scroll={{
                                                        x: 300,
                                                    }}
                                                    title={() => 'Toolkit'}
                                                    style={{
                                                        maxWidth: '325px',
                                                        border: '1px solid #FAFAFA',
                                                        borderRadius: '10px 8px 0 0',
                                                        marginTop: '10px',
                                                    }}
                                                    dataSource={
                                                        materialsUsed.filter(
                                                            (x) => x.category !== 'countable'
                                                        ) || []
                                                    }
                                                >
                                                    <Column
                                                        title="Material"
                                                        dataIndex="name"
                                                        key="name"
                                                    />
                                                    <Column
                                                        title="Used"
                                                        dataIndex="usedQty"
                                                        key="usedQty"
                                                        render={(_, record) =>
                                                            record.category === 'toolkit'
                                                                ? 'Yes'
                                                                : record.usedQty
                                                        }
                                                    />
                                                </Table>
                                            </div>
                                        </Col>
                                        {competitionsData?.length > 0 ? (
                                            <Col>
                                                <div>
                                                    <h3 style={isErr ? { color: 'white' } : {}}>
                                                        Competition
                                                    </h3>
                                                    {competitionsData?.map((item) => (
                                                        <Table
                                                            key={item.title}
                                                            pagination={false}
                                                            rowKey="_id"
                                                            size="small"
                                                            scroll={{
                                                                x: 300,
                                                            }}
                                                            style={{
                                                                maxWidth: '325px',
                                                                marginBottom: '5px',
                                                                border: '1px solid #FAFAFA',
                                                                borderRadius: '10px 10px 0 0',
                                                            }}
                                                            dataSource={item.data || []}
                                                            title={() => item.title}
                                                        >
                                                            <Column
                                                                title="Material"
                                                                dataIndex="name"
                                                                key="name"
                                                            />
                                                            <Column
                                                                title="AI Detect"
                                                                dataIndex="quantity"
                                                                key="quantity"
                                                                render={(_, record) =>
                                                                    record.isCount
                                                                        ? record.quantity
                                                                        : record.value
                                                                }
                                                            />
                                                        </Table>
                                                    ))}
                                                </div>
                                            </Col>
                                        ) : null}
                                        <Col>
                                            <div>
                                                <h3 style={isErr ? { color: 'white' } : {}}>
                                                    SOV By Count
                                                </h3>
                                                <Table
                                                    pagination={false}
                                                    rowKey="_id"
                                                    size="small"
                                                    scroll={{
                                                        x: 300,
                                                    }}
                                                    style={{
                                                        maxWidth: '325px',
                                                        marginBottom: '5px',
                                                        border: '1px solid #FAFAFA',
                                                        borderRadius: '10px 8px 0 0',
                                                    }}
                                                    dataSource={sov || []}
                                                >
                                                    <Column
                                                        title="Name"
                                                        dataIndex="title"
                                                        key="title"
                                                    />
                                                    <Column
                                                        title="Quantity"
                                                        dataIndex="quantity"
                                                        key="quantity"
                                                        align="center"
                                                    />
                                                    <Column
                                                        title="Percentage"
                                                        dataIndex="percent"
                                                        key="percent"
                                                        align="center"
                                                        render={(v) => `${Math.round(v)}%`}
                                                    />
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                ),
                                style: {
                                    borderRadius: '5px',
                                    border: 'none',
                                },
                            },
                        ]}
                    />
                )}
            </div>
        </>
    );
}

export default ExecutionItem;
