/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Select, Space, Table, message } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import HelmetHeader from '../../components/HelmetHeader';
import { useGetSingleDhQuery } from '../../redux/features/dataManagement/dataManagementApi';
import { setGlobalLoading } from '../../redux/features/loaderSlice';
import {
    useApproveOrRejectMutation,
    useGetDhListQuery,
    useGetPendingMaterialListQuery,
} from '../../redux/features/materialManagement/materialManagementApi';
import debounce from '../../util/debounce';
import firebaseLog from '../../util/firebaseLog';

const { Column, ColumnGroup } = Table;
const { Option } = Select;

function ApproveRejectTransfer() {
    const dispatch = useDispatch();

    // get dh list
    const { data, isLoading } = useGetDhListQuery();

    const [dhId, setDhId] = useState('');
    const [skip, setSkip] = useState(false);

    // get single dh information
    const { data: singleData, refetch: singleRefetch } = useGetSingleDhQuery(
        { id: dhId },
        { skip }
    );

    const {
        data: pendingData,
        isLoading: pendingLoading,
        refetch,
    } = useGetPendingMaterialListQuery(
        {
            id: dhId,
        },
        { skip }
    );

    useEffect(() => {
        if (dhId) {
            setSkip(false);
            refetch();
            singleRefetch();
        }
    }, [refetch, singleRefetch, dhId]);

    const [approveOrReject, { isLoading: actionLoading }] = useApproveOrRejectMutation();

    // approve or reject
    const approveOrRejectAction = (id, status) => {
        confirmAlert({
            title: `Confirm to ${status}`,
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: debounce(async () => {
                        try {
                            const result = await approveOrReject({
                                transferId: [id],
                                status,
                            }).unwrap();
                            message.success('Successfully complete', 10);
                        } catch (error) {
                            message.error('Something went wrong');
                        }
                    }, 300),
                },
                {
                    label: 'No',
                    onClick: () => message.warning('Cancelled!'),
                },
            ],
        });
    };

    // data receive and set global loading
    useEffect(() => {
        const loading = pendingLoading || actionLoading;
        dispatch(setGlobalLoading(loading));
    }, [dispatch, pendingLoading, actionLoading]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Transfer Request', user: user.name });
    }, [user.name]);

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };

    return (
        <div style={{ margin: '15px' }}>
            <HelmetHeader title="Transfer Request" />

            <div
                style={{
                    padding: '10px',
                    marginTop: '8px',
                    boxShadow: '0 0 5px 0 #d6d0d0',
                    borderRadius: '10px',
                }}
            >
                <p style={{ fontSize: '16px', fontWeight: 500 }}>Approve/Reject Transfer Request</p>

                <div style={{ marginBottom: '10px' }}>
                    <Space>
                        <div>
                            <label htmlFor="dh" className="required-label">
                                TO DH Name
                            </label>
                            <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                                <Select
                                    allowClear
                                    showSearch
                                    size="large"
                                    id="dh"
                                    placeholder="Select a DH"
                                    style={{ minWidth: '220px' }}
                                    options={data?.data}
                                    value={dhId || null}
                                    onChange={(e) => {
                                        setDhId(e);
                                    }}
                                    filterOption={(input, option) =>
                                        option.props.label
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    searchValue={search}
                                    onSearch={onSearch}
                                />
                                <p style={{ margin: 0 }}>{dhId && singleData?.data?.dhCode}</p>
                            </div>
                        </div>
                    </Space>
                </div>

                <Table
                    rowKey="id"
                    scroll={{ x: 800 }}
                    pagination={false}
                    size="small"
                    dataSource={pendingData?.data || []}
                >
                    <Column
                        title="Timestamp"
                        dataIndex="createdAt"
                        key="createdAt"
                        render={(_, record) =>
                            dayjs(record.createdAt).format('DD/MM/YYYY hh:mm:ss A')
                        }
                    />
                    <Column
                        title="Form DH"
                        dataIndex="formDh"
                        key="formDh"
                        render={(_, record) => record.senderDh.name}
                    />
                    <Column
                        title="Receiver DH"
                        dataIndex="formDh"
                        key="formDh"
                        render={(_, record) => record.receiverDh.name}
                    />
                    <Column
                        title="Material Name"
                        dataIndex="materialName"
                        key="materialName"
                        render={(_, record) => record.material.name}
                    />
                    <Column
                        title="Category"
                        dataIndex="category"
                        key="category"
                        render={(_, record) => record.material.category}
                    />
                    <Column title="Quantity" dataIndex="quantity" key="quantity" />
                    <Column
                        title="Action"
                        key="id"
                        render={(_, record) => (
                            <Space>
                                <Button
                                    disabled={actionLoading}
                                    type="primary"
                                    onClick={() => approveOrRejectAction(record.id, 'approved')}
                                >
                                    Approve
                                </Button>
                                <Button
                                    disabled={actionLoading}
                                    onClick={() => approveOrRejectAction(record.id, 'declined')}
                                    danger
                                >
                                    Reject
                                </Button>
                            </Space>
                        )}
                    />
                </Table>
            </div>
        </div>
    );
}

export default ApproveRejectTransfer;
