import { Pagination, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { useGetTmrHistoryMutation } from '../../redux/features/inventory/inventoryApiSlice';
import { resetTmrHistoryFilter } from '../../redux/features/inventory/tmrHistoryFilterSlice';
import { setReFetchFilter } from '../../redux/features/loaderSlice';
import firebaseLog from '../../util/firebaseLog';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';

const { Column, ColumnGroup } = Table;

function TmrHistory() {
    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    // dh history filter hook
    const { dateRange, tmrId, entryType, dhCode, materialType } = useSelector(
        (state) => state.tmrHistoryFilter
    );

    // filter data
    const { circle, region, area, territory, distributionHouse } = useSelector(
        (state) => state.dataManagement
    );

    const getTmrHistoryFilterData = (dateArr, ids, eType, dhcode, materialtype) => {
        const bodyData = {};

        if (dateArr[0] && dateArr[1]) {
            const [s, e] = dateArr;
            bodyData.from = s;
            bodyData.to = e;
        }
        if (ids.length) {
            bodyData.tmrId = ids;
        }
        if (eType) {
            bodyData.type = eType;
        }
        if (dhcode) {
            bodyData.dhCode = dhcode;
        }
        if (materialtype) {
            bodyData.materialType = materialtype;
        }
        return bodyData;
    };

    const [getTmrHistory, { data, isLoading }] = useGetTmrHistoryMutation();

    const fetchTmrHistoryData = (page, totalShow, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(10);
        }

        getTmrHistory({
            page,
            limit: totalShow,
            ...getDataManagementFilterData({ circle, region, area, territory, distributionHouse }),
            ...getTmrHistoryFilterData(dateRange, tmrId, entryType, dhCode, materialType),
        });
    };

    useEffect(() => {
        getTmrHistory({ page: currentPage, limit: totalShowPage });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetchTmrHistoryData(pageNumber, totalPageChange);
    };

    const dispatch = useDispatch();
    const { reFetchFilter } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetTmrHistoryFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'TMR History', user: user.name });
    }, [user.name]);

    function getType(str) {
        if (str === 'confirmed') {
            return 'Confirm Material';
        }
        if (str === 'execution') {
            return 'Used Material In field';
        }
        if (str === 'return') {
            return 'Return Material';
        }
        if (str === 'assigned') {
            return 'Allocated to TMR';
        }
        return '';
    }

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="TMR History" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    loading={isLoading}
                    queryFunc={fetchTmrHistoryData}
                    pathname="/inventory-tmrHistory"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">TMR History</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    <Table
                        rowKey="_id"
                        pagination={false}
                        scroll={{
                            x: 750,
                        }}
                        size="small"
                        loading={isLoading}
                        dataSource={data?.data}
                    >
                        <Column title="Market Operation" dataIndex="circle" key="circle" />
                        <Column title="Region" dataIndex="region" key="region" />
                        <Column title="Area" dataIndex="area" key="area" />
                        <Column title="Territory" dataIndex="territory" key="territory" />
                        <Column title="DH" dataIndex="dh" key="dh" />
                        <Column title="DH Code" dataIndex="dhcode" key="dhcode" />
                        <Column title="Material Name" dataIndex="material" key="material" />
                        <Column title="TMR Name" dataIndex="tmr" key="tmr" />
                        <Column title="Material Qty" dataIndex="quantity" key="quantity" />
                        <Column
                            title="Entry Type"
                            dataIndex="type"
                            key="type"
                            render={(_, record) => getType(record.type)}
                        />
                        <Column
                            title="Timestamp"
                            dataIndex="createdAt"
                            key="createdAt"
                            render={(_, record) =>
                                dayjs(record.createdAt).format('DD/MM/YYYY hh:mm:ss A')
                            }
                        />
                    </Table>
                </div>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Pagination
                    size="large"
                    pageSize={totalShowPage}
                    showSizeChanger
                    showQuickJumper
                    current={currentPage}
                    defaultCurrent={1}
                    total={data?.meta.total}
                    onChange={onChange}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                />
            </div>
        </>
    );
}

export default TmrHistory;
