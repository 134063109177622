/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    circle: [],
    region: [],
    area: [],
    territory: [],
    distributionHouse: [],
};

const dataManagementFilterSlice = createSlice({
    name: 'dataManagementFilterSlice',
    initialState,
    reducers: {
        setCircle: (state, action) => {
            state.circle = action.payload;
        },
        setRegion: (state, action) => {
            state.region = action.payload;
        },
        setArea: (state, action) => {
            state.area = action.payload;
        },
        setTerritory: (state, action) => {
            state.territory = action.payload;
        },
        setDistributionHouse: (state, action) => {
            state.distributionHouse = action.payload;
        },
        resetDataManagementFilter: (state, action) => {
            state.circle = [];
            state.region = [];
            state.area = [];
            state.territory = [];
            state.distributionHouse = [];
        },
    },
});

export default dataManagementFilterSlice.reducer;
export const {
    setCircle,
    setRegion,
    setArea,
    setTerritory,
    setDistributionHouse,
    resetDataManagementFilter,
} = dataManagementFilterSlice.actions;
