import { Pagination, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { useGetLoginDetailsDataMutation } from '../../redux/features/loginDetails/loginDetailsApi';
import TableSkeleton from '../../ui/TableSkeleton';
import firebaseLog from '../../util/firebaseLog';

const { Column, ColumnGroup } = Table;

function LoginDetails() {
    // pagination
    const [totalShowPage, setTotalPageShow] = useState(100);
    const [currentPage, setCurrentPageShow] = useState(1);

    const { dateRange, name, email } = useSelector((state) => state.loginDetailsFilter);

    const [getLoginDetailsData, { data, isLoading }] = useGetLoginDetailsDataMutation();

    function getBodyData(dArr, n, e) {
        const bodyData = {};
        if (dArr.length) {
            const [s, end] = dArr;
            bodyData.from = s;
            bodyData.to = end;
        }
        if (!dArr.length) {
            bodyData.from = dayjs(new Date());
            bodyData.to = dayjs(new Date());
        }
        if (n) {
            bodyData.name = n;
        }
        if (e) {
            bodyData.email = e;
        }
        return bodyData;
    }

    const fetchLoginData = (pageNumber, totalPageChange, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(100);
        }
        getLoginDetailsData({
            ...getBodyData(dateRange, name, email),
            page: pageNumber,
            limit: totalShowPage,
        });
    };

    useEffect(() => {
        getLoginDetailsData({
            from: dayjs().startOf('day'),
            to: dayjs().endOf('day'),
            page: currentPage,
            limit: totalShowPage,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLoginDetailsData]);

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetchLoginData(pageNumber, totalPageChange);
    };

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Login Details', user: user.name });
    }, [user.name]);

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Login Details" />

            <div style={{ margin: '16px 0' }}>
                <Filter loading={isLoading} queryFunc={fetchLoginData} pathname="/login" />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">Login Details</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    {isLoading ? (
                        <TableSkeleton />
                    ) : (
                        <Table
                            rowKey="_id"
                            loading={isLoading}
                            dataSource={data?.data}
                            scroll={{ x: 800, y: 500 }}
                            pagination={false}
                        >
                            <Column
                                title="Username"
                                dataIndex="username"
                                key="username"
                                render={(_, record) => record.user.username}
                            />
                            <Column
                                title="Name"
                                dataIndex="name"
                                key="name"
                                render={(_, record) => record.user.name}
                            />
                            <Column title="IP Address" dataIndex="ip" key="ip" />
                            <Column title="Platform" dataIndex="deviceName" key="deviceName" />
                            <Column title="Browser" dataIndex="browser" key="browser" />
                            <Column title="Log Type" dataIndex="logType" key="logType" />
                            <Column
                                title="Is Success"
                                dataIndex="isSucceed"
                                key="isSucceed"
                                render={(e) => (e ? 'Yes' : 'No')}
                            />
                            <Column
                                title="Activity Time"
                                dataIndex="activityTime"
                                key="activityTime"
                                render={(e) => dayjs(e).format('DD/MM/YYYY hh:mm:ss A')}
                            />
                        </Table>
                    )}
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '0 0 10px 0',
                    }}
                >
                    <Pagination
                        size="large"
                        pageSize={totalShowPage}
                        showSizeChanger
                        showQuickJumper
                        current={currentPage}
                        defaultCurrent={1}
                        total={data?.meta.total}
                        onChange={onChange}
                        pageSizeOptions={[50, 100, 200, 500]}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    />
                </div>
            </div>
        </>
    );
}

export default LoginDetails;
