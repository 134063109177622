import { apiSlice } from '../api/apiSlice';

export const inventoryApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDhHistory: builder.mutation({
            query: (data) => ({
                url: '/v1/material/dh-history',
                method: 'POST',
                body: data,
            }),
        }),
        getDhSummary: builder.mutation({
            query: (data) => ({
                url: '/v1/material/dh-summary',
                method: 'POST',
                body: data,
            }),
        }),
        getTmrDamageAndLost: builder.mutation({
            query: (data) => ({
                url: '/v1/tmr-material/get-lost-damage',
                method: 'POST',
                body: data,
            }),
        }),
        getTmrHistory: builder.mutation({
            query: (data) => ({
                url: '/v1/tmr-material/history',
                method: 'POST',
                body: data,
            }),
        }),
        getTmrSummary: builder.mutation({
            query: (data) => ({
                url: '/v1/tmr-material/summary',
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const {
    useGetDhHistoryMutation,
    useGetDhSummaryMutation,
    useGetTmrDamageAndLostMutation,
    useGetTmrHistoryMutation,
    useGetTmrSummaryMutation,
} = inventoryApiSlice;
