/* eslint-disable react/no-array-index-key */
import { Col, Image, Row } from 'antd';
import React from 'react';
import AttendanceItemSkeleton from '../../ui/AttendanceItemSkeleton';

function AttendanceTracker({ data, loading }) {
    const { attendances, totalEmployees } = data || {};

    return (
        <div className="component-box-container">
            <div className="box-heading" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                Attendance Tracker
            </div>

            <div style={{ padding: '10px', width: '100%' }}>
                {loading ? (
                    <AttendanceItemSkeleton />
                ) : (
                    <Row gutter={[5, 10]}>
                        <Col lg={6}>
                            <div
                                style={{
                                    textAlign: 'center',
                                    background: '#fff',
                                    borderRadius: '5px',
                                    padding: '5px',
                                    minHeight: '128px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    boxShadow: '0px 0px 5px 0px #00000040',
                                }}
                            >
                                <h5
                                    style={{
                                        color: '#000',
                                        margin: '10px 0 0 0',
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                    }}
                                >
                                    Total Attendance
                                </h5>
                                <p
                                    style={{
                                        color: '#000',
                                        margin: 0,
                                        textAlign: 'center',
                                        fontWeight: 600,
                                        fontSize: '16px',
                                    }}
                                >
                                    {attendances?.totalCount?.count || 0}/{totalEmployees || 0}
                                </p>
                            </div>
                        </Col>
                        {/* {attendances?.data.map((item, i) => (
                            <AttendanceUserItem item={item} key={i + 1} />
                        ))} */}
                        <Image.PreviewGroup
                            preview={{
                                countRender: (current) =>
                                    `${attendances?.data[current - 1]?.name} ( ${
                                        attendances?.data[current - 1]?.lat
                                    }, ${attendances?.data[current - 1]?.long} )`,
                            }}
                        >
                            {attendances?.data.map(
                                (
                                    {
                                        name,
                                        punchInAtBDTime,
                                        attendanceImageOriginal,
                                        attendanceImageThumb,
                                        lat,
                                        long,
                                    },
                                    i
                                ) => (
                                    <Col lg={6} key={attendanceImageOriginal + i}>
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                background: '#fff',
                                                borderRadius: '5px',
                                                padding: '5px',
                                            }}
                                        >
                                            <Image
                                                width={80}
                                                height={80}
                                                style={{ borderRadius: '50%' }}
                                                src={attendanceImageOriginal || ''}
                                            />
                                            <h5
                                                style={{
                                                    color: '#000',
                                                    margin: '10px 0 0 0',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {name}
                                            </h5>
                                            <p
                                                style={{
                                                    color: '#000',
                                                    margin: 0,
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {punchInAtBDTime}
                                            </p>
                                        </div>
                                    </Col>
                                )
                            )}
                        </Image.PreviewGroup>
                    </Row>
                )}
            </div>
        </div>
    );
}

export default AttendanceTracker;
