/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Space, message, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import excelIcon from '../../../assets/excel.png';
import HelmetHeader from '../../../components/HelmetHeader';
import { setGlobalLoading } from '../../../redux/features/loaderSlice';
import { useTmrTargetAllocationMutation } from '../../../redux/features/target/targetApi';
import firebaseLog from '../../../util/firebaseLog';

function TmrTarget() {
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (successText, description) => {
        api.open({
            message: successText,
            duration: 0,
        });
    };

    const dispatch = useDispatch();

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const download = () => {
        const link = document.createElement('a');
        link.href = `${process.env.REACT_APP_API_URL}/v1/target/tmr-target-excel-template`;
        link.setAttribute('download', 'tmr-target-allocation.xlsx');
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    };

    const [tmrTargetAllocation, { data, isLoading }] = useTmrTargetAllocationMutation();

    const submitMaterial = async () => {
        if (selectedFile?.name) {
            try {
                dispatch(setGlobalLoading(false));

                const formData = new FormData();
                formData.append('file', selectedFile);

                const result = await tmrTargetAllocation(formData).unwrap();
                setSelectedFile(null);
                if (result?.message) {
                    message.success(result.message);
                    openNotification(result.message);
                }
            } catch (error) {
                message.error(error?.data?.message);
            } finally {
                dispatch(setGlobalLoading(false));
            }
        } else {
            message.error('Please select a file');
        }
    };

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Tmr Target', user: user.name });
    }, [user.name]);

    return (
        <>
            {contextHolder}
            <HelmetHeader title="TMR Target" />
            <div style={{ margin: '10px' }}>
                <div
                    style={{
                        boxShadow: '0 0 5px 0 #cec6c6',
                        padding: '10px',
                        borderRadius: '5px',
                        marginTop: '10px',
                    }}
                >
                    <Space style={{ marginTop: '10px' }}>
                        <p
                            style={{
                                margin: 0,
                                color: '#1d1c1c',
                                fontSize: '18px',
                                fontWeight: 500,
                            }}
                        >
                            The TMR Target List format appears when you click the download button
                        </p>
                        <Button
                            onClick={download}
                            size="large"
                            style={{ background: '#faad14', color: '#fff' }}
                        >
                            Download
                        </Button>
                    </Space>

                    <div className="file-upload-container">
                        <p style={{ margin: '0 5px', fontSize: '14px' }}>Upload File</p>
                        <label htmlFor="file-upload" className="custom-file-upload">
                            <img src={excelIcon} alt="Upload Icon" className="upload-icon" />
                            Choose File
                        </label>
                        <input
                            type="file"
                            id="file-upload"
                            onChange={handleFileChange}
                            accept=".xlsx"
                        />
                        {selectedFile && (
                            <div className="selected-file-name">{selectedFile.name}</div>
                        )}
                    </div>

                    <div style={{ marginTop: '15px' }}>
                        <Button
                            disabled={isLoading}
                            loading={isLoading}
                            onClick={submitMaterial}
                            size="large"
                            style={{ background: '#faad14', color: '#fff' }}
                        >
                            Upload
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TmrTarget;
