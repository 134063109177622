/* eslint-disable no-underscore-dangle */
import { apiSlice } from '../api/apiSlice';

export const ticketApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        addNotice: builder.mutation({
            query: (data) => ({
                url: '/v1/notices',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['tickets'],
        }),
        getNotice: builder.query({
            query: ({ page, limit }) => ({
                url: `/v1/notices`,
            }),
            providesTags: ['tickets'],
        }),
        deleteNotice: builder.mutation({
            query: (id) => ({
                url: `/v1/notices/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['tickets'],
            // async onQueryStarted(arg, { queryFulfilled, dispatch }) {
            //     try {
            //         const result = await queryFulfilled;
            //         if (result?.data?.data._id) {
            //             console.log(result.data.data);
            //             dispatch(
            //                 apiSlice.util.updateQueryData(
            //                     'getNotices',
            //                     result.data.data._id.toString(),
            //                     (draft) => ({
            //                         data: {
            //                             ...draft,
            //                             data: draft.data.filter(
            //                                 ({ _id }) => _id !== result.data.data._id
            //                             ),
            //                         },
            //                     })
            //                 )
            //             );
            //         }
            //     } catch (error) {
            //         // do nothing
            //     }
            // },
        }),
    }),
});

export const { useGetNoticeQuery, useDeleteNoticeMutation, useAddNoticeMutation } = ticketApi;
