import React from 'react';

function PrivacyPolicyPageForTms() {
    const containerStyle = {
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        fontSize: '16px',
        lineHeight: '1.6',
    };

    const sectionHeadingStyle = {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '10px',
    };

    const paragraphStyle = {
        marginBottom: '20px',
    };

    const contactLinkStyle = {
        color: '#007bff',
        textDecoration: 'underline',
    };

    return (
        <div style={containerStyle}>
            <h1 style={sectionHeadingStyle}>Privacy Policy for Nagad Pulse TMS</h1>
            <p style={paragraphStyle}>Last Updated: 02/09/2023</p>
            <section style={paragraphStyle}>
                <h2 style={sectionHeadingStyle}>1. Introduction</h2>
                <p>
                    The Nagad Pulse TMS designed and developed by HawkEyes Digital Monitoring LTD.
                    This Privacy Policy outlines how HawkEyes Digital Monitoring Limited collect,
                    use, disclose, and protect personal information in connection with the use of
                    Nagad Pulse TMS. By using the Nagad Pulse TMS, you agree to the practices
                    described in this policy.
                </p>
            </section>

            <section style={paragraphStyle}>
                <h2 style={sectionHeadingStyle}>
                    2. Information HawkEyes Digital Monitoring Limited Collect
                </h2>
                <p>
                    a. The Nagad Pulse TMS allows the field force to access the platform using
                    provided credentials, which include usernames and passwords.
                </p>
                <p>
                    b. HawkEyes Digital Monitoring Limited collect information about how users
                    interact with the Nagad Pulse TMS and usage patterns and actions take within the
                    Nagad Pulse TMS.
                </p>
                <p>
                    c. HawkEyes Digital Monitoring Limited may collect device-related information
                    such as device model, operating system.
                </p>
            </section>

            <section style={paragraphStyle}>
                <h2 style={sectionHeadingStyle}>
                    3. How HawkEyes Digital Monitoring Limited Use Your Information
                </h2>
                <p>
                    a. User credentials are used to grant access to the Nagad Pulse TMS and its
                    features exclusively for authorized field force members.
                </p>
                <p>
                    b. HawkEyes Digital Monitoring Limited use the information collected to provide,
                    maintain, and improve the Nagad Pulse TMS&apos;s functionality and user
                    experience.
                </p>
                <p>
                    c. HawkEyes Digital Monitoring Limited may use aggregated and anonymized data
                    for analytical purposes, such as understanding user behavior and trends.
                </p>
            </section>

            <section style={paragraphStyle}>
                <h2 style={sectionHeadingStyle}>4. Disclosure of Your Information</h2>
                <p>
                    a. User credentials are shared with only the owner to enable authorized access
                    to the Nagad Pulse TMS.
                </p>
                <p>
                    b. HawkEyes Digital Monitoring Limited may disclose information if required by
                    law, legal process, or to protect our rights, privacy, safety, or property.
                </p>
            </section>

            <section style={paragraphStyle}>
                <h2 style={sectionHeadingStyle}>5. Security</h2>
                <p>
                    HawkEyes Digital Monitoring Limited implement security measures to protect
                    personal information from unauthorized access, disclosure, or alteration.
                    However, no method of data transmission over the internet is completely secure.
                </p>
            </section>

            <section style={paragraphStyle}>
                <h2 style={sectionHeadingStyle}>6. Children&apos;s Privacy</h2>
                <p>
                    The Nagad Pulse TMS is not intended for individuals under the age of 18.
                    HawkEyes Digital Monitoring Limited do not knowingly collect personal
                    information from children under 18.
                </p>
            </section>

            <section style={paragraphStyle}>
                <h2 style={sectionHeadingStyle}>7. Changes to this Privacy Policy</h2>
                <p>
                    HawkEyes Digital Monitoring Limited reserve the right to update this Privacy
                    Policy to reflect changes to any kind of practices or legal requirements.
                    HawkEyes Digital Monitoring Limited will notify users of material changes
                    through the Nagad Pulse TMS or other means.
                </p>
            </section>

            {/* ... (Repeat the above pattern for the remaining sections) */}
            <section style={paragraphStyle}>
                <h2 style={sectionHeadingStyle}>8. Contact Us</h2>
                <p>
                    If you have any questions or concerns about this Privacy Policy, please contact
                    us at{' '}
                    <a href="mailto:info@hedigital.tech" style={contactLinkStyle}>
                        info@hedigital.tech
                    </a>
                    .
                </p>
            </section>
        </div>
    );
}

export default PrivacyPolicyPageForTms;
