import { apiSlice } from '../api/apiSlice';

export const routePlanTrackerApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // get route plan tracker data
        getRoutePlanData: builder.mutation({
            query: (data) => ({
                url: '/v1/routes/routeTracker',
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const { useGetRoutePlanDataMutation } = routePlanTrackerApi;
