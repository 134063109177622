/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    username: '',
    groupName: '',
    userType: '',
};

const viewUserFilterSlice = createSlice({
    name: 'viewUserFilterSlice',
    initialState,
    reducers: {
        setUsername: (state, action) => {
            state.username = action.payload;
        },
        setGroupName: (state, action) => {
            state.groupName = action.payload;
        },
        setUserType: (state, action) => {
            state.userType = action.payload;
        },
        resetViewUserFilter: (state, action) => {
            state.username = '';
            state.groupName = '';
            state.userType = '';
        },
    },
});

export default viewUserFilterSlice.reducer;
export const { setUsername, setGroupName, setUserType, resetViewUserFilter } =
    viewUserFilterSlice.actions;
