import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Select, Space } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateRange from '../../components/DateRange';
import {
    setDateRange,
    setDhCode,
    setEntryType,
} from '../../redux/features/inventory/viewDhHistoryFilterSlice';

function DhHistoryFilter({ queryFunc, loading }) {
    const { dateRange, entryType, dhCode } = useSelector((state) => state.dhHistoryFilter);

    const dispatch = useDispatch();

    // date picker function
    const dataPickerFunc = (_, date) => {
        dispatch(setDateRange(date));
    };

    return (
        <>
            <DateRange dataPickerFunc={dataPickerFunc} />
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    showArrow
                    placeholder="Entry Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setEntryType(e))}
                    options={[
                        {
                            value: 'allocated',
                            label: 'Allocated',
                        },
                        {
                            value: 'received',
                            label: 'Received',
                        },
                        {
                            value: 'transfer',
                            label: 'Transfer to another DH',
                        },
                        {
                            value: 'transfer-received',
                            label: 'Transfer received from another DH',
                        },
                        {
                            value: 'assigned',
                            label: 'Allocate to TMR',
                        },
                        {
                            value: 'return',
                            label: 'Return from TMR',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={dhCode}
                    placeholder="DH Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setDhCode(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Space size={2}>
                    <Button
                        loading={loading}
                        disabled={loading}
                        size="large"
                        className="filter-btn"
                        icon={<SearchOutlined />}
                        type="primary"
                        style={{ background: '#faad14', color: '#fff' }}
                        onClick={() => queryFunc(1, 10, 'cleanShowResultOnPage')}
                    >
                        Search
                    </Button>
                </Space>
            </Col>
        </>
    );
}

export default DhHistoryFilter;
