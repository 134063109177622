/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Select, Spin, message } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setExpireDate,
    setFileType,
    setFileUrl,
    setName,
    setPublishDate,
    setTargetOf,
} from '../../redux/features/trainingModule/trainingFilterSlice';

function TrainingFilter({ queryFunc, loading: saveLoading }) {
    const { accessToken } = useSelector((state) => state.auth);
    const {
        fileUrl,
        publishDate: pubDate,
        expireDate: expDate,
        name,
        fileType,
        targetOf,
    } = useSelector((state) => state.trainingFilter);
    const dispatch = useDispatch();

    const onChange = (date, dateString) => {
        dispatch(setPublishDate(dateString));
        dispatch(setExpireDate(''));
    };
    const expireDate = (date, dateString) => {
        dispatch(setExpireDate(dateString));
    };

    const [loading, setLoading] = useState();
    const [fileName, setFileName] = useState('');

    const fileChange = async (e) => {
        setLoading(true);
        try {
            const fileData = e.target.files[0];
            const formData = new FormData();
            formData.append('file', fileData);

            setFileName(fileData.name);

            const res = await fetch(
                `https://api.hedigital.net/v1/upload/file/${dayjs(new Date()).format(
                    'YYYY-MM-DD'
                )}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: formData,
                }
            );
            const result = await res.json();
            dispatch(setFileUrl(result.data.fileUrl));
            message.success(result.message);
        } catch (error) {
            message.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    // disable date
    const disabledDate = (current) =>
        // Disable all dates before today (including today)
        current && current < dayjs().startOf('day');

    const disableExpDate = (current) =>
        current && current < dayjs(pubDate || new Date()).startOf('day');

    return (
        <>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <DatePicker
                    disabledDate={disabledDate}
                    value={pubDate ? dayjs(pubDate) : ''}
                    style={{ width: '100%' }}
                    size="large"
                    placeholder="Publish Date"
                    onChange={onChange}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <DatePicker
                    disabledDate={disableExpDate}
                    value={expDate ? dayjs(expDate) : ''}
                    style={{ width: '100%' }}
                    size="large"
                    placeholder="Expire Date"
                    onChange={expireDate}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    showArrow
                    placeholder="File Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    value={fileType || null}
                    onChange={(e) => dispatch(setFileType(e))}
                    options={[
                        {
                            value: 'PDF',
                            label: 'PDF',
                        },
                        {
                            value: 'MP4',
                            label: 'MP4',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    mode="multiple"
                    allowClear
                    showArrow
                    placeholder="Target Of"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    value={targetOf.length ? targetOf : []}
                    onChange={(e) => dispatch(setTargetOf(e))}
                    options={[
                        {
                            value: 'TMR',
                            label: 'TMR',
                        },
                        {
                            value: 'TMS',
                            label: 'TMS',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={name}
                    showCount
                    maxLength={30}
                    placeholder="Name"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setName(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                {loading ? (
                    <Spin />
                ) : (
                    <>
                        {fileUrl ? (
                            <p style={{ color: '#000' }}>{fileName}</p>
                        ) : (
                            <input
                                className="training-file"
                                type="file"
                                accept=".pdf,.mp4"
                                onChange={fileChange}
                            />
                        )}
                    </>
                )}
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    disabled={saveLoading}
                    loading={saveLoading}
                    size="large"
                    className="filter-btn"
                    icon={<SaveOutlined />}
                    type="primary"
                    style={{ background: '#faad14', color: '#fff' }}
                    onClick={queryFunc}
                >
                    Save
                </Button>
            </Col>
        </>
    );
}

export default TrainingFilter;
