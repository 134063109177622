/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dateRange: [],
    tmrId: [],
    entryType: '',
    dhCode: '',
    materialType: '',
};

const tmrHistoryFilterSlice = createSlice({
    name: 'tmrHistoryFilterSlice',
    initialState,
    reducers: {
        setDateRange: (state, action) => {
            state.dateRange = action.payload;
        },
        setTmrId: (state, action) => {
            state.tmrId = action.payload;
        },
        setEntryType: (state, action) => {
            state.entryType = action.payload;
        },
        setDhCodeTmrHistory: (state, action) => {
            state.dhCode = action.payload;
        },
        setMaterialType: (state, action) => {
            state.materialType = action.payload;
        },
        resetTmrHistoryFilter: (state, action) => {
            state.dateRange = [];
            state.tmrId = [];
            state.entryType = '';
            state.dhCode = '';
            state.materialType = '';
        },
    },
});

export default tmrHistoryFilterSlice.reducer;
export const {
    setDateRange,
    resetTmrHistoryFilter,
    setEntryType,
    setDhCodeTmrHistory,
    setTmrId,
    setMaterialType,
} = tmrHistoryFilterSlice.actions;
