/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prettier/prettier */
import { Col, Row, message } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { useGetDashboardDataMutation } from '../../redux/features/dashboard/dashboardApi';
import { useGetCircleListQuery } from '../../redux/features/dataManagement/dataManagementApi';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { setReFetchFilter } from '../../redux/features/loaderSlice';
import firebaseLog from '../../util/firebaseLog';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import AttendanceByRegion from './AttendanceByRegion';
import OutletCovered from './OutletCovered';
import OutletCoveredByDate from './OutletCoveredByDate';
import SovByCount from './SovByCount';
import SovCountStackedChart from './SovCountStackedChart';
import StrikeRate from './StrikeRate';
import StrikeRateByRegion from './StrikeRateByRegion';

function Dashboard() {

  // dispatch
  const dispatch = useDispatch();

  const {data: circleData} = useGetCircleListQuery();


  const { reFetchFilter } = useSelector((state) => state.globalLoading);
  // reset existing filter
  useEffect(() => {
      dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
    }, []);

    // data management filter data
    const { circle, region, area, territory, distributionHouse, point } = useSelector(
        (state) => state.dataManagement
    );

    // dashboard filter data
    const { dateRange } = useSelector(
        (state) => state.dashboardFilter
    );

    // api hook
    const [getDashboardData, {data, isLoading, isError, error}] = useGetDashboardDataMutation();

    // initial state load data
    useEffect(() => {
      async function fetchData() {
        try {
          getDashboardData({
            ...getDataManagementFilterData({ circle: circle.length ? circle : circleData?.data, region, area, territory, distributionHouse })
          })
        } catch (err) {
          message.error(err.message)
        } finally {
          // do something
        }
      }
      if (circleData?.data) {
        fetchData();
      }
    }, [circleData?.data])

        //  query data
        function getBodyData ({d}) {
          const bodyData = {};

          if (d.length) {
              const [s,e] = d;
              bodyData.startDate = s;
              bodyData.endDate = e;
          }
          return bodyData;
      };

    const fetchDashboardData = async () => {
      try {
        getDashboardData({...getBodyData({d: dateRange}), ...getDataManagementFilterData({ circle: circle.length ? circle : circleData?.data, region, area, territory, distributionHouse })})
      } catch (err) {
        message.error(err.message)
      } finally {
        // do something
      }
    }


    // user information log
    const {user} = useSelector((state) => state.auth)
    useEffect(() => {
      // Log a custom event
      firebaseLog({page: 'Dashboard', user: user.name})
    }, []);

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Dashboard" />

        <div style={{ margin: '16px 0' }}>
          <Filter queryFunc={fetchDashboardData} pathname="/" />
        </div>

          <Row gutter={[5,15]}>
            {/* <Col xs={24} sm={12} md={12} lg={7}>
              <ByRegionPresence />
            </Col> */}

            <Col xs={24} sm={12} md={12} lg={8}>
              <AttendanceByRegion loading={isLoading} data={data?.data?.attendanceData} />
            </Col>

            <Col xs={24} sm={12} md={12} lg={8}>
              <StrikeRateByRegion loading={isLoading} data={data?.data?.strikeRate} />
            </Col>
            
            <Col xs={24} sm={12}  md={12} lg={8}>
              <StrikeRate loading={isLoading} data={data?.data?.nationalStrikeRate} />
            </Col>
          </Row>

          <Row style={{marginTop: '20px'}} gutter={[5,15]}>
            <Col xs={24} sm={12}  md={12} lg={12}>
              <OutletCovered loading={isLoading} total={data?.data?.totalExecutionDone} data={data?.data?.outletWisePresence} noExecutionData={data?.data?.totalNoExecutions} />
            </Col>
            <Col xs={24} sm={12}  md={12} lg={12}>
              <SovByCount total={data?.data?.totalExecutionDone} data={data?.data?.sov} noExecutionData={data?.data?.totalNoExecutions} />
            </Col>
          </Row>
          
          <Row style={{marginTop: '20px', marginBottom: '20px'}} gutter={[5,15]}>
            <Col xs={24} sm={24}  md={24} lg={24}>
              <OutletCoveredByDate data={data?.data?.materialUses || []} />
            </Col>
            <Col xs={24} sm={24}  md={24} lg={24}>
              <SovCountStackedChart data={data?.data?.materialUsageComp} />
            </Col>
          </Row>



        </>
    );
}

export default Dashboard;
