import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Space } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateRange from '../../components/DateRange';
import {
    setDateRange,
    setDhCodeDamageAndLost,
} from '../../redux/features/inventory/damageAndLostFilterSlice';

function DamageAndLostFilter({ queryFunc, loading }) {
    const { dateRange, dhCode } = useSelector((state) => state.damageAndLostFilter);

    const dispatch = useDispatch();

    // date picker function
    const dataPickerFunc = (_, date) => {
        dispatch(setDateRange(date));
    };

    return (
        <>
            <DateRange dataPickerFunc={dataPickerFunc} />

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={dhCode}
                    placeholder="DH Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setDhCodeDamageAndLost(e.target.value))}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Space size={2}>
                    <Button
                        loading={loading}
                        disabled={loading}
                        size="large"
                        className="filter-btn"
                        icon={<SearchOutlined />}
                        type="primary"
                        style={{ background: '#faad14', color: '#fff' }}
                        onClick={() => queryFunc(1, 10, 'cleanShowResultOnPage')}
                    >
                        Search
                    </Button>
                </Space>
            </Col>
        </>
    );
}

export default DamageAndLostFilter;
