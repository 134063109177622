import { Input, Modal, Typography, message } from 'antd';
import React, { useState } from 'react';
import { useUserPasswordUpdateMutation } from '../../redux/features/teamManagement/teamManagementApi';

function PasswordChangeModal({ passCngModal, setPassCngModal, id }) {
    const [userPasswordUpdate, { isLoading }] = useUserPasswordUpdateMutation();
    // change state
    const [oldPass, setOldPass] = useState('');
    const [newPass, setNewPass] = useState('');

    const handleOk = async () => {
        if (oldPass && newPass) {
            try {
                const res = await userPasswordUpdate({
                    userId: id,
                    newPassword: newPass,
                }).unwrap();
                setPassCngModal(false);
                message.success('Task Completed successfully');
            } catch (error) {
                message.error('Something went wrong!');
            }
        }
    };
    const handleCancel = () => {
        setPassCngModal(false);
    };

    return (
        <Modal
            title="Password Change"
            open={passCngModal}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Update"
            confirmLoading={isLoading}
        >
            <div>
                <div>
                    <Typography.Title level={5}>Old Password</Typography.Title>
                    <Input onChange={(e) => setOldPass(e.target.value)} placeholder="Abc$^&124" />
                </div>

                <div>
                    <Typography.Title level={5}>New Password</Typography.Title>
                    <Input onChange={(e) => setNewPass(e.target.value)} placeholder="Abc$^&124" />
                </div>
            </div>
        </Modal>
    );
}

export default PasswordChangeModal;
