/* eslint-disable react/no-array-index-key */
import { GoogleMap, InfoWindow, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { Spin, message } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import bluePin from '../../assets/blue-pin.png';
import endIcon from '../../assets/endFlag.png';
import startIcon from '../../assets/startFlag.png';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { setReFetchFilter } from '../../redux/features/loaderSlice';
import { useGetRoutePlanDataMutation } from '../../redux/features/routePlanTracker/routePlanTrackerApi';
import { resetRoutePlanTrackerFilter } from '../../redux/features/routePlanTracker/routePlanTrackerFilterSlice';
import NoResult from '../../ui/NoResult';
import firebaseLog from '../../util/firebaseLog';

const containerStyle = {
    width: '100%',
    height: '87vh',
};

const center = {
    lat: 23.875897,
    lng: 90.401813,
};

const options = {
    zoom: 10,
};

function RoutePlanTracker() {
    const { date, empId } = useSelector((state) => state.routePlanTrackerFilter);

    // map loaded
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    });
    const path = [
        { lat: 23.886105, lng: 90.380065 },
        { lat: 23.879209, lng: 90.38071 },
        { lat: 23.866381, lng: 90.387376 },
        { lat: 23.862962, lng: 90.395794 },
        { lat: 23.875897, lng: 90.401813 },
        { lat: 23.885251, lng: 90.395791 },
        { lat: 23.876111, lng: 90.403567 },
        { lat: 23.877925, lng: 90.372057 },
    ];
    const [selectedMarker, setSelectedMarker] = useState(null);

    const [newPath, setPath] = useState([]);

    const handleMarkerClick = (marker) => {
        setSelectedMarker(marker);
    };

    const handleInfoWindowClose = () => {
        setSelectedMarker(null);
    };

    // data get api hook
    const [getRoutePlanData, { data, isLoading, isSuccess }] = useGetRoutePlanDataMutation();

    const getFilterData = (id, dateStr) => {
        const bodyData = {};
        if (id) {
            bodyData.userId = id;
        }
        if (dateStr) {
            bodyData.date = dayjs(dateStr).format('DD-MM-YYYY');
        }
        return bodyData;
    };

    const searchData = async () => {
        if (empId && date) {
            try {
                const result = await getRoutePlanData({
                    ...getFilterData(empId, date),
                }).unwrap();
                setPath(result?.data);
            } catch (error) {
                message.error(error?.data?.message);
            }
        } else {
            message.error('Date & employee name are required!');
        }
    };

    const dispatch = useDispatch();
    const { reFetchFilter } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetRoutePlanTrackerFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Route Plan Tracker', user: user.name });
    }, [user.name]);

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Route Plan Tracker" />

            <div style={{ margin: '16px 0' }}>
                <Filter loading={false} queryFunc={searchData} pathname="/routePlanTracker" />
            </div>
            {isLoading ? (
                <div
                    style={{
                        width: '100%',
                        height: '75vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Spin size="large" />
                </div>
            ) : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {!data?.data ? (
                        <NoResult />
                    ) : (
                        <div>
                            {isLoaded && newPath.length > 0 && (
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={{ lat: newPath[0]?.lat, lng: newPath[0]?.long }}
                                    zoom={options.zoom}
                                >
                                    <Polyline
                                        path={newPath
                                            ?.filter((item) => item.lat)
                                            .map(({ lat, long }) => ({ lat, lng: long }))}
                                    />

                                    {newPath
                                        ?.filter((item) => item.lat)
                                        .map(
                                            (
                                                {
                                                    lat,
                                                    long,
                                                    start,
                                                    end,
                                                    employeeName,
                                                    orgName,
                                                    timeStamp,
                                                },
                                                index
                                            ) => (
                                                <Marker
                                                    icon={
                                                        // eslint-disable-next-line no-nested-ternary
                                                        start || end
                                                            ? start
                                                                ? startIcon
                                                                : endIcon
                                                            : bluePin
                                                    }
                                                    key={index}
                                                    position={{ lat, lng: long }}
                                                    onClick={() =>
                                                        handleMarkerClick({
                                                            lat,
                                                            lng: long,
                                                            employeeName,
                                                            orgName,
                                                            timeStamp,
                                                        })
                                                    }
                                                />
                                            )
                                        )}

                                    {selectedMarker && (
                                        <InfoWindow
                                            position={{
                                                lat: selectedMarker.lat,
                                                lng: selectedMarker.lng,
                                            }}
                                            onCloseClick={handleInfoWindowClose}
                                        >
                                            <div>
                                                <h3>{selectedMarker?.orgName}</h3>
                                                <p>{selectedMarker?.employeeName}</p>
                                                <p>
                                                    {dayjs(selectedMarker?.timeStamp).format(
                                                        'DD/MM/YYYY hh:mm:ss A'
                                                    )}
                                                </p>
                                            </div>
                                        </InfoWindow>
                                    )}
                                </GoogleMap>
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default RoutePlanTracker;
