import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Select } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setDescription,
    setExpireDate,
    setPublishDate,
    setTarget,
} from '../../redux/features/notice/noticeFilterSlice';

function NoticeFilter({ queryFunc, loading }) {
    const { publishDate, expireDate, description } = useSelector((state) => state.noticeFilter);

    const dispatch = useDispatch();
    const onChange = (date, dateString) => {
        dispatch(setPublishDate(dateString));
        dispatch(setExpireDate(''));
    };
    const expireDateFunc = (date, dateString) => {
        dispatch(setExpireDate(dateString));
    };

    const disabledDate = (current) =>
        // Disable all dates before today (including today)
        current && current < dayjs().startOf('day');

    const disableExpDate = (current) =>
        current && current < dayjs(publishDate || new Date()).startOf('day');

    return (
        <>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <DatePicker
                    disabledDate={disabledDate}
                    value={publishDate ? dayjs(publishDate) : ''}
                    style={{ width: '100%' }}
                    size="large"
                    placeholder="Publish Date"
                    onChange={onChange}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <DatePicker
                    disabledDate={disableExpDate}
                    value={expireDate ? dayjs(expireDate) : ''}
                    style={{ width: '100%' }}
                    size="large"
                    placeholder="Expire Date"
                    onChange={expireDateFunc}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    mode="multiple"
                    allowClear
                    showArrow
                    placeholder="Target Of"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setTarget(e))}
                    options={[
                        {
                            value: 'TMR',
                            label: 'TMR',
                        },
                        {
                            value: 'TMS',
                            label: 'TMS',
                        },
                    ]}
                />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Input
                    value={description}
                    showCount
                    maxLength={500}
                    placeholder="Type Your Notice"
                    size="large"
                    style={{ width: '100%', minHeight: '60px' }}
                    onChange={(e) => dispatch(setDescription(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    loading={loading}
                    disabled={loading}
                    size="large"
                    className="filter-btn"
                    icon={<SaveOutlined />}
                    type="primary"
                    style={{ background: '#faad14' }}
                    color="#000"
                    onClick={queryFunc}
                >
                    Save
                </Button>
            </Col>
        </>
    );
}

export default NoticeFilter;
