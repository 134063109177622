/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dateRange: [],
    uddoktaCode: '',
    tmsCode: '',
    tmrId: '',
    errorReason: '',
    callType: [],
    isQrCodeScanned: '',
    isAiReady: '',
    tmrCode: '',
};

const tmrExecutionFilterSlice = createSlice({
    name: 'tmrExecutionFilterSlice',
    initialState,
    reducers: {
        setDateRange: (state, action) => {
            state.dateRange = action.payload;
        },
        setUddoktaCode: (state, action) => {
            state.uddoktaCode = action.payload;
        },
        setTmsCode: (state, action) => {
            state.tmsCode = action.payload;
        },
        setTmrId: (state, action) => {
            state.tmrId = action.payload;
        },
        setErrorReason: (state, action) => {
            state.errorReason = action.payload;
        },
        setCallType: (state, action) => {
            state.callType = action.payload;
        },
        setQrCodeScanned: (state, action) => {
            state.isQrCodeScanned = action.payload;
        },
        setIsAiReady: (state, action) => {
            state.isAiReady = action.payload;
        },
        setTmrCode: (state, action) => {
            state.tmrCode = action.payload;
        },
        resetTmrExecutionFilter: (state, action) => {
            state.dateRange = [];
            state.uddoktaCode = '';
            state.tmsCode = '';
            state.tmrId = '';
            state.errorReason = '';
            state.callType = [];
            state.isQrCodeScanned = '';
            state.isAiReady = '';
            state.tmrCode = '';
        },
    },
});

export default tmrExecutionFilterSlice.reducer;
export const {
    setCallType,
    setDateRange,
    setErrorReason,
    setTmrId,
    setTmsCode,
    setUddoktaCode,
    setQrCodeScanned,
    setIsAiReady,
    setTmrCode,
    resetTmrExecutionFilter,
} = tmrExecutionFilterSlice.actions;
