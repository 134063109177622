import { Button, Form, Input, Select, message } from 'antd';
import React from 'react';
import {
    useAddRegionMutation,
    useGetCircleListQuery,
} from '../../redux/features/dataManagement/dataManagementApi';

const { Option } = Select;

function AddRegion() {
    // get circle list data
    const { data, isLoading } = useGetCircleListQuery();

    // add region api hook
    const [addRegion, { isSuccess, isLoading: addLoading }] = useAddRegionMutation();

    // form
    const [form] = Form.useForm();

    // submit function
    const onFinish = async (values) => {
        try {
            const result = await addRegion({
                name: values.region,
                circleId: values.regionCircle,
            }).unwrap();
            message.success('Region added successfully');
            form.resetFields();
        } catch (error) {
            message.error(error.data.message);
        }
    };
    return (
        <div className="data-box">
            <h3>Add Region</h3>
            <div>
                <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
                    <Form.Item
                        name="regionCircle"
                        label="Circle"
                        rules={[
                            {
                                required: true,
                                message: 'Missing circle',
                            },
                        ]}
                    >
                        <Select loading={isLoading} size="large" options={data?.data} />
                    </Form.Item>
                    <Form.Item
                        name="region"
                        label="Region"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input size="large" placeholder="Region Name" />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            disabled={addLoading}
                            loading={addLoading}
                            type="primary"
                            htmlType="submit"
                        >
                            Add Region
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default AddRegion;
