import { apiSlice } from '../api/apiSlice';

export const teamManagementApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        searchEmployee: builder.mutation({
            query: (data) => ({
                url: '/v2/employee/search',
                method: 'POST',
                body: { ...data },
            }),
        }),
        addEmployee: builder.mutation({
            query: (data) => ({
                url: '/v1/employee/add',
                method: 'POST',
                body: data,
            }),
        }),
        updateEmployeeInfo: builder.mutation({
            query: ({ data, id }) => ({
                url: `/v1/employee/update/${id}`,
                method: 'PATCH',
                body: data,
            }),
        }),
        unLockUser: builder.mutation({
            query: (data) => ({
                url: '/v1/user/unlock-user',
                method: 'PATCH',
                body: data,
            }),
        }),
        forceLogoutUser: builder.mutation({
            query: (data) => ({
                url: '/v1/user/force-logout',
                method: 'PATCH',
                body: data,
            }),
        }),
        suspendUserApi: builder.mutation({
            query: (data) => ({
                url: '/v1/user/suspend-user',
                method: 'PATCH',
                body: data,
            }),
        }),
        userPasswordUpdate: builder.mutation({
            query: (data) => ({
                url: '/v1/user/reset-password',
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const {
    useSearchEmployeeMutation,
    useAddEmployeeMutation,
    useUpdateEmployeeInfoMutation,
    useForceLogoutUserMutation,
    useUnLockUserMutation,
    useSuspendUserApiMutation,
    useUserPasswordUpdateMutation,
} = teamManagementApi;
