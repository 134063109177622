/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import { Pagination } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../../components/Filter';
import HelmetHeader from '../../../components/HelmetHeader';
import { resetDataManagementFilter } from '../../../redux/features/filter/dataManagementFilterSlice';
import { setReFetchFilter } from '../../../redux/features/loaderSlice';
import { useGetTmsExecutionDataMutation } from '../../../redux/features/report/tms/tmsExecutionApi';
import { resetTmsExecutionFilter } from '../../../redux/features/report/tms/tmsExecutionFilterSlice';
import ExecutionSkeleton from '../../../ui/ExecutionSkeleton';
import NoResult from '../../../ui/NoResult';
import firebaseLog from '../../../util/firebaseLog';
import getDataManagementFilterData from '../../../util/generateDataManagementFilterData';
import TmsExecutionItem from './TmsExecutionItem';

function TmsExecution() {
    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    // filter data
    const { circle, region, area, territory, distributionHouse } = useSelector(
        (state) => state.dataManagement
    );
    const { dateRange, uddoktaCode, tmsCode, callType } = useSelector(
        (state) => state.tmsExecutionFilter
    );

    const [getTmsExecutionData, { data, isLoading, isError, isSuccess, error }] =
        useGetTmsExecutionDataMutation();

    function getTmsFilterData(range, uCode, tCode, cType) {
        const bodyData = {};
        if (range?.length) {
            const [s, e] = range;
            bodyData.startAt = dayjs(s).format('DD-MM-YYYY');
            bodyData.endAt = dayjs(e).format('DD-MM-YYYY');
        }
        if (uCode) {
            bodyData.uddoktaCode = uCode;
        }
        if (tCode) {
            bodyData.supervisorId = tCode;
        }
        if (cType) {
            bodyData.callType = cType;
        }
        return bodyData;
    }

    const fetchExecutionData = (page, totalShow, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(10);
        }
        getTmsExecutionData({
            page,
            limit: totalShow,
            ...getTmsFilterData(dateRange, uddoktaCode, tmsCode, callType),
            ...getDataManagementFilterData({ circle, region, area, territory, distributionHouse }),
        });
    };

    useEffect(() => {
        getTmsExecutionData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetchExecutionData(pageNumber, totalPageChange);
    };

    const dispatch = useDispatch();
    const { reFetchFilter } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetTmsExecutionFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'TMS Execution', user: user.name });
    }, [user.name]);

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="TMS Execution" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    loading={isLoading}
                    queryFunc={fetchExecutionData}
                    pathname="/tms-execution"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">Uddokta TMS Call</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    {!isLoading ? (
                        <>
                            {data?.data.length <= 0 || !data ? (
                                <NoResult />
                            ) : (
                                <>
                                    {data?.data.map((item, i) => (
                                        <TmsExecutionItem
                                            b={i}
                                            loading={isLoading}
                                            data={item}
                                            key={i}
                                        />
                                    ))}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {new Array(5).fill('').map((_, i) => (
                                <ExecutionSkeleton key={i} />
                            ))}
                        </>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Pagination
                            size="large"
                            pageSize={totalShowPage}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultCurrent={1}
                            total={data?.meta.count}
                            onChange={onChange}
                            showTotal={(total, range) =>
                                `${range[0]}-${range[1]} of ${total} items`
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default TmsExecution;
