import { DeleteOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Table } from 'antd';
import Column from 'antd/es/table/Column';
import React, { useState } from 'react';
import MapModal from '../../components/MapModal';
import { useGetDhDataQuery } from '../../redux/features/dataManagement/dataManagementApi';

function DataTable() {
    // dh data get
    const { data, isLoading } = useGetDhDataQuery();

    // modal state and function
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [location, setLocation] = useState({ lat: 0, lon: 0 });

    return (
        <>
            <Modal
                footer={false}
                title="Location in google Map"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width="850px"
                bodyStyle={{ padding: 0 }}
            >
                <MapModal lat={location.lat} lon={location.lon} />
            </Modal>
            <Table
                loading={isLoading}
                rowKey="_id"
                pagination={false}
                size="small"
                scroll={{ x: 800 }}
                dataSource={data?.data}
            >
                <Column title="Market Operation" dataIndex="circle" key="circle" />
                <Column title="Region" dataIndex="region" key="region" />
                <Column title="Area" dataIndex="area" key="area" />
                <Column title="Territory" dataIndex="territory" key="territory" />
                <Column title="DH" dataIndex="dh" key="dh" />
                <Column title="DH Code" dataIndex="dhCode" key="dhCode" />
                <Column
                    title="Action"
                    key="action"
                    align="right"
                    render={(_, record) => (
                        <Space size={2}>
                            {/* <Button
                            size="small"
                            icon={<EditOutlined />}
                            type="primary"
                            danger
                            shape="circle"
                        /> */}
                            <Button
                                size="small"
                                icon={<DeleteOutlined />}
                                type="primary"
                                danger
                                shape="circle"
                            />
                            <Button
                                onClick={() => {
                                    setLocation({ lat: record.lat, lon: record.long });
                                    showModal();
                                }}
                                size="small"
                                icon={<EnvironmentOutlined />}
                                type="primary"
                                shape="circle"
                            />
                        </Space>
                    )}
                />
            </Table>
        </>
    );
}

export default DataTable;
